import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { ToastContainer } from "react-toastify";
import jwt_decode from "jwt-decode";
import { ThemeProvider, CssBaseline } from "@mui/material";
import onboardingActions from "./store/onboardingSlice/onboardingActions";
import "./App.css";
import { getAccessToken } from "./Utils/getToken";
// import MainPage from "./sections/connectSocials/pages/MainPage/MainPage";
import { Dark } from "./core/theme/SecureTheme";
import NoAccessPage from "./sections/connectSocials/pages/NoAccessPage/NoAccessPage";
import { Route, Routes } from "react-router-dom";
import ConfigPage from "./sections/connectSocials/pages/ConfigPage/ConfigPage";
import ThemePage from "./sections/connectSocials/pages/ThemePage/ThemePage";
import LogRocket from "logrocket";
import { ENV } from "./Utils/constants";
import SocialConnectComponent from "./sharedComponent/SocialConnectComponent";

function App() {
    const dispatch = useDispatch();
    const token = getAccessToken();
    useEffect(() => {
        if (ENV === "prod") {
            LogRocket.init("a1e4oo/vn");
            LogRocket.startNewSession();
        }
    }, []);
    useEffect(() => {
        if (token) {
            let decoded: any = jwt_decode(token);
            if (decoded?.user_id) {
                dispatch(onboardingActions.setUserId(parseInt(decoded?.user_id)));
            }
        } // eslint-disable-next-line
    }, [token]);

    const config = {
        workspace_logo: null,
        workspace_name: null,
        title: null,
        description: null,
        skip_intro: true,
    };
    //Note :-  this condition is only added to test V2 social connects
    //process.env.REACT_APP_ENV === "dev" ? <MainPageV2/> :<MainPage/>
    return (
        <ThemeProvider theme={Dark}>
            <CssBaseline />
            <Routes>
                <Route
                    path="/"
                    element={
                        token ? (
                            // ENV === "dev" ? (
                            <SocialConnectComponent />
                        ) : (
                            // ) : (
                            // <MainPage config={config} theme={Dark} />
                            // )
                            <NoAccessPage />
                        )
                    }
                />
                <Route
                    path="/:theme"
                    element={token ? <ThemePage config={config} /> : <NoAccessPage />}
                />
                <Route
                    path="/consent/:invitationKey/:teamId/:profileId"
                    element={
                        token ? (
                            // ENV === "dev" ? (
                            <SocialConnectComponent />
                        ) : (
                            // ) : (
                            // <MainPage config={config} theme={Dark} />
                            // )
                            <NoAccessPage />
                        )
                    }
                />
                <Route
                    path="/consent/:invitationKey/:teamId/:profileId/:domainId/:creatorInviteKey"
                    element={
                        token ? (
                            // ENV === "dev" ? (
                            <SocialConnectComponent />
                        ) : (
                            // ) : (
                            //     <MainPage config={config} theme={Dark} />
                            // )
                            <NoAccessPage />
                        )
                    }
                />
                <Route
                    path="/consent/mediakits/:theme"
                    element={token ? <ThemePage config={config} /> : <NoAccessPage />}
                />
                <Route path="/preview/:embedKey" element={<ConfigPage isPreview />} />
                <Route path="/:embedKey/:invitationKey" element={<ConfigPage />} />
            </Routes>

            <ToastContainer
                position="top-right"
                autoClose={3000}
                hideProgressBar={false}
                newestOnTop={true}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                pauseOnHover
                theme={"colored"}
            />
        </ThemeProvider>
    );
}

export default App;
