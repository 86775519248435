import { useMutation } from "@apollo/client";
import { ProfileClient } from "../../../../../apolloClient/apolloClient";
import { UPDATE_PLATFORM_ORDERS_MASTER_PROFILE } from "../../../../../graphQL/Mutations";

export default function useSocialAccountsOrderUpdate(
    socialAccounts,
    socialAccountsMediakits,
    setSocialAccountsMediakits,
    user_id,
    setSocialAccounts
) {
    const pathname = window.location.pathname;
    const [updatedSocialConnectPlatformOrders] = useMutation(
        UPDATE_PLATFORM_ORDERS_MASTER_PROFILE,
        {
            client: ProfileClient,
            fetchPolicy: "network-only",
        }
    );
    const reorder = (list, startIndex, endIndex) => {
        const result: Array<any> = Array.from(list);
        const [removed] = result.splice(startIndex, 1);
        result.splice(endIndex, 0, removed);

        return result;
    };

    const handleOnDragEnd = async (result) => {
        //

        if (result.destination) {
            const items = reorder(socialAccounts, result.source.index, result.destination.index);

            if (pathname.includes("mediakits")) {
                const itemsMK = reorder(
                    socialAccountsMediakits,
                    result.source.index,
                    result.destination.index
                );
                setSocialAccountsMediakits(itemsMK);
            }

            await updatedSocialConnectPlatformOrders({
                variables: {
                    userId: user_id,
                    platformOrder: items.map((item) => ({
                        id: item.id,
                        img_url: item.profile_pic,
                        name: item.social_handle,
                        platform_id: item.platform_id,
                        visible: item.visible,
                    })),
                },
            });
            setSocialAccounts([...items]);
        } else {
            // dropped outside the list
            return;
        }
    };
    return { handleOnDragEnd };
}
