import axios from "axios";
import { REACT_APP_TECHLABS_API } from "./constants";

export const validateEmbedLinkAPI = async (
    link: any,
    key: any,
    endpoint = "secure/v2.0/workspaces/connects"
) => {
    const keyframe = key ? `&invitation_key=${key}` : "";
    const Endpoint = `${REACT_APP_TECHLABS_API}${endpoint}?security_key=${link}${keyframe}`;

    try {
        const { data } = await axios.get(`${Endpoint}`, {});
        return data;
    } catch (error: any) {
        // toast.error(`Embed Key is not valid!`, TOASTIFY_THEME);
        return false;
    }
};
