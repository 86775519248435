const CircleFbIcon = (props) => (
    <svg
        width={props.width}
        height={props.height}
        viewBox="0 0 50 50"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            d="M0 24C0 10.7452 10.7452 0 24 0H26C39.2548 0 50 10.7452 50 24V26C50 39.2548 39.2548 50 26 50H24C10.7452 50 0 39.2548 0 26V24Z"
            fill={props?.color ? "none" : "url(#paint0_linear_22506_224448)"}
        />
        <path
            d="M39.5499 25.038C39.5499 16.985 33.0305 10.4492 24.9976 10.4492C16.9647 10.4492 10.4453 16.985 10.4453 25.038C10.4453 32.099 15.4513 37.9782 22.0872 39.335V29.4146H19.1767V25.038H22.0872V21.3908C22.0872 18.5752 24.3719 16.2847 27.1805 16.2847H30.8185V20.6614H27.9081C27.1077 20.6614 26.4528 21.3179 26.4528 22.1202V25.038H30.8185V29.4146H26.4528V39.5538C33.8018 38.8244 39.5499 32.6096 39.5499 25.038Z"
            fill={props?.color ? props?.color : "white"}
        />
        <defs>
            <linearGradient
                id="paint0_linear_22506_224448"
                x1="25"
                y1="0"
                x2="25"
                y2="50"
                gradientUnits="userSpaceOnUse"
            >
                <stop stopColor="#18ADFE" />
                <stop offset="1" stopColor="#0164E1" />
            </linearGradient>
        </defs>
    </svg>
);

export default CircleFbIcon;
