const CircleTiktokIcon = (props) => (
    <svg
        width={props.width}
        height={props.height}
        viewBox="0 0 51 50"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            d="M0.5 24C0.5 10.7452 11.2452 0 24.5 0H26.5C39.7548 0 50.5 10.7452 50.5 24V26C50.5 39.2548 39.7548 50 26.5 50H24.5C11.2452 50 0.5 39.2548 0.5 26V24Z"
            fill={props?.color ? "none" : "black"}
        />
        <path
            d="M22.6804 21.9528V20.8351C22.2896 20.7737 21.8949 20.74 21.4992 20.7344C17.6689 20.7263 14.2777 23.1905 13.1275 26.8176C11.9772 30.4447 13.335 34.3931 16.479 36.5648C15.3261 35.3399 14.5549 33.8098 14.2586 32.1593C13.9623 30.5089 14.1536 28.8085 14.8092 27.2636C15.4649 25.7186 16.557 24.3951 17.9536 23.4528C19.3503 22.5104 20.9919 21.9895 22.6802 21.9529L22.6804 21.9528Z"
            fill={props?.color ? "none" : "#25F4EE"}
        />
        <path
            d="M22.8939 34.6166C25.0355 34.6137 26.7959 32.9387 26.8896 30.8147V11.8603H30.3776C30.3064 11.4642 30.2725 11.0625 30.2762 10.6602L25.5053 10.6602V29.5963C25.426 31.7311 23.6614 33.4221 21.5096 33.4256C20.8666 33.4201 20.234 33.2632 19.6641 32.9675C20.0335 33.4756 20.5186 33.8898 21.0799 34.1764C21.6412 34.463 22.2628 34.6138 22.8939 34.6166ZM36.8926 18.2913V17.2378C35.6089 17.2383 34.3536 16.8622 33.2845 16.1569C34.2217 17.2385 35.4883 17.9878 36.8926 18.2913Z"
            fill={props?.color ? "none" : "#25F4EE"}
        />
        <path
            d="M33.2842 16.1574C32.231 14.9667 31.6504 13.4362 31.6509 11.8516H30.3773C30.5418 12.7277 30.8844 13.5613 31.3842 14.3016C31.884 15.0419 32.5305 15.6733 33.2842 16.1574ZM21.5002 25.4742C20.607 25.4788 19.7409 25.7796 19.0397 26.3289C18.3385 26.8781 17.8424 27.6444 17.6302 28.5058C17.4181 29.3671 17.5021 30.2742 17.8689 31.0827C18.2356 31.8913 18.8641 32.5549 19.6545 32.968C19.2224 32.3757 18.9636 31.6764 18.9068 30.9472C18.8499 30.2181 18.9972 29.4875 19.3324 28.8363C19.6676 28.185 20.1776 27.6384 20.8061 27.2569C21.4345 26.8754 22.157 26.6737 22.8937 26.6743C23.2942 26.6795 23.6919 26.7414 24.0747 26.8581V22.0388C23.6837 21.9806 23.2891 21.95 22.8937 21.9472H22.6813V25.6116C22.2964 25.5091 21.8985 25.4628 21.5002 25.4742Z"
            fill={props?.color ? "none" : "#FE2C55"}
        />
        <path
            d="M36.8929 18.2891V21.9536C34.5164 21.949 32.2016 21.2022 30.2765 19.819V29.4474C30.2662 34.2518 26.3402 38.1413 21.5007 38.1413C19.7034 38.1446 17.9493 37.594 16.4805 36.5656C17.6782 37.8446 19.2361 38.7358 20.9513 39.1232C22.6665 39.5106 24.4595 39.3762 26.0967 38.7376C27.7339 38.099 29.1394 36.9857 30.1302 35.5427C31.121 34.0998 31.6511 32.3941 31.6515 30.6478V21.0467C33.5831 22.4208 35.9007 23.1578 38.2771 23.1537V18.4353C37.8118 18.4339 37.348 18.3849 36.8929 18.2891Z"
            fill={props?.color ? "none" : "#FE2C55"}
        />
        <path
            d="M30.274 29.4502V19.8218C32.205 21.1971 34.5231 21.9343 36.8996 21.9288V18.2644C35.4956 17.9701 34.2261 17.2306 33.2824 16.1574C32.5286 15.6733 31.8821 15.0419 31.3823 14.3016C30.8826 13.5613 30.54 12.7277 30.3755 11.8516H26.8875V30.8155C26.8535 31.6409 26.5614 32.4354 26.0517 33.0884C25.542 33.7415 24.84 34.2207 24.0434 34.4594C23.2467 34.6982 22.3949 34.6847 21.6064 34.4207C20.8178 34.1567 20.1317 33.6555 19.6433 32.9866C18.8528 32.5735 18.2242 31.9098 17.8573 31.1013C17.4904 30.2927 17.4064 29.3855 17.6185 28.5241C17.8307 27.6626 18.3269 26.8963 19.0282 26.347C19.7295 25.7977 20.5957 25.4969 21.489 25.4925C21.8896 25.4961 22.2875 25.5578 22.6701 25.6758V22.0113C20.9728 22.0398 19.3204 22.5581 17.9146 23.5028C16.5088 24.4476 15.4102 25.778 14.7528 27.3318C14.0955 28.8857 13.9077 30.5958 14.2124 32.2538C14.5171 33.9117 15.3012 35.4459 16.469 36.6691C17.9522 37.6636 19.7088 38.1788 21.4983 38.1441C26.3377 38.1441 30.2638 34.2546 30.274 29.4502Z"
            fill={props?.color ? props?.color : "white"}
        />
    </svg>
);

export default CircleTiktokIcon;
