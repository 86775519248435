import { Button, Grid, IconButton, Modal, Tooltip, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
import CloseIcon from "@mui/icons-material/Close";
import { getSocialUrl } from "../../../../Utils/validationLibrary";
import { useState } from "react";
import GetPlatformIcon from "../../../../sharedComponent/GetPlatformIcon/GetPlatformIcon";

const RemoveSocialAccountDialog = (props) => {
    const { theme, config } = props;
    const useStyles = makeStyles({
        ModalCSS: {
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: "586px",
            maxHeight: "600px",
            maxWidth: "98vw",
            padding: "24px",
            position: "relative",
            background: config?.paper_color ?? theme.palette.card.main,
            borderRadius: "8px",
            overflow: "auto",
        },
    });

    const { open, close, social, ctaFn } = props;
    const classes = useStyles();

    const [socialImageLoadError, setSocialImageLoadError] = useState<boolean>(false);

    return (
        <Modal open={open} onClose={close}>
            <div className={classes.ModalCSS}>
                <Grid container>
                    <Grid item xs={12} mt={-1}>
                        <Grid container justifyContent="space-between" alignItems="center">
                            <Grid item>
                                <Typography
                                    fontSize="22px"
                                    fontWeight={700}
                                    color={theme.palette.text.primary}
                                >
                                    Remove social connection
                                </Typography>
                            </Grid>
                            <Grid item>
                                <IconButton onClick={close}>
                                    <CloseIcon sx={{ color: theme.palette.text.ter }} />
                                </IconButton>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item xs={12}>
                        <Typography fontSize="14px" color={theme.palette.text.secondary}>
                            Are you sure you want to remove this social from your profile?
                        </Typography>
                    </Grid>
                    <Grid item xs={12} mt={1}>
                        <Grid
                            item
                            xs={12}
                            sx={{
                                border: `1px solid ${theme.palette.divider}`,
                                padding: "8px 16px",
                                borderRadius: "4px",
                            }}
                        >
                            <Grid
                                container
                                spacing={2}
                                sx={{
                                    position: "relative",
                                }}
                                alignItems={"center"}
                            >
                                <Grid
                                    item
                                    sx={{ cursor: "pointer" }}
                                    onClick={() =>
                                        getSocialUrl(social?.platform_id, social?.social_handle)
                                    }
                                >
                                    <Grid container>
                                        {!socialImageLoadError && social?.profile_pic ? (
                                            <img
                                                style={{
                                                    width: "32px",
                                                    height: "32px",
                                                    borderRadius: "50%",
                                                    objectFit: "cover",
                                                    objectPosition: "center",
                                                }}
                                                onError={() => setSocialImageLoadError(true)}
                                                src={social?.profile_pic}
                                                alt="profile"
                                                width={36}
                                                height={36}
                                            />
                                        ) : (
                                            <GetPlatformIcon
                                                platform_id={social?.platform_id}
                                                size="32px"
                                            />
                                        )}
                                    </Grid>
                                </Grid>
                                {!socialImageLoadError && social?.profile_pic ? (
                                    <Grid
                                        item
                                        sx={{
                                            position: "absolute",
                                            cursor: "pointer",
                                        }}
                                        onClick={() =>
                                            getSocialUrl(social?.platform_id, social?.social_handle)
                                        }
                                        ml={2.25}
                                        mt={2.25}
                                    >
                                        <Grid container>
                                            <GetPlatformIcon
                                                platform_id={social?.platform_id}
                                                size="18px"
                                            />
                                        </Grid>
                                    </Grid>
                                ) : null}
                                <Grid
                                    item
                                    xs
                                    zeroMinWidth
                                    onClick={() =>
                                        getSocialUrl(social?.platform_id, social?.social_handle)
                                    }
                                    sx={{ cursor: "pointer" }}
                                >
                                    {social?.social_handle.length > 17 ? (
                                        <Tooltip title={social?.social_handle} placement="top">
                                            <Typography
                                                fontSize="14px"
                                                color={theme.palette.text.secondary}
                                                noWrap
                                            >
                                                {social?.social_handle}
                                            </Typography>
                                        </Tooltip>
                                    ) : (
                                        <Typography
                                            fontSize="14px"
                                            color={theme.palette.text.secondary}
                                            noWrap
                                        >
                                            {social?.social_handle}
                                        </Typography>
                                    )}
                                    <Typography
                                        fontSize="12px"
                                        color={theme.palette.text.disabled}
                                        noWrap
                                    >
                                        {social?.social_handle}
                                    </Typography>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item xs={12} mt={1}>
                        <Grid container justifyContent="flex-end" alignItems="center" spacing={1}>
                            <Grid item>
                                <Button variant="text" size="small" onClick={() => close()}>
                                    Cancel
                                </Button>
                            </Grid>
                            <Grid item>
                                <Button variant="contained" color="error" onClick={() => ctaFn()}>
                                    Remove
                                </Button>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </div>
        </Modal>
    );
};
export default RemoveSocialAccountDialog;
