import { ToastOptions } from "react-toastify";

//TOASTIFY THEME COLORED
export const TOASTIFY_THEME: ToastOptions = { theme: "colored", hideProgressBar: true };

export const REACT_APP_AUTH_API_URL_GRAPHQL: any = process.env.REACT_APP_AUTH_API_URL_GRAPHQL;
export const REACT_APP_USER_API_URL_GRAPHQL: any = process.env.REACT_APP_USER_API_URL_GRAPHQL;
export const REACT_APP_SECURE_API_URL_GRAPHQL: any = process.env.REACT_APP_SECURE_API_URL_GRAPHQL;
export const REACT_APP_PROFILE_API_URL_GRAPHQL: any = process.env.REACT_APP_PROFILE_API_URL_GRAPHQL;
export const REACT_APP_VN_SECURE_URL: any = process.env.REACT_APP_VN_SECURE_URL;
export const REACT_APP_VN_PLATFORM_URL: any = process.env.REACT_APP_VN_PLATFORM_URL;
export const ENV = process.env.REACT_APP_ENV;
export const REACT_APP_TECHLABS_API = process.env.REACT_APP_TECHLABS_API;
export const REACT_APP_CONNECTS_API_URL_GRAPHQL: any =
    process.env.REACT_APP_CONNECTS_API_URL_GRAPHQL;
export const REACT_APP_BACKEND_API_URL_GRAPHQL: any = process.env.REACT_APP_BACKEND_API_URL_GRAPHQL;
export const REACT_APP_CONNECTS_REST_API_URL: any = process.env.REACT_APP_CONNECTS_REST_API_URL;
export const REACT_APP_HANDLE_REDIRECT_URL: any = process.env.REACT_APP_HANDLE_REDIRECT_URL;

export const FACEBOOK_PLATFORM_ID = 2;
export const GOOGLE_PLATFORM_ID = 3;
export const GOOGLE_NEWS_PLATFORM_ID = 4;
export const INSTAGRAM_PLATFORM_ID = 5;
export const LINKEDIN_PLATFORM_ID = 6;
export const TIKTOK_PLATFORM_ID = 7;
export const TWITTER_PLATFORM_ID = 8;
export const YOUTUBE_PLATFORM_ID = 10;
export const FACEBOOK_PERSONAL_PLATFORM_ID = 11;
export const TWITCH_PLATFORM_ID = 12;

export const PLATFORM_DESCRIPTION_MAP: Record<number, string> = {
    2: "FACEBOOK_PAGE",
    3: "GOOGLE",
    4: "GOOGLENEWS",
    5: "INSTAGRAM",
    6: "LINKEDIN",
    7: "TIKTOK",
    8: "TWITTER",
    10: "YOUTUBE",
    11: "FACEBOOK_USER",
    12: "TWITCH",
};

export const DOMAINS_MAP: Record<string, string> = {
    "1": REACT_APP_VN_SECURE_URL,
    "2": REACT_APP_VN_PLATFORM_URL,
    // "3": VN_TALENT_URL,
    // "4": VN_ADMIN_URL,
    // "5": REACT_APP_VN_PLATFORM_URL,
    // "6": VMESSENGER_URL,
    // "7": VN_MEDIAKITS_URL,
    // "8": VN_TECHLABS_URL,
    // "9": REACT_APP_VN_PLATFORM_URL,
    // "10": VN_VDATA_URL,
    // "11": REACT_APP_VN_EMPOWER_URL,
    // "12": REACT_APP_VN_AGENCY_URL,
    // "13": REACT_APP_VN_SDC_URL,
    // "14": REACT_APP_VN_APP_LANDING_DOMAIN_URL,
    // "15": REACT_APP_VN_ENTERPRISE_URL,
    // "16": REACT_APP_VN_COMPONENTS_URL,
    "-1": REACT_APP_VN_SECURE_URL,
};

export function FilteredPlatforms(handles) {
    return handles.filter((item) =>
        ENV === "prod" ? item?.platform_id !== TWITCH_PLATFORM_ID : item
    );
}
export const DisabledConsentPlatforms = [LINKEDIN_PLATFORM_ID, TWITCH_PLATFORM_ID];

export const SCOPES_FACEBOOK =
    "email,instagram_basic,instagram_content_publish,instagram_manage_comments,instagram_manage_insights,pages_manage_engagement,pages_manage_posts,pages_messaging,pages_read_engagement,pages_read_user_content,pages_show_list,public_profile,read_insights,user_photos,user_posts,user_videos";

export const SCOPES_INSTAGRAM_BUSINESS =
    "email,instagram_basic,instagram_content_publish,instagram_manage_comments,instagram_manage_insights,pages_manage_engagement,pages_read_engagement,pages_read_user_content,pages_show_list,public_profile,read_insights,user_photos,user_posts,user_videos";
