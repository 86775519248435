/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/no-unused-vars */
import {
    Avatar,
    Backdrop,
    Badge,
    Button,
    CircularProgress,
    Grid,
    Tooltip,
    Typography,
    useMediaQuery,
} from "@mui/material";

import { useState } from "react";
import { useParams } from "react-router";
import DragIndicatorIcon from "@mui/icons-material/DragIndicator";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";

import { getSocialUrl } from "../../../../../../Utils/validationLibrary";
import { DisabledConsentPlatforms } from "../../../../../../Utils/constants";
import ThreeDotMenu from "../../../../../../sharedComponent/threeDotMenu/threeDotMenu";
import GetPlatformIcon from "../../../../../../sharedComponent/GetPlatformIcon/GetPlatformIcon";
import ProfileHandlesCheckmarks from "../../../../../../sections/connectSocials/components/ProfileHandlesCheckmarks/ProfileHandlesCheckmarks";
import MediakitsSwitch from "../MediakitsSwitch";

const ConnectedSocials = ({
    handleOnDragEnd,
    socialAccounts,
    config,
    theme,
    setSelectedAccount,
    ChangeSelectedSocialAccounts,
    socialAccountsMediakits,
    setShowRemoveSocialDialog,
}) => {
    const [socialImageLoadError, setSocialImageLoadError] = useState<any>([]);
    let { invitationKey, teamId, profileId, domainId, creatorInviteKey } = useParams();
    const pathname = window.location.pathname;
    return (
        <DragDropContext onDragEnd={handleOnDragEnd}>
            <Droppable droppableId="droppable">
                {(provided) => (
                    <div {...provided.droppableProps} ref={provided.innerRef}>
                        <Grid container spacing={1}>
                            {socialAccounts.length > 0 &&
                                socialAccounts.map((social, index) => (
                                    <Grid item xs={12} key={index}>
                                        <Draggable
                                            key={social.id}
                                            draggableId={social.id}
                                            index={index}
                                        >
                                            {(provided) => (
                                                <div
                                                    ref={provided.innerRef}
                                                    {...provided.draggableProps}
                                                    {...provided.dragHandleProps}
                                                >
                                                    <Grid container>
                                                        <Grid
                                                            item
                                                            xs={12}
                                                            sx={{
                                                                background:
                                                                    config?.paper_color ??
                                                                    theme.palette.card.light,
                                                                padding: "12px 8px",
                                                                borderRadius: "10px",
                                                            }}
                                                        >
                                                            <Grid
                                                                container
                                                                sx={{
                                                                    position: "relative",
                                                                }}
                                                                alignItems={"center"}
                                                            >
                                                                <Grid item>
                                                                    <Grid container>
                                                                        <DragIndicatorIcon
                                                                            sx={{
                                                                                color: theme.palette
                                                                                    .text.disabled,
                                                                            }}
                                                                        />
                                                                    </Grid>
                                                                </Grid>
                                                                <Grid
                                                                    item
                                                                    sx={{
                                                                        cursor: "pointer",
                                                                    }}
                                                                    pr={2}
                                                                >
                                                                    <Badge
                                                                        anchorOrigin={{
                                                                            vertical: "top",
                                                                            horizontal: "right",
                                                                        }}
                                                                        overlap="circular"
                                                                        color="primary"
                                                                        badgeContent={
                                                                            DisabledConsentPlatforms?.includes(
                                                                                social?.platform_id
                                                                            )
                                                                                ? "Beta"
                                                                                : null
                                                                        }
                                                                    >
                                                                        <Grid
                                                                            container
                                                                            sx={{
                                                                                position:
                                                                                    "relative",
                                                                            }}
                                                                        >
                                                                            {!socialImageLoadError.includes(
                                                                                index
                                                                            ) &&
                                                                            social?.profile_pic ? (
                                                                                <img
                                                                                    style={{
                                                                                        width: "44px",
                                                                                        height: "44px",
                                                                                        borderRadius:
                                                                                            "50%",
                                                                                        objectFit:
                                                                                            "cover",
                                                                                        objectPosition:
                                                                                            "center",
                                                                                    }}
                                                                                    onError={() =>
                                                                                        setSocialImageLoadError(
                                                                                            [
                                                                                                ...socialImageLoadError,
                                                                                                index,
                                                                                            ]
                                                                                        )
                                                                                    }
                                                                                    src={
                                                                                        social?.profile_pic
                                                                                    }
                                                                                    alt="profile"
                                                                                    width={44}
                                                                                    height={44}
                                                                                />
                                                                            ) : (
                                                                                <GetPlatformIcon
                                                                                    platform_id={
                                                                                        social?.platform_id
                                                                                    }
                                                                                    size="44px"
                                                                                />
                                                                            )}
                                                                            {!socialImageLoadError.includes(
                                                                                index
                                                                            ) &&
                                                                            social?.profile_pic ? (
                                                                                <Grid
                                                                                    item
                                                                                    sx={{
                                                                                        position:
                                                                                            "absolute",
                                                                                        cursor: "pointer",
                                                                                    }}
                                                                                    onClick={() =>
                                                                                        getSocialUrl(
                                                                                            social.platform_id,
                                                                                            social.social_handle
                                                                                        )
                                                                                    }
                                                                                    ml={3.25}
                                                                                    mt={3.25}
                                                                                >
                                                                                    <Grid container>
                                                                                        <GetPlatformIcon
                                                                                            platform_id={
                                                                                                social?.platform_id
                                                                                            }
                                                                                            size="22px"
                                                                                        />
                                                                                    </Grid>
                                                                                </Grid>
                                                                            ) : null}
                                                                        </Grid>
                                                                    </Badge>
                                                                </Grid>
                                                                <Grid item xs zeroMinWidth>
                                                                    <Grid container>
                                                                        <Grid item>
                                                                            <Grid
                                                                                container
                                                                                spacing={0.5}
                                                                                alignItems="center"
                                                                            >
                                                                                <Grid
                                                                                    item
                                                                                    xs
                                                                                    zeroMinWidth
                                                                                >
                                                                                    <Tooltip
                                                                                        title={
                                                                                            social.social_handle
                                                                                        }
                                                                                        placement="top"
                                                                                    >
                                                                                        <Typography
                                                                                            sx={{
                                                                                                color: theme
                                                                                                    .palette
                                                                                                    .text
                                                                                                    .secondary,
                                                                                                fontSize:
                                                                                                    "14px",
                                                                                            }}
                                                                                            noWrap
                                                                                        >
                                                                                            {
                                                                                                social.social_handle
                                                                                            }
                                                                                        </Typography>
                                                                                    </Tooltip>
                                                                                </Grid>
                                                                                <Grid item>
                                                                                    <ProfileHandlesCheckmarks
                                                                                        optin={true}
                                                                                        is_token_valid={
                                                                                            social?.is_token_valid
                                                                                        }
                                                                                        platform_id={
                                                                                            social?.platform_id
                                                                                        }
                                                                                        is_business={
                                                                                            social?.is_business
                                                                                        }
                                                                                        is_advertiser={
                                                                                            social?.is_advertiser
                                                                                        }
                                                                                        theme={
                                                                                            theme
                                                                                        }
                                                                                    />
                                                                                </Grid>
                                                                            </Grid>
                                                                        </Grid>
                                                                        <Grid item xs={12}>
                                                                            <Typography
                                                                                fontSize="12px"
                                                                                color={
                                                                                    theme.palette
                                                                                        .text
                                                                                        .disabled
                                                                                }
                                                                                noWrap
                                                                            >
                                                                                {
                                                                                    social.social_handle
                                                                                }
                                                                            </Typography>
                                                                        </Grid>
                                                                    </Grid>
                                                                </Grid>
                                                                {((invitationKey &&
                                                                    teamId &&
                                                                    profileId) ||
                                                                    pathname.includes(
                                                                        "mediakits"
                                                                    )) && (
                                                                    <Grid item>
                                                                        {pathname.includes(
                                                                            "mediakits"
                                                                        ) ? (
                                                                            <MediakitsSwitch
                                                                                sx={{
                                                                                    m: 1,
                                                                                }}
                                                                                checked={
                                                                                    socialAccountsMediakits?.find(
                                                                                        (item) =>
                                                                                            item?.id ===
                                                                                            social?.id
                                                                                    )?.visible
                                                                                }
                                                                                handleMediakitSelection={(
                                                                                    e
                                                                                ) =>
                                                                                    ChangeSelectedSocialAccounts(
                                                                                        social,
                                                                                        e.target
                                                                                            .checked
                                                                                    )
                                                                                }
                                                                            />
                                                                        ) : null}
                                                                    </Grid>
                                                                )}
                                                                {!pathname.includes(
                                                                    "mediakits"
                                                                ) && (
                                                                    <Grid item>
                                                                        <ThreeDotMenu
                                                                            item={social}
                                                                            MenuOptions={[
                                                                                "Remove Connection",
                                                                            ]}
                                                                            selection={(
                                                                                value: any,
                                                                                selectedItem: any
                                                                            ) => {
                                                                                if (
                                                                                    value ===
                                                                                    "Remove Connection"
                                                                                ) {
                                                                                    setSelectedAccount(
                                                                                        selectedItem
                                                                                    );
                                                                                    setShowRemoveSocialDialog(
                                                                                        true
                                                                                    );
                                                                                }
                                                                            }}
                                                                        />
                                                                    </Grid>
                                                                )}
                                                            </Grid>
                                                        </Grid>
                                                    </Grid>
                                                </div>
                                            )}
                                        </Draggable>
                                    </Grid>
                                ))}
                        </Grid>
                        {provided.placeholder}
                    </div>
                )}
            </Droppable>
        </DragDropContext>
    );
};
export default ConnectedSocials;
