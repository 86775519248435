const SecureLogo = (props: any) => (
    <svg xmlns="http://www.w3.org/2000/svg" height={props?.size ?? 25} viewBox="50 15 510 60">
        <g>
            <path
                fill={props?.color || "#FFFFFF"}
                d="M146.8,41.7h-6.5c-0.3,0-0.5,0.2-0.6,0.5L134,62.9l-6.1-20.7c-0.1-0.3-0.3-0.5-0.6-0.5h-6.5
                  c-0.4,0-0.8,0.4-0.6,0.8l9.7,27.6c0.1,0.3,0.3,0.4,0.6,0.4h7c0.3,0,0.5-0.2,0.6-0.4l9.5-27.6C147.5,42.2,147.2,41.7,146.8,41.7
                  L146.8,41.7z"
            />
            <path
                fill={props?.color || "#FFFFFF"}
                d="M166.9,47.4v-5c0-0.3-0.3-0.6-0.6-0.6H160c-0.3,0-0.6,0.3-0.6,0.6V70c0,0.3,0.3,0.6,0.6,0.6h6.2
                  c0.3,0,0.6-0.3,0.6-0.6V56c0-4.4,3.8-7.8,8.1-7.2c0,0,0,0,0.1,0c0.4,0.1,0.7-0.2,0.7-0.6v-6.9c-0.4-0.1-0.6-0.1-0.9-0.1
                  C171.5,41.2,168.5,43.5,166.9,47.4L166.9,47.4z"
            />
            <path
                fill={props?.color || "#FFFFFF"}
                d="M210.6,33.7l-6.2,1.5c-0.3,0.1-0.4,0.3-0.4,0.6V70c0,0.3,0.3,0.6,0.6,0.6h6.2c0.3,0,0.6-0.3,0.6-0.6V34.3
                  C211.5,33.9,211.2,33.6,210.6,33.7L210.6,33.7z"
            />
            <path
                fill={props?.color || "#FFFFFF"}
                d="M238.3,41.2c-3.9,0-6.5,1.4-8.7,4.7v-3.5c0-0.3-0.3-0.6-0.6-0.6h-6.2c-0.3,0-0.6,0.3-0.6,0.6V70
                  c0,0.3,0.3,0.6,0.6,0.6h6.2c0.3,0,0.6-0.3,0.6-0.6V53.3c0-3.4,2.4-5.7,6-5.7c3.2,0,4.8,1.8,4.8,5.2V70c0,0.3,0.3,0.6,0.6,0.6h6.2
                  c0.3,0,0.6-0.3,0.6-0.6V51.3C248,44.8,244.5,41.3,238.3,41.2L238.3,41.2z"
            />
            <path
                fill={props?.color || "#FFFFFF"}
                d="M274.7,50.1c0-5.9-4-8.9-11.8-8.9c-7.8,0-11.6,3.1-12.3,9.3c0,0.4,0.3,0.7,0.6,0.7h6c0.3,0,0.6-0.2,0.6-0.5
                  c0.5-2.6,1.8-3.4,5.2-3.4c2.9,0,4.3,1,4.3,2.9c0,1-0.5,1.8-1.3,2.2c-0.7,0.4-2.2,0.7-4.7,1.1l-3,0.5c-5.7,1-8.5,3.9-8.5,9.1
                  s3.5,8.7,8.8,8.7c2.8,0,5.4-1,7.8-3.2c0.4-0.3,1-0.1,1,0.4c0.1,0.5,0.3,0.9,0.6,1.3c0.1,0.2,0.3,0.3,0.5,0.3h7.8v-0.9
                  c-1.3-1.2-1.7-2.1-1.7-3.5L274.7,50.1L274.7,50.1z M267.4,59c0,4.3-2.1,6.8-5.9,6.8c-2.5,0-4.1-1.3-4.1-3.5c0-2.2,1.2-3.3,4.3-4
                  l2.6-0.5c1.2-0.2,1.8-0.4,2.3-0.5c0.4-0.1,0.8,0.1,0.8,0.6L267.4,59L267.4,59z"
            />
            <path
                fill={props?.color || "#FFFFFF"}
                d="M293,41.7h6.2c0.3,0,0.6,0.3,0.6,0.6V70c0,0.3-0.3,0.6-0.6,0.6H293c-0.3,0-0.6-0.3-0.6-0.6V42.4
                  C292.4,42,292.7,41.7,293,41.7L293,41.7z"
            />
            <path
                fill={props?.color || "#FFFFFF"}
                d="M316.5,41.2c-8.8,0-14.2,5.8-14.2,15.3s5.4,15.3,14.3,15.3s14.3-5.8,14.3-15.1
                  C330.8,47,325.5,41.2,316.5,41.2z M316.5,65.8c-4.1,0-6.8-3.7-6.8-9.3s2.7-9.3,6.8-9.3c4.1,0,6.8,3.7,6.8,9.4
                  C323.3,62.3,320.5,65.8,316.5,65.8z"
            />
            <path
                fill={props?.color || "#FFFFFF"}
                d="M349.3,41.2c-3.9,0-6.5,1.4-8.7,4.7v-3.5c0-0.3-0.3-0.6-0.6-0.6h-6.2c-0.3,0-0.6,0.3-0.6,0.6V70
                  c0,0.3,0.3,0.6,0.6,0.6h6.2c0.3,0,0.6-0.3,0.6-0.6V53.3c0-3.4,2.4-5.7,6-5.7c3.2,0,4.8,1.8,4.8,5.2V70c0,0.3,0.3,0.6,0.6,0.6h6.2
                  c0.3,0,0.6-0.3,0.6-0.6V51.3C359,44.8,355.5,41.3,349.3,41.2L349.3,41.2z"
            />
            <path
                fill={props?.color || "#FFFFFF"}
                d="M189.6,41.2c-7.5,0-11.6,3.1-12.3,9.3c0,0.4,0.3,0.7,0.6,0.7h6c0.3,0,0.6-0.2,0.6-0.5
                  c0.5-2.6,1.8-3.4,5.2-3.4c2.9,0,4.3,1,4.3,2.9c0,1-0.5,1.8-1.3,2.2c-0.7,0.4-2.3,0.7-4.7,1.1l-3,0.5c-5.7,1-8.5,3.9-8.5,9.1
                  s3.5,8.7,8.8,8.7c2.8,0,5.4-1,7.8-3.2c0.4-0.3,1-0.1,1,0.4c0.1,0.5,0.3,0.9,0.6,1.3c0.1,0.2,0.3,0.3,0.5,0.3h5.5
                  c0.3,0,0.6-0.3,0.6-0.6V50.1C201.4,44.2,197.4,41.2,189.6,41.2z M194.1,59c0,4.3-2.1,6.8-5.9,6.8c-2.5,0-4.1-1.3-4.1-3.5
                  c0-2.2,1.2-3.3,4.3-4l2.6-0.5c1.2-0.2,1.8-0.4,2.3-0.5c0.4-0.1,0.8,0.1,0.8,0.6L194.1,59L194.1,59z"
            />
            <path
                fill={props?.color || "#FFFFFF"}
                d="M289.4,41.8h-4.3v-7.3c0-0.5-0.2-0.7-1-0.5l-6.2,1.4c-0.2,0.1-0.3,0.3-0.3,0.5v10.2c0,0.3,0.3,0.6,0.6,0.6
                  h5.1l-5.3,3.2c-0.3,0.2-0.4,0.5-0.4,0.8v13.4c0,4.6,2.4,6.8,7.3,6.8c1.5,0,2.7-0.1,3.9-0.5c0.3-0.1,0.5-0.3,0.5-0.6v-4
                  c0-0.4-0.3-0.7-0.7-0.6c-0.3,0-0.6,0.1-1,0.1c-2,0-2.5-0.6-2.5-3.2V46.8h2.8c0.3,0,0.4-0.2,0.6-0.6l1.6-3.7
                  C290.2,42,290,41.8,289.4,41.8L289.4,41.8z"
            />
            <path
                fill={props?.color || "#FFFFFF"}
                d="M149.7,41.7h6.2c0.3,0,0.6,0.3,0.6,0.6V70c0,0.3-0.3,0.6-0.6,0.6h-6.2c-0.3,0-0.6-0.3-0.6-0.6V42.4
                  C149,42,149.3,41.7,149.7,41.7L149.7,41.7z"
            />
            <path
                fill={props?.color || "#FFFFFF"}
                d="M156,31.9h-6.2c-0.4,0-0.6,0.3-0.6,0.6V38c0,0.4,0.3,0.6,0.6,0.6h6.2c0.4,0,0.6-0.3,0.6-0.6v-5.4
                  C156.6,32.2,156.3,31.9,156,31.9z"
            />
            <path
                fill={props?.color || "#FFFFFF"}
                d="M293.1,31.9h6.2c0.3,0,0.6,0.3,0.6,0.6V38c0,0.3-0.3,0.6-0.6,0.6h-6.2c-0.3,0-0.6-0.3-0.6-0.6v-5.4
                  C292.5,32.2,292.8,31.9,293.1,31.9z"
            />
            {!props?.noVlogo && (
                <path
                    fill={props?.color || "#FFFFFF"}
                    d="M114.6,28.3H107L85,66.5l-10.5-18l-4.4,7.7L81,75.1c0.1,0.2,0.4,0.4,0.7,0.4h6.6c0.3,0,0.5-0.1,0.7-0.4
                  l26.3-45.6C115.5,29,115.2,28.3,114.6,28.3z"
                />
            )}
            {!props?.noVlogo && (
                <path
                    fill={props?.color || "#FFFFFF"}
                    d="M85,59.5l18-31.2h-8.4c-0.3,0-0.5,0.2-0.7,0.4l-9,15.4L76,28.7c-0.1-0.2-0.4-0.4-0.7-0.4h-20
                  c-0.6,0-1,0.7-0.7,1.2l13.4,23l6.4-11.2L85,59.5L85,59.5z"
                />
            )}
        </g>
        <g>
            <path
                fill={props?.color || "#FFFFFF"}
                d="M494.1,59.6c0,1.7-0.4,3.1-1.1,4.2s-1.6,1.9-2.7,2.4s-2.1,0.8-3.2,0.8c-1.7,0-3.2-0.6-4.3-1.8
              c-1.1-1.2-1.7-2.8-1.7-4.7v-17H477v17.3c0,2.3,0.4,4.2,1.1,5.7c0.8,1.5,1.8,2.6,3.1,3.4c1.3,0.7,2.8,1.1,4.5,1.1
              c2.1,0,3.7-0.5,5.1-1.4c1.3-1,2.3-2.1,3-3.5h0.3v4.6h4.2V43.5h-4.2V59.6z"
            />
            <path
                fill={props?.color || "#FFFFFF"}
                d="M441.2,46.1c-1.1-1.1-2.4-1.8-3.8-2.3s-2.8-0.7-4.2-0.7c-2.5,0-4.6,0.6-6.4,1.8s-3.3,2.9-4.3,5
              s-1.5,4.6-1.5,7.4s0.5,5.2,1.5,7.3s2.5,3.7,4.4,4.9c1.9,1.2,4.2,1.7,6.8,1.7c1.8,0,3.5-0.3,5-0.8c1.5-0.6,2.7-1.4,3.7-2.4
              s1.7-2.2,2.1-3.6l-4-1.1c-0.4,0.9-0.9,1.7-1.5,2.4c-0.6,0.6-1.4,1.1-2.3,1.4s-1.9,0.5-3,0.5c-1.7,0-3.2-0.4-4.4-1.1
              c-1.3-0.7-2.3-1.8-3-3.2c-0.6-1.3-1-2.8-1-4.7H445v-1.8c0-2.5-0.3-4.7-1-6.4C443.2,48.6,442.3,47.2,441.2,46.1z M425.2,54.9
              c0.1-1.3,0.4-2.5,0.9-3.6c0.6-1.3,1.6-2.4,2.8-3.2c1.2-0.8,2.7-1.2,4.3-1.2c1.5,0,2.8,0.3,3.9,1s2,1.6,2.6,2.8s0.9,2.6,0.9,4.1
              h-15.4V54.9z"
            />
            <path
                fill={props?.color || "#FFFFFF"}
                d="M456.9,48.2c1.2-0.8,2.7-1.3,4.3-1.3c1.8,0,3.3,0.5,4.4,1.4c1.1,1,1.8,2.1,2.1,3.3h4.2
              c-0.2-1.7-0.8-3.2-1.8-4.5s-2.2-2.3-3.8-3c-1.5-0.7-3.3-1.1-5.2-1.1c-2.5,0-4.6,0.6-6.4,1.8s-3.3,2.8-4.3,5
              c-1,2.1-1.5,4.5-1.5,7.3c0,2.7,0.5,5.1,1.5,7.2c1,2.1,2.4,3.8,4.2,5c1.8,1.2,4,1.8,6.6,1.8c2,0,3.8-0.4,5.3-1.1
              c1.5-0.8,2.7-1.8,3.7-3c0.9-1.3,1.5-2.7,1.8-4.3h-4.2c-0.3,1-0.7,1.8-1.3,2.5c-0.6,0.7-1.4,1.2-2.2,1.6c-0.9,0.4-1.9,0.6-3,0.6
              c-1.7,0-3.1-0.4-4.3-1.3c-1.2-0.9-2.2-2.1-2.8-3.6c-0.7-1.6-1-3.4-1-5.5s0.3-3.8,1-5.4C454.7,50.2,455.7,49,456.9,48.2z"
            />
            <path
                fill={props?.color || "#FFFFFF"}
                d="M539.7,46.1c-1.1-1.1-2.4-1.8-3.8-2.3c-1.4-0.5-2.8-0.7-4.2-0.7c-2.5,0-4.6,0.6-6.4,1.8
              c-1.8,1.2-3.3,2.9-4.3,5s-1.5,4.6-1.5,7.4s0.5,5.2,1.5,7.3s2.5,3.7,4.4,4.9c1.9,1.2,4.2,1.7,6.8,1.7c1.8,0,3.5-0.3,5-0.8
              c1.5-0.6,2.7-1.4,3.7-2.4s1.7-2.2,2.1-3.6l-4-1.1c-0.4,0.9-0.9,1.7-1.5,2.4c-0.6,0.6-1.4,1.1-2.3,1.4c-0.9,0.3-1.9,0.5-3,0.5
              c-1.7,0-3.2-0.4-4.4-1.1c-1.3-0.7-2.3-1.8-3-3.2c-0.6-1.3-1-2.8-1-4.7h19.7v-1.8c0-2.5-0.3-4.7-1-6.4
              C541.8,48.6,540.9,47.2,539.7,46.1z M523.7,54.9c0.1-1.3,0.4-2.5,0.9-3.6c0.6-1.3,1.6-2.4,2.8-3.2s2.7-1.2,4.3-1.2
              c1.5,0,2.8,0.3,3.9,1c1.1,0.7,2,1.6,2.6,2.8s0.9,2.6,0.9,4.1h-15.4V54.9z"
            />
            <rect x="366.4" y="67.2" fill={props?.color || "#FFFFFF"} width="19.8" height="3.4" />
            <path
                fill={props?.color || "#FFFFFF"}
                d="M413.8,53.7c-1.1-0.8-2.2-1.3-3.3-1.8c-1.2-0.4-2.2-0.8-3.1-1l-3.7-1c-0.7-0.2-1.4-0.4-2.2-0.7
              c-0.8-0.3-1.6-0.7-2.3-1.1c-0.7-0.5-1.3-1-1.8-1.7s-0.7-1.5-0.7-2.5c0-1.2,0.3-2.2,1-3.1c0.7-0.9,1.6-1.6,2.8-2.1
              c1.2-0.5,2.6-0.8,4.2-0.8c2.2,0,4,0.5,5.6,1.5c1.5,1,2.4,2.4,2.6,4.2h4.2c-0.1-1.8-0.6-3.5-1.7-4.9s-2.5-2.6-4.3-3.4
              c-1.8-0.8-3.9-1.3-6.2-1.3c-2.3,0-4.4,0.4-6.3,1.3c-1.9,0.8-3.3,2-4.5,3.5c-1.1,1.5-1.6,3.3-1.6,5.3c0,2.4,0.8,4.4,2.5,5.9
              s3.9,2.7,6.7,3.5l4.5,1.3c1.3,0.4,2.5,0.8,3.6,1.2c1.1,0.5,2,1.1,2.7,1.8c0.7,0.8,1,1.7,1,2.9c0,1.3-0.4,2.5-1.2,3.5
              c-0.8,1-1.8,1.7-3.2,2.3c-1.3,0.5-2.8,0.8-4.5,0.8c-1.4,0-2.8-0.2-4-0.6s-2.3-1.1-3.1-2c-0.8-0.9-1.3-2-1.4-3.4h-4.5
              c0.1,2,0.7,3.7,1.8,5.2c1.1,1.5,2.6,2.7,4.5,3.5s4.1,1.3,6.7,1.3c2.8,0,5.1-0.5,7.1-1.4s3.4-2.2,4.5-3.8c1-1.6,1.5-3.3,1.5-5.3
              c0-1.7-0.3-3.1-1-4.3C415.8,55.4,414.9,54.4,413.8,53.7z"
            />
            <path
                fill={props?.color || "#FFFFFF"}
                d="M515.4,43.1c-1.6,0-3.1,0.4-4.4,1.3c-1.3,0.8-2.2,1.9-2.7,3.3H508v-4.1h-4v27.2h4.2V53.5
              c0-1.2,0.3-2.3,0.9-3.3s1.4-1.7,2.4-2.3c1-0.6,2.2-0.8,3.5-0.8c0.6,0,1.1,0,1.6,0.1s0.8,0.1,1,0.2v-4.2c-0.2,0-0.6,0-1-0.1
              C516.1,43.1,515.7,43.1,515.4,43.1z"
            />
            <path
                fill={props?.color || "#FFFFFF"}
                d="M546.2,41.9c0.4-0.1,0.7-0.4,0.7-0.9c0-0.6-0.5-0.9-1.1-0.9c-0.2,0-0.3,0-0.5,0h-1.2v3.5h0.9v-1.2h0.4
              c0.3,0,0.5,0.1,0.5,0.4c0,0.3,0.1,0.6,0.1,0.8h1c-0.1-0.2-0.2-0.5-0.2-0.9C546.7,42.3,546.6,42,546.2,41.9z M545.7,41.7
              c-0.1,0-0.2,0-0.3,0H545v-0.9h0.3c0.2,0,0.2,0,0.3,0c0.2,0.1,0.3,0.2,0.3,0.4C546,41.5,545.9,41.6,545.7,41.7z"
            />
            <path
                fill={props?.color || "#FFFFFF"}
                d="M545.4,38.3c-2.1,0-3.6,1.5-3.6,3.6s1.5,3.6,3.6,3.6s3.6-1.5,3.6-3.6S547.5,38.3,545.4,38.3z M545.4,44.7
              c-1.6,0-2.8-1.2-2.8-2.8s1.2-2.8,2.8-2.8s2.8,1.2,2.8,2.8S547.1,44.7,545.4,44.7z"
            />
        </g>
    </svg>
);

export default SecureLogo;
