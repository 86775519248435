import { makeStyles } from "@mui/styles";

export const useStyles = makeStyles(() => ({
    DisConnectedCard: (props: any) => ({
        border: `2px solid ${props?.theme.palette.divider}`,
        borderRadius: "50%",
        cursor: "pointer",
        "&:hover": {
            background: props?.theme.palette.action.hover,
        },
    }),
    ConnectedCard: {
        border: "2px solid #3DACFF",
        borderRadius: "50%",
        cursor: "pointer",
        background:
            "linear-gradient(180deg, rgba(61, 172, 255, 0.2) 0%, rgba(64, 61, 255, 0.2) 100%)",
        "&:hover": {
            background:
                "linear-gradient(180deg, rgba(61, 172, 255, 0.5) 0%, rgba(64, 61, 255, 0.5) 100%)",
        },
    },
    IGFBDisConnectedCard: (props: any) => ({
        padding: "24px",
        border: `2px solid ${props?.theme.palette.divider}`,
        borderRadius: "12px",
        cursor: "pointer",
        height: "200px",
        "&:hover": {
            background: props?.theme.palette.action.hover,
        },
    }),

    IGFBConnectedCard: {
        padding: "24px",
        borderRadius: "12px",
        border: "2px solid #3DACFF",
        cursor: "pointer",
        height: "200px",
        background:
            "linear-gradient(180deg, rgba(61, 172, 255, 0.2) 0%, rgba(64, 61, 255, 0.2) 100%)",
    },
    unSelectedSlideTab: (props: any) => ({
        width: "8px",
        height: "8px",
        background: props?.config?.button_color ?? props?.theme.palette.primary.main,
        opacity: 0.2,
        borderRadius: "38px",
    }),

    selectedSlideTab: (props: any) => ({
        width: "16px",
        height: "8px",
        background: props?.config?.button_color ?? props?.theme.palette.primary.main,
        borderRadius: "38px",
    }),
}));
