import { useMutation } from "@apollo/client";
import {
    INSTAGRAM_PLATFORM_ID,
    FACEBOOK_PLATFORM_ID,
    FACEBOOK_PERSONAL_PLATFORM_ID,
    TWITTER_PLATFORM_ID,
    YOUTUBE_PLATFORM_ID,
    TIKTOK_PLATFORM_ID,
    LINKEDIN_PLATFORM_ID,
    TWITCH_PLATFORM_ID,
    TOASTIFY_THEME,
} from "../../../../../Utils/constants";
import { BackendClient } from "../../../../../apolloClient/apolloClient";
import { REMOVE_SOCIAL_CONNECT_MONO } from "../../../../../graphQL/Mutations";
import { useEffect } from "react";
import { toast } from "react-toastify";

export default function useRemoveSocialConnect() {
    const [
        removeSocialConnect,
        {
            data: removeSocialConnectData,
            loading: removeSocialConnectLoading,
            error: removeSocialConnectError,
        },
    ] = useMutation(REMOVE_SOCIAL_CONNECT_MONO, {
        client: BackendClient,
        fetchPolicy: "network-only",
        refetchQueries: ["UserSocialConnects"],
    });
    const handleRemoveSocialConnects = (SelectedAccount) => {
        let variables = {};
        if (SelectedAccount?.platform_id === INSTAGRAM_PLATFORM_ID) {
            variables = {
                socialPlatform: "instagram",
                instagramAccountId: SelectedAccount.id,
            };
        } else if (SelectedAccount?.platform_id === FACEBOOK_PLATFORM_ID) {
            variables = {
                socialPlatform: "facebook_pages",
                facebookPageId: SelectedAccount.id,
            };
        } else if (SelectedAccount?.platform_id === FACEBOOK_PERSONAL_PLATFORM_ID) {
            variables = {
                socialPlatform: "facebook",
                facebookUserId: SelectedAccount.id,
            };
        } else if (SelectedAccount?.platform_id === TWITTER_PLATFORM_ID) {
            variables = {
                socialPlatform: "twitter",
                twitterAccountId: SelectedAccount.id,
            };
        } else if (SelectedAccount?.platform_id === YOUTUBE_PLATFORM_ID) {
            variables = {
                socialPlatform: "youtube",
                youtubeChannelId: SelectedAccount.id,
            };
        } else if (SelectedAccount?.platform_id === TIKTOK_PLATFORM_ID) {
            variables = {
                socialPlatform: "tiktok",
                tiktokUniqueKey: SelectedAccount.id,
            };
        } else if (SelectedAccount?.platform_id === LINKEDIN_PLATFORM_ID) {
            variables = {
                socialPlatform: "linkedin",
                linkedinAccountId: SelectedAccount.id,
            };
        } else if (SelectedAccount?.platform_id === TWITCH_PLATFORM_ID) {
            variables = {
                socialPlatform: "twitch",
                twitchAccountId: SelectedAccount.twitch_account_id,
            };
        }
        if (Object.keys(variables)?.length > 0) {
            removeSocialConnect({ variables });
        }
    };

    useEffect(() => {
        if (removeSocialConnectData && !removeSocialConnectLoading && !removeSocialConnectError) {
            toast.success(removeSocialConnectData?.removeSocialConnect?.msg, TOASTIFY_THEME);
        }
        if (!removeSocialConnectData && removeSocialConnectError) {
            toast.error(removeSocialConnectError?.message, TOASTIFY_THEME);
        } // eslint-disable-next-line
    }, [removeSocialConnectData, removeSocialConnectLoading, removeSocialConnectError]);

    return { handleRemoveSocialConnects, removeSocialConnectLoading };
}
