const CircleTwitchIcon = (props) => (
    <svg
        width={props.width}
        height={props.height}
        viewBox="0 0 128 128"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <g clip-path="url(#clip0_14315_160391)">
            <g filter="url(#filter0_b_14315_160391)">
                <rect width="128" height="128" rx="64" fill={props?.color ? "none" : "#8F44FD"} />
            </g>
            <path
                d="M44.0229 33.6045H95.962V69.5623L73.9878 89.5389H44.0229V33.6045Z"
                fill={props?.color ? "none" : "#8F44FD"}
            />
            <path
                d="M62.5618 45.311H68.2751V62.4109H62.5618M78.2633 45.311H83.9766V62.4109H78.2633M44.0235 29.6094L29.7603 43.8726V95.2524H46.8602V109.516L61.1634 95.2524H72.5501L98.2399 69.5625V29.6094M92.5266 66.7258L81.14 78.1125H69.7134L59.7251 88.1007V78.1125H46.8602V35.3227H92.5266V66.7258Z"
                fill={props?.color ? props?.color : "#FCFCFD"}
            />
        </g>
        <defs>
            <filter
                id="filter0_b_14315_160391"
                x="-15"
                y="-15"
                width="158"
                height="158"
                filterUnits="userSpaceOnUse"
                color-interpolation-filters="sRGB"
            >
                <feFlood flood-opacity="0" result="BackgroundImageFix" />
                <feGaussianBlur in="BackgroundImageFix" stdDeviation="7.5" />
                <feComposite
                    in2="SourceAlpha"
                    operator="in"
                    result="effect1_backgroundBlur_14315_160391"
                />
                <feBlend
                    mode="normal"
                    in="SourceGraphic"
                    in2="effect1_backgroundBlur_14315_160391"
                    result="shape"
                />
            </filter>
            <clipPath id="clip0_14315_160391">
                <rect width="128" height="128" fill="white" />
            </clipPath>
        </defs>
    </svg>
);

export default CircleTwitchIcon;
