import { Chip, Grid, Tooltip, Typography } from "@mui/material";
import KeyIcon from "@mui/icons-material/Key";
// import GppBadIcon from "@mui/icons-material/GppBad";
import BusinessIcon from "@mui/icons-material/Business";
// import VerifiedUserIcon from "@mui/icons-material/VerifiedUser";
import PersonIcon from "@mui/icons-material/Person";
import ArticleIcon from "@mui/icons-material/Article";
import {
    FACEBOOK_PERSONAL_PLATFORM_ID,
    FACEBOOK_PLATFORM_ID,
    INSTAGRAM_PLATFORM_ID,
    TIKTOK_PLATFORM_ID,
} from "../../../../Utils/constants";

const ProfileHandlesCheckmarks = (props: any) => {
    const {
        is_token_valid,
        platform_id,
        is_advertiser,
        // is_blocked,
        is_business,
        optin,
        // masterProfile,
        fontSize,
        theme,
    } = props;
    return (
        <Grid container spacing={0.5} alignItems="center">
            {platform_id &&
                (platform_id === TIKTOK_PLATFORM_ID ||
                    platform_id === INSTAGRAM_PLATFORM_ID ||
                    platform_id === FACEBOOK_PERSONAL_PLATFORM_ID ||
                    platform_id === FACEBOOK_PLATFORM_ID) && (
                    <Grid item>
                        <Grid container>
                            <Chip
                                size="small"
                                label={
                                    platform_id === FACEBOOK_PERSONAL_PLATFORM_ID
                                        ? "Personal"
                                        : platform_id === FACEBOOK_PLATFORM_ID
                                        ? "Page"
                                        : is_advertiser === "true"
                                        ? "Advertiser"
                                        : is_business
                                        ? "Business"
                                        : "Personal"
                                }
                                sx={{
                                    color: theme.palette.text.disabled,
                                    borderRadius: "16px",
                                    fontSize: "12px !important",
                                    backgroundColor: theme.palette.card.dark,
                                    paddingLeft: "2px",
                                }}
                                icon={
                                    platform_id === FACEBOOK_PLATFORM_ID ? (
                                        <ArticleIcon
                                            sx={{
                                                fontSize: "15px !important",
                                                color: `${theme.palette.text.disabled} !important`,
                                                cursor: "pointer",
                                            }}
                                        />
                                    ) : is_business ? (
                                        <BusinessIcon
                                            sx={{
                                                fontSize: "15px !important",
                                                color: `${theme.palette.text.disabled} !important`,
                                                cursor: "pointer",
                                            }}
                                        />
                                    ) : (
                                        <PersonIcon
                                            sx={{
                                                fontSize: "15px !important",
                                                color: `${theme.palette.text.disabled} !important`,
                                                cursor: "pointer",
                                            }}
                                        />
                                    )
                                }
                            />
                        </Grid>
                    </Grid>
                )}
            {/* {optin && !masterProfile && (
                <Grid item>
                    <Grid container>
                        {is_blocked ? (
                            <Tooltip
                                title={
                                    <Typography fontSize="12px" color={theme.palette.error.main}>
                                        No Access
                                    </Typography>
                                }
                            >
                                <GppBadIcon
                                    sx={{
                                        fontSize: fontSize ? fontSize : "20px",
                                        color: `${theme.palette.error.main} !important`,
                                        cursor: "pointer",
                                        marginTop: "-1px",
                                    }}
                                />
                            </Tooltip>
                        ) : (
                            <Tooltip
                                title={
                                    <Typography fontSize="12px" color={theme.palette.success.main}>
                                        Access
                                    </Typography>
                                }
                            >
                                <VerifiedUserIcon
                                    sx={{
                                        fontSize: fontSize ? fontSize : "18px",
                                        color: `${theme.palette.success.main} !important`,
                                        cursor: "pointer",
                                    }}
                                />
                            </Tooltip>
                        )}
                    </Grid>
                </Grid>
            )} */}
            {optin && (
                <Grid item>
                    <Grid container>
                        {is_token_valid ? (
                            <Tooltip
                                title={
                                    <Typography fontSize="12px" color={theme.palette.success.main}>
                                        Valid
                                    </Typography>
                                }
                            >
                                <KeyIcon
                                    sx={{
                                        fontSize: fontSize ? fontSize : "18px",
                                        color: `${theme.palette.success.main} !important`,
                                        cursor: "pointer",
                                    }}
                                />
                            </Tooltip>
                        ) : (
                            <Tooltip
                                title={
                                    <Typography fontSize="12px" color={theme.palette.error.main}>
                                        Expired
                                    </Typography>
                                }
                            >
                                <KeyIcon
                                    sx={{
                                        fontSize: fontSize ? fontSize : "18px",
                                        color: `${theme.palette.error.main} !important`,
                                        cursor: "pointer",
                                    }}
                                />
                            </Tooltip>
                        )}
                    </Grid>
                </Grid>
            )}
        </Grid>
    );
};
export default ProfileHandlesCheckmarks;
