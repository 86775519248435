import { useMutation } from "@apollo/client";
import { DOMAINS_MAP } from "../../../../../Utils/constants";
import { Authclient } from "../../../../../apolloClient/apolloClient";
import { GET_SSO_AUTH_KEY } from "../../../../../graphQL/Mutations";

export default function useSsoAuth(domainId) {
    const [getSsoAuthKey] = useMutation(GET_SSO_AUTH_KEY, { client: Authclient });
    const handleRedirect = async (user_id) => {
        const { data } = await getSsoAuthKey({ variables: { userId: user_id } });
        const auth_key = data?.authKeyForSSO?.data?.access_token ?? data?.authKeyForSSO?.data;
        if (auth_key && user_id) {
            const url = `${DOMAINS_MAP[domainId || "1"]}/${auth_key}/${user_id}`;
            if (window.self !== window.top) {
                // checking if it is an iframe
                window.parent.location.href = url;
            } else {
                window.location.href = url;
            }
        }
    };
    return { handleRedirect };
}
