import { ThemeProvider } from "@mui/material";
import { Dark, Light } from "../../../../core/theme/SecureTheme";
import { useParams } from "react-router-dom";
// import MainPage from "../MainPage/MainPage";
// import { ENV } from "../../../../Utils/constants";
import MainPageV2 from "../MainPageV2";

const ThemePage = (props: any) => {
    const { config } = props;
    const { theme }: any = useParams();

    return (
        <ThemeProvider theme={theme === "dark" ? Dark : Light}>
            {/* {ENV !== "prod" ? ( */}
            <MainPageV2 config={config} theme={theme === "dark" ? Dark : Light} />
            {/* ) : (
                <MainPage config={config} theme={theme === "dark" ? Dark : Light} />
            )} */}
        </ThemeProvider>
    );
};

export default ThemePage;
