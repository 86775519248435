import { Typography } from "@mui/material";
import SecurityIcon from "@mui/icons-material/Security";
import VerifiedIcon from "@mui/icons-material/Verified";
import {
    FACEBOOK_PLATFORM_ID,
    INSTAGRAM_PLATFORM_ID,
    LINKEDIN_PLATFORM_ID,
    TIKTOK_PLATFORM_ID,
    TWITTER_PLATFORM_ID,
    YOUTUBE_PLATFORM_ID,
    TWITCH_PLATFORM_ID,
} from "../../../../../Utils/constants";
import InstaFBBusiness from "../../../../../assets/instaFBBusiness";
import InstaFBPersonal from "../../../../../assets/instaFBPersonal";

export const SocialConnectFeatures = (theme, config) => {
    const socialConnectFeaturesArr = [
        {
            title: "Your data is protected",
            icon: (
                <SecurityIcon sx={{ color: config?.button_color ?? theme.palette.primary.main }} />
            ),
            description:
                "VN Secure has implemented strict security measures to protect our clients' data, including encryption and regular backups.",
        },
        {
            title: "Verify your social identity",
            icon: (
                <VerifiedIcon sx={{ color: config?.button_color ?? theme.palette.primary.main }} />
            ),
            description:
                "Verifying all of your social connections allows us to tailor your platform experience and provide a complete profile.",
        },
    ];

    return socialConnectFeaturesArr;
};

export const SocialPlatforms = [
    {
        platform_id: FACEBOOK_PLATFORM_ID,
        name: "Facebook",
        connected: 0,
    },
    {
        platform_id: INSTAGRAM_PLATFORM_ID,
        name: "Instagram",
        connected: 0,
    },
    {
        platform_id: TIKTOK_PLATFORM_ID,
        name: "TikTok",
        connected: 0,
    },
    {
        platform_id: YOUTUBE_PLATFORM_ID,
        name: "YouTube",
        connected: 0,
    },
    {
        platform_id: TWITTER_PLATFORM_ID,
        name: "Twitter",
        connected: 0,
    },
    {
        platform_id: LINKEDIN_PLATFORM_ID,
        name: "LinkedIn",
        connected: 0,
    },
    {
        platform_id: TWITCH_PLATFORM_ID,
        name: "Twitch",
        connected: 0,
    },
];

export const InstagramSelectionTypes = (selectedPlatform) => {
    const instaTypes = [
        {
            svg_icon: <InstaFBPersonal height="88px" width="116px" />,
            icon_platform_ids:
                selectedPlatform === "instagram" ? [INSTAGRAM_PLATFORM_ID] : [FACEBOOK_PLATFORM_ID],
            name: "Personal",
            social_connect: selectedPlatform === "instagram" ? "instagram" : "Facebook",
            recommended: false,
        },
        {
            svg_icon: <InstaFBBusiness height="88px" width="116px" />,
            icon_platform_ids: [FACEBOOK_PLATFORM_ID, INSTAGRAM_PLATFORM_ID],
            name: "Business",
            social_connect: "instagram_business",
            recommended: true,
        },
    ];
    return instaTypes;
};

// Different account types for tiktok - used in tiktok modal
export const TiktokSelectionTypes = [
    {
        svg_icon: <InstaFBBusiness height="88px" width="116px" />,
        icon_platform_ids: [TIKTOK_PLATFORM_ID],
        name: "Advertiser",
        social_connect: "tiktok_ad",
        recommended: false,
    },
    {
        svg_icon: <InstaFBBusiness height="88px" width="116px" />,
        icon_platform_ids: [TIKTOK_PLATFORM_ID],
        name: "Personal & Business",
        social_connect: "tiktok",
        recommended: true,
    },
];

export const capitalizeFirstLetter = (string) => {
    return string?.charAt(0)?.toUpperCase() + string?.slice(1);
};

export const DisabledConsentPlatformsTootipTitle = (socialName, theme) => {
    return (
        <Typography fontSize="11px" color={theme.palette.text.primary} textAlign="center">
            {socialName} is currently in beta testing and only available for features which include
            post scheduling and analytics
        </Typography>
    );
};

export const TooltipTitle = (theme) => {
    return (
        <Typography fontSize="11px" color={theme.palette.text.primary} textAlign="center">
            Please Allow ALL Permissions (including optional ones)
        </Typography>
    );
};
