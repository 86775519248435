import { gql } from "@apollo/client";

export const INSTAGRAM_SAVE_ACCOUNT = gql`
    mutation InstagramSaveAccount($userId: Int!, $accountDetails: [AccountDetails!]!) {
        instagramSaveAccount(user_id: $userId, account_details: $accountDetails) {
            code
            status
            msg
            data
        }
    }
`;

export const FACEBOOK_SAVE_ACCOUNT = gql`
    mutation SaveFacebookPages($fbUserId: String!, $fbAccountName: String!, $fbPages: [FbPages!]!) {
        saveFacebookPages(
            fb_user_id: $fbUserId
            fb_account_name: $fbAccountName
            fb_pages: $fbPages
        ) {
            code
            status
            msg
            data
        }
    }
`;

export const GENERATE_AUTH_URL = gql`
    mutation GenerateAuthUrl($socialPlatform: String!, $callbackUrl: String!) {
        generateAuthUrl(social_platform: $socialPlatform, callback_url: $callbackUrl) {
            code
            status
            msg
            data
        }
    }
`;

// For FB and IG
export const VERIFY_SOCIAL_MEDIA_FB_IG = gql`
    mutation VerifySocialMediaToken(
        $socialPlatform: String!
        $callbackUrl: String
        $fbToken: String
        $scope: String
    ) {
        verifySocialMediaToken(
            social_platform: $socialPlatform
            callback_url: $callbackUrl
            fb_token: $fbToken
            scope: $scope
        ) {
            code
            status
            msg
            data
        }
    }
`;

// For YT, TT, LI, TC(TWITCH)
export const VERIFY_SOCIAL_MEDIA_YT_TT_LI_TC = gql`
    mutation VerifySocialMediaToken(
        $socialPlatform: String!
        $callbackUrl: String
        $code: String
        $scope: String
    ) {
        verifySocialMediaToken(
            social_platform: $socialPlatform
            callback_url: $callbackUrl
            code: $code
            scope: $scope
        ) {
            code
            status
            msg
            data
        }
    }
`;

// // For TW
export const VERIFY_SOCIAL_MEDIA_TW = gql`
    mutation VerifySocialMediaToken(
        $socialPlatform: String!
        $oauthToken: String
        $oauthVerifier: String
        $callbackUrl: String
        $oauthVerifierServer: String
        $scope: String
    ) {
        verifySocialMediaToken(
            social_platform: $socialPlatform
            oauth_token: $oauthToken
            oauth_verifier: $oauthVerifier
            callback_url: $callbackUrl
            oauth_verifier_server: $oauthVerifierServer
            scope: $scope
        ) {
            code
            status
            msg
            data
        }
    }
`;

// For tiktok business
export const VERIFY_SOCIAL_MEDIA_TT_PERSONAL = gql`
    mutation VerifySocialMediaToken($socialPlatform: String!, $code: String, $scope: String) {
        verifySocialMediaToken(social_platform: $socialPlatform, code: $code, scope: $scope) {
            code
            status
            msg
            data
        }
    }
`;

// For instagram personal
export const VERIFY_SOCIAL_MEDIA_IN_PERSONAL = gql`
    mutation VerifySocialMediaToken(
        $socialPlatform: String!
        $callbackUrl: String
        $instaToken: String
        $scope: String
    ) {
        verifySocialMediaToken(
            social_platform: $socialPlatform
            callback_url: $callbackUrl
            insta_token: $instaToken
            scope: $scope
        ) {
            code
            status
            msg
            data
        }
    }
`;

export const UPDATE_PROFILE_CONSENT = gql`
    mutation UpdateProfileConsent($input: ProfileConsentInput) {
        updateProfileConsent(input: $input) {
            message
        }
    }
`;

export const GET_SSO_AUTH_KEY = gql`
    mutation AuthKeyForSSO($userId: Int!) {
        authKeyForSSO(user_id: $userId) {
            code
            status
            msg
            data
        }
    }
`;

export const GET_TOKEN_AUTH_KEY = gql`
    mutation AuthSSO($userId: Int!, $authKey: String) {
        authSSO(user_id: $userId, auth_key: $authKey) {
            data
            code
            msg
            status
        }
    }
`;
// common api to remove all the social connects
export const REMOVE_SOCIAL_CONNECT = gql`
    mutation RemoveSocialConnect(
        $socialPlatform: String
        $youtubeChannelId: String
        $linkedinAccountId: String
        $tiktokUniqueKey: String
        $facebookUserId: String
        $instagramAccountId: String
        $twitterAccountId: String
        $twitchAccountId: String
        $facebookPageId: String
    ) {
        removeSocialConnect(
            social_platform: $socialPlatform
            youtube_channel_id: $youtubeChannelId
            linkedin_account_id: $linkedinAccountId
            tiktok_unique_key: $tiktokUniqueKey
            facebook_user_id: $facebookUserId
            instagram_account_id: $instagramAccountId
            twitter_account_id: $twitterAccountId
            twitch_account_id: $twitchAccountId
            facebook_page_id: $facebookPageId
        ) {
            code
            status
            msg
            data
        }
    }
`;

export const REMOVE_SOCIAL_CONNECT_MONO = gql`
    mutation RemoveSocialConnect(
        $socialPlatform: SocialPlatforms
        $youtubeChannelId: String
        $linkedinAccountId: String
        $tiktokUniqueKey: String
        $facebookUserId: String
        $instagramAccountId: String
        $twitterAccountId: String
        $facebookPageId: String
    ) {
        removeSocialConnect(
            social_platform: $socialPlatform
            youtube_channel_id: $youtubeChannelId
            linkedin_account_id: $linkedinAccountId
            tiktok_unique_key: $tiktokUniqueKey
            facebook_user_id: $facebookUserId
            instagram_account_id: $instagramAccountId
            twitter_account_id: $twitterAccountId
            facebook_page_id: $facebookPageId
        ) {
            msg
            social_user_id
        }
    }
`;

// Update Platform orders of Master Profile
export const UPDATE_PLATFORM_ORDERS_MASTER_PROFILE = gql`
    mutation UpdateDefaultMediakitPlatformOrder(
        $userId: Int!
        $platformOrder: [OrderedPlatformInput!]!
    ) {
        updateDefaultMediakitPlatformOrder(user_id: $userId, platform_order: $platformOrder)
    }
`;

export const ACCEPT_CREATOR_INVITE = gql`
    mutation AcceptCreatorInvite($invitationKey: String!) {
        acceptCreatorInvite(invitation_key: $invitationKey) {
            code
            status
            msg
            data
        }
    }
`;

export const TRIGGER_CHALLENGE_ACTION = gql`
    mutation ActionByUser($action: String!, $count: Int) {
        actionByUser(action: $action, count: $count) {
            code
            status
            msg
            data
        }
    }
`;
