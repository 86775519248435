import { ApolloClient, createHttpLink, DefaultOptions, InMemoryCache } from "@apollo/client";
import { setContext } from "@apollo/client/link/context";
import { getAccessToken } from "../Utils/getToken";
import {
    REACT_APP_AUTH_API_URL_GRAPHQL,
    REACT_APP_PROFILE_API_URL_GRAPHQL,
    REACT_APP_SECURE_API_URL_GRAPHQL,
    REACT_APP_USER_API_URL_GRAPHQL,
    REACT_APP_CONNECTS_API_URL_GRAPHQL,
    REACT_APP_BACKEND_API_URL_GRAPHQL,
} from "../Utils/constants";

const defaultOptions: DefaultOptions = {
    watchQuery: {
        fetchPolicy: "network-only",
        errorPolicy: "ignore",
    },
    query: {
        fetchPolicy: "network-only",
        errorPolicy: "all",
    },
};
const httpLinkAuth = createHttpLink({
    uri: REACT_APP_AUTH_API_URL_GRAPHQL,
});
const httpLinkUser = createHttpLink({
    uri: `${REACT_APP_USER_API_URL_GRAPHQL}`,
});
const httpLinkConnects = createHttpLink({
    uri: REACT_APP_CONNECTS_API_URL_GRAPHQL,
});

const httpLinkBackend = createHttpLink({
    uri: REACT_APP_BACKEND_API_URL_GRAPHQL,
});

const httpLinkSecure = createHttpLink({
    uri: `${REACT_APP_SECURE_API_URL_GRAPHQL}`,
});

const httpLinkProfile = createHttpLink({
    uri: REACT_APP_PROFILE_API_URL_GRAPHQL,
});
const authLink = setContext((_, { headers }) => {
    // get the authentication token from Session storage if its shareLink as special_token should be used first and it stays in Session
    // storage and local storage is checked after and it stays until token is expired
    const token = getAccessToken();
    // return the headers to the context so httpLink can read them
    return {
        headers: {
            ...headers,
            Authorization: `${token}`,
        },
    };
});

export const Authclient = new ApolloClient({
    cache: new InMemoryCache(),
    link: authLink.concat(httpLinkAuth),
    defaultOptions: defaultOptions,
});

export const Userclient = new ApolloClient({
    cache: new InMemoryCache(),
    link: authLink.concat(httpLinkUser),
    defaultOptions: defaultOptions,
});

export const ConnectsClient = new ApolloClient({
    cache: new InMemoryCache(),
    link: authLink.concat(httpLinkConnects),
    defaultOptions: defaultOptions,
});

export const BackendClient = new ApolloClient({
    cache: new InMemoryCache(),
    link: authLink.concat(httpLinkBackend),
    defaultOptions: defaultOptions,
});

export const SecureClient = new ApolloClient({
    cache: new InMemoryCache(),
    link: authLink.concat(httpLinkSecure),
    defaultOptions: defaultOptions,
});

export const ProfileClient = new ApolloClient({
    cache: new InMemoryCache(),
    link: authLink.concat(httpLinkProfile),
    defaultOptions: defaultOptions,
});
