import { SvgIcon, SvgIconProps } from "@mui/material";

const VNLogo = (props: SvgIconProps) => {
  return (
    <SvgIcon {...props} viewBox="0 2 100 100" fill="inherit" xmlns="http://www.w3.org/2000/svg">
      <g>
        <path
          fill="inherit"
          d="M93.9,15.1H82.7L50,71.7L34.5,44.9l-6.6,11.4l16.2,28c0.2,0.4,0.6,0.6,1,0.6h9.8c0.4,0,0.8-0.2,1-0.6l39-67.6
		C95.3,16,94.7,15.1,93.9,15.1z"
        />
        <path
          fill="inherit"
          d="M50,61.3l26.8-46.2H64.2c-0.4,0-0.8,0.3-1,0.6L50,38.5L36.8,15.7c-0.2-0.3-0.6-0.6-1-0.6H6.1
		c-0.9,0-1.4,1-1,1.8l19.6,34l9.6-16.6L50,61.3z"
        />
      </g>
    </SvgIcon>
  );
};

export default VNLogo;
