const InstaFBPersonal = (props) => (
    <svg
        width={props.width}
        height={props.height}
        viewBox="0 0 117 88"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <g clip-path="url(#clip0_8293_17184)">
            <path
                d="M69.6279 74.0799L74.7587 65.8837C79.8118 66.0205 84.7554 67.3967 89.1601 69.8929C80.2998 71.4015 75.6284 81.4234 67.7442 85.7631C65.2749 87.0976 62.5388 87.8558 59.7388 87.9813C56.9388 88.1069 54.1466 87.5967 51.5691 86.4885L44.7461 86.7057C46.2562 82.4129 48.6731 78.5006 51.8301 75.2385C54.9871 71.9763 58.8092 69.4418 63.0332 67.8095C65.4118 66.9174 67.8926 66.3304 70.417 66.0622C69.8089 69.9722 69.6279 74.0799 69.6279 74.0799Z"
                fill="#313131"
            />
            <path
                d="M74.1462 86.6849C74.0619 86.8856 73.9743 87.0828 73.8815 87.28C73.8161 87.4167 73.7491 87.5551 73.6786 87.6901L71.8961 87.6918H69.9246L68.8486 87.6935C68.7352 87.5603 68.6234 87.4236 68.5169 87.2835C67.23 85.5961 66.585 83.5013 66.6983 81.3772C66.8135 79.5867 67.4597 77.8376 68.094 76.1059C68.9706 73.715 69.8112 71.3587 69.1528 68.9626C70.1469 69.6707 71.0468 70.5039 71.8308 71.4418C71.4576 71.7802 71.1534 72.1886 70.9353 72.6441C70.8734 72.7721 70.8236 72.9434 70.9233 73.0472C70.9734 73.0826 71.0312 73.1055 71.0918 73.1142C71.1524 73.123 71.2142 73.1172 71.2722 73.0974C71.561 73.0403 71.8532 72.9849 72.1437 72.9278C72.3757 72.8846 72.6078 72.8413 72.8398 72.7964C73.3805 73.6127 73.8411 74.4801 74.2149 75.3862C74.7998 76.7944 75.1601 78.2868 75.2823 79.8081C75.4561 82.1589 75.0665 84.5173 74.1462 86.6849Z"
                fill="#3F3D56"
            />
            <path
                d="M78.134 77.8654C78.0739 77.9934 78.0223 78.1647 78.1237 78.2668C78.1732 78.3028 78.2306 78.3263 78.291 78.3354C78.3515 78.3444 78.4132 78.3387 78.4709 78.3187C78.7597 78.2616 79.0536 78.2079 79.3424 78.1508C79.296 79.7822 79.2943 81.4413 78.966 83.0105C78.8922 83.3653 78.7992 83.7159 78.6875 84.0606C78.2927 85.2594 77.6434 86.3574 76.7847 87.2784C76.7641 87.3026 76.7435 87.3251 76.7211 87.3476C76.6128 87.4635 76.4977 87.5777 76.3808 87.6884L73.6787 87.6901L71.8963 87.6919H69.9247C69.9093 87.5552 69.8938 87.4185 69.8817 87.2819C69.6435 84.7402 70.0591 82.1796 71.0884 79.8462C71.1554 79.6975 71.2242 79.5522 71.2929 79.4034C72.0315 77.906 73.0199 76.5472 74.215 75.3863C76.0943 73.5456 78.4283 72.2441 80.9753 71.6166C79.9371 72.9885 79.5796 74.6182 79.4369 76.3412C78.8788 76.7271 78.4299 77.2523 78.134 77.8654Z"
                fill="#3DACFF"
            />
            <path
                d="M72.0377 87.2802C72.0102 87.3649 71.9827 87.4497 71.9535 87.5327C71.9346 87.5864 71.9157 87.64 71.8951 87.6919H69.9235L68.8475 87.6936L65.8601 87.6954C65.7828 87.6019 65.7054 87.5068 65.6298 87.4099C65.5971 87.3684 65.5645 87.3269 65.5336 87.2853C64.4384 85.8822 63.6104 84.2874 63.091 82.5814C62.482 80.5175 62.3247 78.3453 62.6301 76.2143C62.9354 74.0834 63.6961 72.0444 64.8597 70.2378C65.0282 71.8917 65.824 73.2843 66.8347 74.5992C66.7832 74.6424 66.7316 74.6822 66.6835 74.7289C66.3101 75.0662 66.0064 75.4742 65.7897 75.9295C65.7278 76.0593 65.6779 76.2306 65.7759 76.3309C65.8253 76.3673 65.8827 76.391 65.9431 76.4001C66.0036 76.4091 66.0654 76.4032 66.1231 76.3828C66.4153 76.3274 66.7075 76.2704 66.9963 76.215C67.2283 76.1717 67.4587 76.1268 67.6907 76.0835C67.8007 76.0627 67.9107 76.042 68.019 76.0212C68.0448 76.0506 68.0689 76.0783 68.0929 76.106C69.1535 77.3118 70.2639 78.5073 71.0872 79.8463C71.2933 80.1823 71.4809 80.5294 71.6493 80.886C72.5616 82.8953 72.7 85.174 72.0377 87.2802Z"
                fill="#3DACFF"
            />
            <path
                d="M102.499 25.3239H96.3758C95.0551 25.3239 93.9844 26.4015 93.9844 27.7308V27.7308C93.9844 29.0602 95.0551 30.1378 96.3758 30.1378H102.499C103.819 30.1378 104.89 29.0602 104.89 27.7308C104.89 26.4015 103.819 25.3239 102.499 25.3239Z"
                fill="#E6E6E6"
            />
            <path
                d="M113.858 34.9518H85.0165C83.6957 34.9518 82.625 36.0294 82.625 37.3588V37.3588C82.625 38.6881 83.6957 39.7657 85.0165 39.7657H113.858C115.179 39.7657 116.25 38.6881 116.25 37.3588C116.25 36.0294 115.179 34.9518 113.858 34.9518Z"
                fill="#E6E6E6"
            />
            <path
                d="M101.483 42.1576H72.6415C71.3207 42.1576 70.25 43.2352 70.25 44.5646C70.25 45.8939 71.3207 46.9715 72.6415 46.9715H101.483C102.804 46.9715 103.875 45.8939 103.875 44.5646C103.875 43.2352 102.804 42.1576 101.483 42.1576Z"
                fill="#3DACFF"
            />
            <path
                d="M114.577 87.4773C114.577 87.5044 114.572 87.5312 114.562 87.5562C114.552 87.5812 114.536 87.604 114.517 87.6231C114.498 87.6422 114.476 87.6574 114.451 87.6677C114.426 87.678 114.4 87.6833 114.373 87.6832L103.539 87.6884L100.837 87.6901L99.0541 87.6919H97.0825L96.0065 87.6936L93.0191 87.6953L46.0149 87.7195L45.6574 87.7213L24.6322 87.7282H24.285L6.38808 87.7351H6.04087L0.454546 87.7368C0.400297 87.7368 0.348269 87.7152 0.30991 87.6765C0.27155 87.6379 0.25 87.5856 0.25 87.531C0.25 87.4764 0.27155 87.424 0.30991 87.3854C0.348269 87.3468 0.400297 87.3251 0.454546 87.3251L6.09931 87.3234H6.44652L24.3382 87.3165H24.6855L45.5474 87.3095H45.9032L63.6797 87.3026H63.9135L92.6925 87.2853L95.6748 87.2836L97.0396 87.2819L99.1967 87.2801H101.039L103.943 87.2784L114.373 87.2715C114.4 87.2714 114.426 87.2767 114.451 87.287C114.476 87.2973 114.498 87.3125 114.517 87.3316C114.536 87.3507 114.552 87.3735 114.562 87.3985C114.572 87.4235 114.577 87.4503 114.577 87.4773Z"
                fill="#CACACA"
            />
            <path
                d="M44.5125 18.5771C44.4403 18.577 44.3711 18.5481 44.3201 18.4967C44.269 18.4453 44.2403 18.3757 44.2402 18.303V16.4398C44.2404 16.3673 44.2692 16.2978 44.3202 16.2465C44.3713 16.1953 44.4404 16.1665 44.5125 16.1665C44.5846 16.1665 44.6537 16.1953 44.7047 16.2465C44.7558 16.2978 44.7845 16.3673 44.7847 16.4398V18.303C44.7846 18.3757 44.7559 18.4453 44.7049 18.4967C44.6539 18.5481 44.5847 18.577 44.5125 18.5771Z"
                fill="#3F3D56"
            />
            <path
                d="M62.5506 12.2873V6.7257C62.5506 5.872 62.2136 5.05327 61.6138 4.44962C61.0141 3.84597 60.2006 3.50684 59.3524 3.50684H47.6454C46.7972 3.50684 45.9837 3.84597 45.384 4.44962C44.7842 5.05328 44.4473 5.87201 44.4473 6.72571V37.2368C44.4473 38.0905 44.7842 38.9093 45.384 39.5129C45.9837 40.1166 46.7972 40.4557 47.6454 40.4557H59.3524C60.2006 40.4557 61.0141 40.1166 61.6138 39.5129C62.2136 38.9093 62.5506 38.0905 62.5506 37.2369V16.2461C62.6041 16.2461 62.6554 16.2247 62.6932 16.1866C62.731 16.1485 62.7523 16.0969 62.7523 16.0431V12.4903C62.7523 12.4364 62.731 12.3848 62.6932 12.3467C62.6554 12.3087 62.6041 12.2873 62.5506 12.2873Z"
                fill="#3F3D56"
            />
            <path
                d="M61.8708 6.74711V37.2144C61.871 37.8473 61.623 38.4547 61.1808 38.9046C60.7386 39.3546 60.1378 39.6107 59.509 39.6174C59.5005 39.6188 59.4919 39.6194 59.4832 39.6191H47.7193C47.0857 39.619 46.478 39.3656 46.03 38.9147C45.5819 38.4637 45.3302 37.8521 45.3301 37.2144V6.74711C45.3302 6.43142 45.392 6.11884 45.5122 5.82722C45.6323 5.5356 45.8083 5.27066 46.0302 5.04752C46.252 4.82437 46.5154 4.6474 46.8052 4.52671C47.095 4.40602 47.4056 4.34397 47.7193 4.34412H49.146C49.0756 4.51769 49.0487 4.70603 49.0677 4.89253C49.0867 5.07903 49.151 5.25796 49.2549 5.41353C49.3589 5.5691 49.4993 5.69654 49.6637 5.78459C49.8282 5.87264 50.0116 5.9186 50.1979 5.91843H56.9049C57.0513 5.91831 57.1962 5.88891 57.3312 5.83193C57.4693 5.77559 57.595 5.69236 57.7011 5.58702C57.8071 5.48168 57.8915 5.3563 57.9493 5.21807C58.0071 5.07984 58.0372 4.93148 58.038 4.78151C58.0387 4.63153 58.01 4.4829 57.9535 4.34412H59.4832C60.1164 4.34419 60.7237 4.59739 61.1714 5.04802C61.6191 5.49865 61.8707 6.10982 61.8708 6.74711Z"
                fill="white"
            />
            <path
                d="M44.5125 15.3985C44.4403 15.3984 44.3711 15.3696 44.3201 15.3182C44.269 15.2668 44.2403 15.1972 44.2402 15.1245V13.2613C44.2402 13.1886 44.2689 13.1189 44.32 13.0676C44.371 13.0162 44.4403 12.9873 44.5125 12.9873C44.5847 12.9873 44.6539 13.0162 44.705 13.0676C44.756 13.1189 44.7847 13.1886 44.7847 13.2613V15.1245C44.7846 15.1972 44.7559 15.2668 44.7049 15.3182C44.6539 15.3696 44.5847 15.3984 44.5125 15.3985Z"
                fill="#3F3D56"
            />
            <path
                d="M44.4578 11.7817C44.3856 11.7816 44.3164 11.7527 44.2654 11.7014C44.2143 11.65 44.1856 11.5804 44.1855 11.5077V9.64449C44.1855 9.57182 44.2142 9.50212 44.2653 9.45074C44.3163 9.39935 44.3856 9.37048 44.4578 9.37048C44.53 9.37048 44.5992 9.39935 44.6503 9.45074C44.7013 9.50212 44.73 9.57182 44.73 9.64449V11.5077C44.7299 11.5804 44.7012 11.65 44.6502 11.7014C44.5992 11.7527 44.53 11.7816 44.4578 11.7817Z"
                fill="#3F3D56"
            />
            <path
                d="M51.6992 4.65755C51.6993 4.58491 51.728 4.51526 51.7791 4.4639C51.8301 4.41253 51.8993 4.38363 51.9715 4.38355H53.8227C53.8585 4.38346 53.894 4.39048 53.9271 4.4042C53.9602 4.41793 53.9903 4.4381 54.0156 4.46356C54.041 4.48901 54.0611 4.51925 54.0748 4.55253C54.0885 4.58582 54.0956 4.62151 54.0956 4.65755C54.0956 4.69359 54.0885 4.72928 54.0748 4.76257C54.0611 4.79586 54.041 4.82609 54.0156 4.85154C53.9903 4.877 53.9602 4.89717 53.9271 4.91089C53.894 4.92462 53.8585 4.93164 53.8227 4.93155H51.9715C51.8993 4.93147 51.8301 4.90257 51.7791 4.85121C51.728 4.79984 51.6993 4.7302 51.6992 4.65755Z"
                fill="white"
            />
            <path
                d="M54.8582 4.93155C55.0085 4.93155 55.1304 4.80888 55.1304 4.65755C55.1304 4.50622 55.0085 4.38354 54.8582 4.38354C54.7078 4.38354 54.5859 4.50622 54.5859 4.65755C54.5859 4.80888 54.7078 4.93155 54.8582 4.93155Z"
                fill="white"
            />
            <path
                d="M53.5723 14.705C53.4234 14.4572 53.2471 14.1926 52.9708 14.1117C52.6509 14.018 52.3222 14.2042 52.0435 14.3879C51.2098 14.9372 50.407 15.5326 49.6387 16.1712L49.6395 16.1808L51.434 16.0562C51.8662 16.0262 52.3132 15.9921 52.6951 15.7863C52.8295 15.6903 52.9833 15.6255 53.1456 15.5967C53.2475 15.6043 53.3468 15.6336 53.4367 15.6825C53.5266 15.7315 53.6052 15.7991 53.6673 15.8809C54.4943 16.7673 54.7337 18.1228 55.6615 18.9015C55.0764 17.4493 54.3778 16.0461 53.5723 14.705Z"
                fill="#313131"
            />
            <path
                d="M58.6574 22.2231C58.5659 22.1068 58.5286 22.0796 58.4383 21.9623C57.7038 21.0061 57.0134 20.0176 56.3671 18.9969C55.9281 18.3036 55.5109 17.597 55.1155 16.8771C54.9272 16.5349 54.7441 16.1899 54.5662 15.8423C54.4281 15.5728 54.2931 15.3016 54.1612 15.0289C54.1235 14.951 54.0868 14.8727 54.0504 14.7943C53.9644 14.6098 53.8801 14.4244 53.7895 14.2425C53.6959 14.024 53.5526 13.8308 53.3711 13.6783C53.2849 13.6123 53.1818 13.5724 53.0738 13.5632C52.9217 13.564 52.7728 13.607 52.6434 13.6873C51.6634 14.2058 50.7486 14.8407 49.9188 15.5783C49.0876 16.313 48.3447 17.143 47.7052 18.0514C47.6856 18.0796 47.6394 18.0527 47.6591 18.0244C47.7369 17.9137 47.8162 17.8042 47.897 17.6959C48.9022 16.3503 50.1348 15.1932 51.5384 14.2774C51.7745 14.1234 52.0151 13.9766 52.2601 13.837C52.3836 13.7666 52.5076 13.6971 52.6338 13.632C52.7546 13.5606 52.8903 13.5183 53.03 13.5084C53.5108 13.5009 53.7598 14.0489 53.9308 14.4176C53.9845 14.5337 54.0389 14.6494 54.0939 14.7647C54.302 15.2026 54.518 15.6365 54.742 16.0664C54.8802 16.3318 55.0215 16.5956 55.1659 16.858C55.615 17.6748 56.0923 18.4751 56.5979 19.2589C57.2677 20.2971 57.9321 21.2142 58.6951 22.1851C58.7161 22.212 58.6786 22.2503 58.6574 22.2231Z"
                fill="#E4E4E4"
            />
            <path
                d="M49.871 15.3077C49.843 15.2763 49.8153 15.245 49.7872 15.2136C49.5774 14.9532 49.3175 14.7382 49.0231 14.5814C48.8779 14.5119 48.7195 14.4749 48.5587 14.4731C48.3928 14.4777 48.2292 14.5136 48.0765 14.5789C48.0039 14.6081 47.9329 14.6413 47.8629 14.6765C47.783 14.717 47.7046 14.7602 47.6265 14.8037C47.4798 14.8854 47.3351 14.9706 47.1923 15.0594C46.9081 15.2362 46.6328 15.4265 46.3665 15.6302C46.2284 15.7359 46.0931 15.845 45.9608 15.9576C45.8376 16.0622 45.717 16.1696 45.5988 16.2798C45.5735 16.3032 45.5358 16.2653 45.5611 16.2418C45.5922 16.2126 45.6237 16.1836 45.6552 16.155C45.7442 16.074 45.8346 15.9946 45.9264 15.9168C46.0939 15.7746 46.2656 15.6379 46.4417 15.5066C46.7156 15.3025 46.9989 15.1122 47.2916 14.9357C47.4378 14.8476 47.5861 14.7632 47.7364 14.6824C47.7818 14.6581 47.8277 14.6343 47.8741 14.6117C47.9795 14.5582 48.089 14.5134 48.2015 14.4776C48.361 14.4257 48.5296 14.409 48.696 14.4287C48.8567 14.4522 49.0115 14.5061 49.1523 14.5875C49.4401 14.7712 49.6957 15.0017 49.9087 15.2695C49.9315 15.2953 49.8939 15.3335 49.871 15.3077Z"
                fill="#E4E4E4"
            />
            <path
                d="M55.9952 17.8651L56.7115 17.6014L57.0672 17.4705C57.1821 17.4239 57.2993 17.3833 57.4183 17.3488C57.5224 17.3189 57.6329 17.3203 57.7361 17.3529C57.8282 17.3872 57.9125 17.4398 57.984 17.5075C58.0571 17.5752 58.125 17.6483 58.1874 17.7261C58.2648 17.8197 58.3411 17.9144 58.4177 18.0087C58.734 18.398 59.0482 18.789 59.3603 19.1817C59.6725 19.5743 59.9825 19.9687 60.2905 20.3647C60.5992 20.7617 60.9058 21.1603 61.2102 21.5606C61.2475 21.6096 61.2848 21.6587 61.322 21.7078C61.3426 21.7349 61.389 21.7082 61.3681 21.6807C61.0614 21.2764 60.7526 20.8737 60.4416 20.4727C60.1298 20.0707 59.8159 19.6704 59.4999 19.2719C59.1838 18.8733 58.8656 18.4765 58.5453 18.0814C58.4655 17.983 58.3856 17.8846 58.3055 17.7864C58.238 17.7036 58.1712 17.6198 58.0971 17.5427C58.034 17.4662 57.9566 17.403 57.8692 17.3567C57.7818 17.3103 57.6862 17.2818 57.5879 17.2727C57.4688 17.2769 57.3514 17.3029 57.2415 17.3493C57.1218 17.3929 57.0023 17.4374 56.8827 17.4814L56.1614 17.7469L55.981 17.8133C55.949 17.8251 55.9629 17.877 55.9952 17.8651L55.9952 17.8651Z"
                fill="#E4E4E4"
            />
            <path
                d="M48.9284 14.8921C48.7443 14.8008 48.5394 14.7604 48.3347 14.7752C48.1301 14.7899 47.933 14.8592 47.7637 14.9759C48.4017 15.0867 49.0328 15.235 49.6537 15.4199C49.3958 15.2678 49.1942 15.0296 48.9284 14.8921Z"
                fill="#313131"
            />
            <path
                d="M47.7595 14.9752L47.6895 15.0326C47.7132 15.0125 47.7378 14.9937 47.7631 14.9759L47.7595 14.9752Z"
                fill="#313131"
            />
            <path
                d="M57.9581 17.8678C57.8954 17.7748 57.808 17.7014 57.7059 17.6559L57.6074 17.6598C58.357 18.9929 59.3694 20.1578 60.5824 21.0829L57.9581 17.8678Z"
                fill="#313131"
            />
            <path
                d="M49.2556 9.40433C49.8262 9.40433 50.2886 8.93884 50.2886 8.36464C50.2886 7.79044 49.8262 7.32495 49.2556 7.32495C48.6851 7.32495 48.2227 7.79044 48.2227 8.36464C48.2227 8.93884 48.6851 9.40433 49.2556 9.40433Z"
                fill="#FD6584"
            />
            <path
                d="M51.5671 7.87232C51.538 7.77618 51.4816 7.69071 51.4048 7.62644C51.328 7.56216 51.2342 7.52189 51.135 7.51058C51.1327 7.51038 51.1304 7.51038 51.128 7.51058C51.1253 7.5097 51.1224 7.50905 51.1195 7.50863C50.9427 7.48605 50.7631 7.50145 50.5926 7.5538C50.4221 7.60615 50.2645 7.69427 50.1303 7.81235C50.0342 7.8997 49.9574 8.00635 49.9047 8.12542C49.8568 8.05946 49.7935 8.00626 49.7205 7.97044C49.6475 7.93461 49.5669 7.91724 49.4858 7.91982C49.3811 7.92678 49.281 7.96608 49.1993 8.03235C49.1176 8.09862 49.0582 8.18864 49.0292 8.29013C49.026 8.30252 49.0269 8.31563 49.0317 8.32749C49.0365 8.33934 49.045 8.34932 49.0559 8.35593C49.087 8.38272 49.124 8.40167 49.1639 8.41122C49.2494 8.42952 49.3356 8.44627 49.4219 8.46146C49.5906 8.49105 49.7602 8.51506 49.9307 8.53349C50.0432 8.54556 50.1561 8.55517 50.2692 8.5623C50.5029 8.5771 50.7377 8.58061 50.9717 8.5732C51.0858 8.5776 51.1995 8.55742 51.3052 8.51402C51.3825 8.47578 51.4485 8.41794 51.4967 8.34619C51.5422 8.27718 51.572 8.19899 51.5842 8.11712C51.5963 8.03525 51.5905 7.95169 51.5671 7.87232Z"
                fill="#E6E6E6"
            />
            <path
                d="M49.4425 9.38704C49.4406 9.34347 49.4333 9.30031 49.4209 9.25854C49.3916 9.1624 49.3351 9.07696 49.2583 9.0127C49.1814 8.94844 49.0876 8.90815 48.9883 8.89679C48.9862 8.8966 48.984 8.8966 48.9818 8.89679C48.9788 8.89596 48.9759 8.89531 48.9729 8.89484C48.7815 8.87124 48.5872 8.89134 48.4045 8.95364C48.2642 8.99624 48.1342 9.06788 48.023 9.16396C47.9117 9.26005 47.8216 9.37847 47.7585 9.51163C47.7104 9.44563 47.6471 9.39241 47.5741 9.35658C47.501 9.32076 47.4203 9.30341 47.3391 9.30604C47.2344 9.313 47.1344 9.3523 47.0527 9.41857C46.971 9.48484 46.9116 9.57485 46.8826 9.67634C46.8796 9.68874 46.8805 9.70178 46.8853 9.7136C46.8901 9.72542 46.8985 9.73541 46.9093 9.74215C46.9404 9.76886 46.9774 9.7878 47.0172 9.79744C47.1031 9.81574 47.189 9.83248 47.2756 9.84767C47.4443 9.87726 47.6138 9.90128 47.784 9.91971C48.1298 9.95707 48.4778 9.97034 48.8255 9.95942C48.9396 9.96379 49.0533 9.94361 49.159 9.90024C49.2361 9.86199 49.302 9.80414 49.3501 9.73241C49.4179 9.6307 49.4504 9.50927 49.4425 9.38704Z"
                fill="#E6E6E6"
            />
            <path
                opacity="0.8"
                d="M47.217 12.2002C47.1946 12.2985 47.2103 12.4017 47.2609 12.4888C47.2905 12.5331 47.3339 12.5662 47.3842 12.583C47.4346 12.5997 47.489 12.5992 47.539 12.5814C47.7354 12.5201 47.9291 12.4482 48.1238 12.3816C48.1504 12.3736 48.1729 12.3555 48.1866 12.3313C48.2003 12.307 48.2041 12.2783 48.1974 12.2512C48.1897 12.2243 48.1719 12.2014 48.1476 12.1876C48.1234 12.1737 48.0947 12.17 48.0678 12.1772L47.5813 12.3436C47.5446 12.3561 47.508 12.3704 47.4706 12.3807C47.4667 12.3818 47.4524 12.385 47.4532 12.3849C47.455 12.3847 47.4661 12.3859 47.4538 12.3851C47.4468 12.3829 47.4468 12.3834 47.4539 12.3864L47.4446 12.3811C47.4531 12.3882 47.4436 12.3802 47.4407 12.3772C47.4381 12.3745 47.4305 12.364 47.4364 12.3727C47.4321 12.3665 47.4284 12.36 47.4253 12.3532C47.4305 12.3647 47.4208 12.3363 47.4201 12.3324C47.4162 12.2882 47.4187 12.2437 47.4276 12.2002C47.4275 12.1722 47.4163 12.1453 47.3966 12.1254C47.3769 12.1056 47.3502 12.0944 47.3223 12.0942C47.2946 12.0949 47.2682 12.1063 47.2486 12.126C47.229 12.1458 47.2177 12.1723 47.217 12.2002L47.217 12.2002Z"
                fill="#3F3D56"
            />
            <path
                opacity="0.8"
                d="M57.5006 14.2196L58.0196 14.8783C58.0857 14.9621 58.2273 14.8714 58.1957 14.7752C58.131 14.5784 58.3543 14.4688 58.5084 14.4364C58.7137 14.4179 58.9163 14.3762 59.1123 14.3119C59.2356 14.2557 59.1286 14.073 59.006 14.1289C58.7789 14.2324 58.5142 14.1849 58.2843 14.2856C58.1774 14.3243 58.0884 14.4015 58.0345 14.5023C57.9807 14.6031 57.9657 14.7203 57.9925 14.8315L58.1686 14.7284L57.6495 14.0697C57.6297 14.05 57.6029 14.0389 57.575 14.0389C57.5472 14.0389 57.5204 14.05 57.5006 14.0697C57.4814 14.0899 57.4707 14.1167 57.4707 14.1446C57.4707 14.1725 57.4814 14.1994 57.5006 14.2196H57.5006Z"
                fill="#3F3D56"
            />
            <path
                opacity="0.2"
                d="M61.8706 34.6793V37.215C61.8706 37.8478 61.6226 38.4551 61.1803 38.905C60.7381 39.3549 60.1374 39.6109 59.5086 39.6176C59.5 39.6183 59.4907 39.6183 59.4828 39.6183H47.7187C47.0853 39.6184 46.4778 39.3653 46.0298 38.9146C45.5819 38.4638 45.3302 37.8525 45.3301 37.215V34.6793H61.8706Z"
                fill="#3F3D56"
            />
            <g opacity="0.8">
                <path
                    d="M61.8697 10.9199V12.527C61.5607 12.7235 61.2385 12.898 60.9054 13.0495C60.4512 12.7152 60.0809 12.2785 59.8243 11.7745C59.8716 12.3111 60.0266 12.8326 60.2798 13.3073C60.2712 13.309 60.2626 13.3125 60.2557 13.3142C59.6317 13.5391 58.9133 13.681 58.3271 13.3696C57.8905 13.1377 57.6035 12.6879 57.4264 12.2226C57.3528 12.0248 57.2897 11.8233 57.2374 11.6188C57.1634 11.342 57.0964 11.0635 57.0053 10.7918C57.0019 10.7797 56.9967 10.7693 56.9933 10.7572C56.9847 10.7296 56.9744 10.7019 56.9658 10.6742C56.8455 10.3093 56.6425 9.9776 56.3728 9.70539C56.3192 9.65496 56.2623 9.60815 56.2026 9.56526L56.2507 9.50644C56.176 9.59583 56.0899 9.67494 55.9946 9.74172C55.9416 9.78344 55.8798 9.81245 55.814 9.82654C55.7482 9.84063 55.68 9.83943 55.6147 9.82303C55.3569 9.74691 55.283 9.3888 55.405 9.1466C55.4067 9.14314 55.4085 9.13795 55.4102 9.13449C55.4141 9.12605 55.4187 9.11796 55.4239 9.11028C55.4937 8.99762 55.5863 8.90097 55.6955 8.82655C55.8039 8.75086 55.9177 8.68321 56.0359 8.62413C56.0942 8.41165 56.2052 8.21759 56.3586 8.06022C56.5119 7.90284 56.7026 7.78732 56.9125 7.72452C57.3341 7.60636 57.7758 7.57924 58.2085 7.64494C58.7034 7.69494 59.1943 7.77816 59.6781 7.89407C59.9944 7.97019 60.3365 8.08091 60.5135 8.35771C60.871 8.91824 60.3107 9.72961 60.6424 10.304C60.809 10.5438 61.0595 10.7113 61.3437 10.7728C61.5173 10.8265 61.6978 10.8645 61.8697 10.9199Z"
                    fill="#7E7E86"
                />
                <path
                    d="M52.4023 10.591L52.8858 10.6518V10.591L52.9806 10.3094V10.3216C52.9806 10.4832 52.997 10.6444 53.0296 10.8026C53.0509 10.9096 53.0791 11.015 53.1142 11.1183C53.2562 11.5343 53.4531 12.2179 53.3963 12.6776C53.3559 13.0152 53.2719 14.6165 53.1988 14.9619C53.3915 14.8482 53.9764 12.9045 53.9764 12.9045L54.0272 12.7591L54.0647 12.9209C54.2161 12.9448 54.37 12.9499 54.5227 12.9361C54.6949 12.9201 54.864 12.8796 55.0248 12.8157H55.0254C55.0991 12.7868 55.1707 12.7531 55.2399 12.7147C55.4581 12.5964 55.6643 12.4571 55.8557 12.2987C56.4128 11.828 56.7192 11.2198 56.3494 10.5399C56.3121 10.2444 56.1889 9.96662 55.9953 9.74136C55.9917 9.7365 55.988 9.73103 55.9838 9.72616L55.5711 9.17698L55.5179 9.10643C55.4879 9.11659 55.4557 9.11807 55.4249 9.11069C55.2907 9.09122 55.0847 9.0079 55.0406 9.07602C54.6019 8.95682 54.0677 9.02067 53.4616 9.44031C52.3401 9.3874 52.496 10.0515 52.4023 10.591Z"
                    fill="#7E7E86"
                />
                <path
                    d="M54.1948 12.344C54.9269 12.344 55.5204 11.7467 55.5204 11.0098C55.5204 10.2729 54.9269 9.67554 54.1948 9.67554C53.4626 9.67554 52.8691 10.2729 52.8691 11.0098C52.8691 11.7467 53.4626 12.344 54.1948 12.344Z"
                    fill="#9E616A"
                />
                <path
                    d="M52.3828 10.6105L52.4293 10.6305L53.8113 10.8349L54.3364 10.4085C54.3364 10.4085 54.8319 11.2253 54.7739 11.7021C54.7569 11.8438 54.7328 12.199 54.7044 12.5968C54.6633 13.183 54.6137 13.8599 54.5684 14.074C54.7007 13.9956 55.0119 13.0827 55.2065 12.4763H55.2071C55.3062 12.1674 55.3751 11.9381 55.3751 11.9381L55.4283 11.7873L55.4669 11.9545C55.6558 11.9847 55.848 11.9864 56.0374 11.9594C56.5945 11.4887 56.9008 10.8805 56.531 10.2005C56.4937 9.90507 56.3706 9.62726 56.1769 9.40201C56.276 9.33633 55.8488 8.75552 55.7902 8.84735C55.7781 8.84431 55.7655 8.84066 55.7528 8.83762C55.6982 8.82408 55.6429 8.81372 55.5872 8.80661C55.141 8.75968 54.6924 8.86971 54.3176 9.11799C54.2627 9.15083 54.2071 9.18671 54.1509 9.22564C53.7375 9.19205 53.3266 9.31563 52.9993 9.57199C52.672 9.82835 52.452 10.1989 52.3828 10.6105Z"
                    fill="#7E7E86"
                />
                <path
                    d="M52.8387 24.6253C52.8945 24.5591 52.9351 24.4815 52.9578 24.3977C52.9805 24.314 52.9847 24.2263 52.9701 24.1408C52.9555 24.0553 52.9224 23.974 52.8732 23.9027C52.824 23.8315 52.7599 23.7719 52.6854 23.7283L53.251 18.9738L52.2437 19.3907L51.8528 23.977C51.7883 24.1079 51.7737 24.258 51.8118 24.399C51.85 24.54 51.9381 24.662 52.0597 24.7419C52.1812 24.8218 52.3276 24.8541 52.4712 24.8326C52.6148 24.8111 52.7455 24.7374 52.8387 24.6253Z"
                    fill="#9E616A"
                />
                <path
                    d="M53.4356 14.6139L52.7557 14.1781C52.7557 14.1781 52.1867 14.1832 52.1678 15.2181C52.1489 16.2531 52.1016 18.2709 52.1016 18.2709L51.4824 22.9246L52.8647 23.1158L53.525 18.0942L54.0395 16.5775L53.4356 14.6139Z"
                    fill="#3DACFF"
                />
                <path
                    d="M56.5663 21.9358L52.9408 21.8749L52.6387 19.3815H56.5663V21.9358Z"
                    fill="#9E616A"
                />
                <path
                    d="M53.2137 37.1908H52.3768L51.9785 33.9415H53.2139L53.2137 37.1908Z"
                    fill="#9E616A"
                />
                <path
                    d="M58.5466 37.1794L57.7096 37.1735L57.334 33.9216L58.4225 34.1728L58.5466 37.1794Z"
                    fill="#9E616A"
                />
                <path
                    d="M55.9291 17.234L56.1187 17.7303L56.0809 18.2265L52.3691 19.5204L52.5534 17.3485L52.9706 16.8522L55.9291 17.234Z"
                    fill="#FFB6B6"
                />
                <path
                    d="M53.4222 36.7749L53.2142 36.7628L52.3187 36.7092L52.0987 36.6953V37.2662L50.8405 38.1399C50.7783 38.1831 50.7315 38.2452 50.7069 38.3171C50.6822 38.389 50.6811 38.4669 50.7035 38.5395C50.726 38.6121 50.7709 38.6756 50.8317 38.7207C50.8925 38.7658 50.966 38.7902 51.0416 38.7904H52.6161L52.8876 38.2264L52.9942 38.7904H53.5889L53.4222 36.7749Z"
                    fill="#7E7E86"
                />
                <path
                    d="M58.8327 36.7662L58.5285 36.7455L57.6519 36.6866L57.5092 36.6763L57.5057 37.2472L56.2407 38.1139C56.1782 38.1567 56.131 38.2184 56.106 38.2901C56.0809 38.3619 56.0793 38.4398 56.1014 38.5124C56.1235 38.5851 56.1681 38.6488 56.2287 38.6941C56.2894 38.7395 56.3628 38.7641 56.4383 38.7644L58.0128 38.7748L58.2895 38.2125L58.391 38.7782L58.984 38.7817L58.8327 36.7662Z"
                    fill="#7E7E86"
                />
                <path
                    d="M58.8062 34.8667L58.1968 29.9637L57.4717 24.7942C58.0027 23.8016 56.6514 20.989 56.6514 20.989L56.6382 20.7275L52.8181 21.4529L52.8189 21.4492C52.0375 23.5941 51.7787 24.6538 51.8215 26.9379L51.9754 35.1489H51.9271C51.847 35.1489 51.7702 35.1809 51.7135 35.2379C51.6568 35.295 51.625 35.3723 51.625 35.453C51.625 35.5336 51.6568 35.611 51.7135 35.668C51.7702 35.725 51.847 35.757 51.9271 35.757H53.4378C53.5179 35.757 53.5947 35.725 53.6514 35.668C53.7081 35.611 53.7399 35.5336 53.7399 35.453C53.7399 35.3723 53.7081 35.295 53.6514 35.2379C53.5947 35.1809 53.5179 35.1489 53.4378 35.1489H53.3988L54.7854 25.372L55.961 29.4771L57.2904 34.8291C56.807 34.7074 56.8821 34.9893 56.8821 35.1489C56.8821 35.2295 56.9139 35.3069 56.9705 35.3639C57.0272 35.4209 57.104 35.453 57.1842 35.453H58.6948C58.7651 35.453 58.8332 35.4284 58.8873 35.3834C58.9415 35.3384 58.9784 35.2757 58.9916 35.2063C59.0048 35.1368 58.9935 35.0649 58.9597 35.0029C58.9258 34.9409 58.8716 34.8928 58.8062 34.8667H58.8062Z"
                    fill="#7E7E86"
                />
                <path
                    d="M56.9914 19.8298L56.9843 19.6627C56.9717 19.6131 56.9439 19.5687 56.9048 19.536C56.8656 19.5033 56.8172 19.4839 56.7664 19.4806C56.7161 18.9012 56.5042 16.4015 56.4916 16.2924L56.4911 16.2878L56.3947 14.2165C56.3912 14.1413 56.3603 14.07 56.3079 14.0163C56.2555 13.9625 56.1853 13.9301 56.1106 13.9252L55.6018 13.8917C55.5948 13.8915 55.5882 13.8885 55.5833 13.8835C55.5785 13.8784 55.5757 13.8717 55.5757 13.8646C55.5757 13.8081 55.5647 13.7521 55.5432 13.6998C55.5217 13.6476 55.4901 13.6001 55.4504 13.5601C55.4107 13.5201 55.3635 13.4884 55.3116 13.4667C55.2597 13.4451 55.204 13.434 55.1478 13.434H53.6414C53.5438 13.434 53.4496 13.4699 53.3764 13.5349C53.3033 13.5999 53.2562 13.6895 53.244 13.7869C53.2434 13.7936 53.2402 13.7999 53.2352 13.8043C53.2302 13.8088 53.2236 13.8112 53.2169 13.811H53.2168C53.0456 13.8075 52.8788 13.8658 52.7467 13.9754C52.6146 14.0851 52.5259 14.2387 52.4968 14.4085C52.2908 15.6146 51.8781 18.3519 52.0821 19.8737C52.1532 20.4035 52 19.9839 51.9732 20.4272C51.9705 20.47 51.9763 20.5129 51.9901 20.5535C52.004 20.5941 52.0256 20.6316 52.0539 20.6637C51.996 20.6849 51.9488 20.7281 51.9225 20.7841C51.8961 20.8401 51.8928 20.9043 51.9132 20.9627L51.8867 20.9561C51.9024 21.0187 51.9421 21.0724 51.9971 21.1055C52.0522 21.1386 52.118 21.1483 52.1802 21.1325L56.809 19.9581C56.9384 19.9252 57.0241 19.9601 56.9914 19.8298Z"
                    fill="#3DACFF"
                />
                <path
                    d="M61.9496 15.7487C61.9261 15.8279 61.8857 15.9009 61.8311 15.9626C61.7765 16.0243 61.7092 16.0732 61.6338 16.1059C61.5584 16.1386 61.4768 16.1542 61.3947 16.1518C61.3127 16.1493 61.2322 16.1287 61.1589 16.0915L59.7082 17.424L59.5156 16.3985L60.9236 15.2937C60.9939 15.1739 61.1053 15.0842 61.2369 15.0416C61.3684 14.999 61.5109 15.0064 61.6374 15.0625C61.7639 15.1186 61.8655 15.2194 61.9231 15.3459C61.9806 15.4724 61.9901 15.6157 61.9496 15.7487V15.7487Z"
                    fill="#9E616A"
                />
                <path
                    d="M56.9002 14.7302C56.9002 14.7302 56.3943 13.5797 55.7555 13.8084C55.1166 14.0371 55.4465 15.1534 55.4465 15.1534L57.8467 18.7937C57.8467 18.7937 61.3087 16.5584 60.8878 16.5427C60.4668 16.5269 60.3409 15.7562 60.3409 15.7562L58.5111 16.6764L58.4492 16.3488L58.35 16.4487L58.0939 16.2459L56.9002 14.7302Z"
                    fill="#3DACFF"
                />
            </g>
            <path
                d="M34.3023 46.469C34.4752 46.294 34.608 46.0829 34.6912 45.8507C34.7744 45.6185 34.806 45.3708 34.7837 45.1249C34.7615 44.8791 34.6859 44.6411 34.5624 44.4279C34.439 44.2146 34.2705 44.0312 34.069 43.8905L36.7401 30.5371L33.7903 31.4903L31.6522 44.4064C31.4399 44.7628 31.3648 45.1853 31.4413 45.5937C31.5177 46.0021 31.7404 46.3681 32.067 46.6224C32.3937 46.8766 32.8016 47.0014 33.2136 46.9731C33.6256 46.9449 34.0129 46.7655 34.3023 46.469Z"
                fill="#9E616A"
            />
            <path
                d="M38.2461 18.2168L36.4159 16.8263C36.4159 16.8263 34.8014 16.7112 34.5154 19.6417C34.2295 22.5721 33.6424 28.2831 33.6424 28.2831L30.8418 41.3383L34.7185 42.1947L37.7181 28.1059L39.5176 23.922L38.2461 18.2168Z"
                fill="#3DACFF"
            />
            <path
                d="M35.2285 39.5346L45.5417 39.3616L46.4012 32.2686H35.2285V39.5346Z"
                fill="#9E616A"
            />
            <path
                d="M44.7621 82.9287H47.143L48.2756 73.6858L44.7617 73.686L44.7621 82.9287Z"
                fill="#9E616A"
            />
            <path
                d="M29.5938 82.8965L31.9746 82.8798L33.043 73.629L29.9465 74.3439L29.5938 82.8965Z"
                fill="#9E616A"
            />
            <path
                d="M37.0413 26.16L36.502 27.5717L36.6097 28.9834L47.1679 32.664L46.644 26.4858L45.4571 25.0741L37.0413 26.16Z"
                fill="#FFB6B6"
            />
            <path
                d="M41.5697 13.456C43.6524 13.456 45.3407 11.7567 45.3407 9.6606C45.3407 7.56448 43.6524 5.86523 41.5697 5.86523C39.4871 5.86523 37.7988 7.56448 37.7988 9.6606C37.7988 11.7567 39.4871 13.456 41.5697 13.456Z"
                fill="#9E616A"
            />
            <path
                d="M44.1718 81.7467L47.9355 81.5206V83.1436L51.5137 85.6309C51.6904 85.7537 51.8235 85.9302 51.8936 86.1345C51.9636 86.3389 51.9669 86.5604 51.903 86.7668C51.8391 86.9732 51.7113 87.1536 51.5383 87.2817C51.3653 87.4098 51.1561 87.4789 50.9413 87.4789H46.4606L45.6882 85.8735L45.3867 87.4789H43.6973L44.1718 81.7467Z"
                fill="#7E7E86"
            />
            <path
                d="M28.7804 81.7201L32.5424 81.4675L32.5537 83.0905L36.1491 85.5524C36.3267 85.674 36.461 85.8496 36.5325 86.0534C36.6039 86.2573 36.6088 86.4788 36.5463 86.6856C36.4838 86.8924 36.3573 87.0737 36.1852 87.203C36.013 87.3324 35.8044 87.4029 35.5896 87.4044L31.1089 87.436L30.3255 85.8361L30.0351 87.4436L28.3457 87.4555L28.7804 81.7201Z"
                fill="#7E7E86"
            />
            <path
                d="M28.8531 76.3181L30.5867 62.3708L32.6494 47.6657C31.1388 44.8422 34.5401 36.0746 34.5401 36.0746L35.0202 36.0978L45.887 38.1611L45.8846 38.1506C48.1073 44.252 48.8436 47.2663 48.7218 53.7637L48.284 77.1206H48.4213C48.6493 77.1206 48.8679 77.2118 49.029 77.374C49.1902 77.5362 49.2808 77.7562 49.2808 77.9856C49.2808 78.215 49.1902 78.4351 49.029 78.5973C48.8679 78.7595 48.6493 78.8506 48.4213 78.8506H44.1242C43.8962 78.8506 43.6776 78.7595 43.5164 78.5973C43.3553 78.4351 43.2647 78.215 43.2647 77.9856C43.2647 77.7562 43.3553 77.5362 43.5164 77.374C43.6776 77.2118 43.8962 77.1206 44.1242 77.1206H44.235L40.2907 49.3095L36.9465 60.9868L33.165 76.2109C34.5401 75.8649 34.3266 76.6666 34.3266 77.1206C34.3266 77.35 34.236 77.57 34.0749 77.7323C33.9137 77.8945 33.6951 77.9856 33.4672 77.9856H29.17C28.9701 77.9858 28.7764 77.9159 28.6223 77.7878C28.4683 77.6597 28.3634 77.4814 28.3258 77.2839C28.2883 77.0863 28.3204 76.8817 28.4166 76.7054C28.5129 76.5291 28.6672 76.3921 28.853 76.3181H28.8531Z"
                fill="#7E7E86"
            />
            <path
                d="M33.6937 36.5287L34.0375 33.0686C34.0732 32.9275 34.1524 32.8013 34.2637 32.7083C34.375 32.6152 34.5127 32.56 34.6572 32.5505C34.8004 30.9023 35.403 23.7917 35.439 23.4816L35.4405 23.4685L35.7145 17.5764C35.7244 17.3624 35.8124 17.1597 35.9615 17.0068C36.1106 16.8539 36.3104 16.7617 36.5228 16.7477L37.9702 16.6525C37.9901 16.6518 38.0089 16.6434 38.0227 16.629C38.0365 16.6146 38.0442 16.5954 38.0442 16.5754C38.0442 16.4146 38.0757 16.2553 38.1369 16.1066C38.198 15.958 38.2877 15.8229 38.4007 15.7092C38.5138 15.5954 38.6479 15.5052 38.7956 15.4436C38.9433 15.382 39.1016 15.3503 39.2614 15.3503H43.5468C43.8243 15.3505 44.0923 15.4525 44.3004 15.6374C44.5085 15.8223 44.6424 16.0772 44.677 16.3544C44.6789 16.3735 44.6879 16.3912 44.7022 16.4039C44.7165 16.4166 44.735 16.4234 44.754 16.4229L44.7546 16.4229C45.2415 16.4128 45.7159 16.5788 46.0916 16.8906C46.4674 17.2024 46.7197 17.6395 46.8027 18.1226C47.3885 21.5533 48.5626 29.3399 47.9821 33.6688C47.78 35.1758 48.2157 33.9823 48.2921 35.2431C48.2997 35.365 48.2833 35.4871 48.2439 35.6026C48.2045 35.7181 48.1429 35.8246 48.0625 35.916C48.4162 36.0195 48.5534 36.4041 48.4627 36.7665L48.538 36.7478C48.4934 36.9257 48.3804 37.0786 48.2239 37.1727C48.0673 37.2668 47.8801 37.2945 47.7032 37.2496L34.5361 33.9088C34.1679 33.8154 33.6009 36.8993 33.6937 36.5287Z"
                fill="#3DACFF"
            />
            <path
                d="M37.5317 6.2858C36.8292 6.49628 36.1738 5.90485 35.6848 5.34451L35.8586 5.55204C34.7084 6.36732 34.0206 7.69547 33.5744 9.03994C33.1281 10.3829 32.8792 11.7897 32.3755 13.1134C31.8718 14.4356 31.0589 15.7148 29.8144 16.3745C28.1472 17.2579 26.1045 16.8562 24.3312 16.2188C24.3077 16.2099 24.2856 16.2025 24.262 16.1936C24.9802 14.8434 25.42 13.3611 25.5551 11.8356C24.8257 13.269 23.7736 14.5111 22.4829 15.4628C21.3243 14.9327 20.2102 14.3091 19.1515 13.5981C18.4667 13.1371 17.745 12.5412 17.6655 11.7155C17.5845 10.8958 18.1927 10.1472 18.91 9.75294C19.6272 9.35864 20.452 9.22523 21.2355 8.98806C22.0175 8.75237 22.8158 8.36549 23.2267 7.65397C24.1736 6.01749 22.5801 3.70952 23.5963 2.11454C24.0971 1.33039 25.0691 1.01614 25.9704 0.798238C27.3462 0.467035 28.7426 0.229819 30.1502 0.0882106C31.3858 -0.0363089 32.6627 -0.0822609 33.838 0.317968C35.0133 0.718196 36.0795 1.6476 36.3314 2.872C37.0177 3.23517 37.7438 3.63689 38.112 4.32616C38.4802 5.01396 38.2754 6.06345 37.5317 6.2858Z"
                fill="#7E7E86"
            />
            <path
                d="M36.4832 6.01001C35.8105 6.85818 35.4443 7.77541 35.4443 8.32472C34.0469 10.8954 36.0452 13.1035 38.5994 14.51C39.6181 15.0739 40.7944 15.2803 41.9427 15.0966L42.0495 14.6375L42.1943 15.051C42.1943 15.051 43.8572 20.5791 44.4057 20.9033C44.1976 19.9195 43.9592 15.3644 43.8433 14.4043C43.6829 13.0975 44.2421 11.152 44.6455 9.96893C44.8956 9.23978 45.024 8.47396 45.0254 7.70257V7.66884L45.4181 8.93867L46.8481 8.3247C46.5821 6.78887 46.8481 5.04534 43.6587 5.19656C41.9336 4.00249 40.4146 3.82061 39.1667 4.15927C39.0057 3.90869 38.0771 4.37802 37.8088 4.24607"
                fill="#7E7E86"
            />
            <path
                d="M63.6533 22.4609C63.583 22.6974 63.4621 22.9156 63.2991 23.1C63.136 23.2844 62.9348 23.4305 62.7095 23.5282C62.4843 23.6258 62.2405 23.6726 61.9953 23.6652C61.7502 23.6577 61.5096 23.5963 61.2906 23.4851L56.9563 27.4663L56.3809 24.4024L60.5877 21.1014C60.7976 20.7435 61.1306 20.4755 61.5237 20.3482C61.9168 20.2209 62.3426 20.2432 62.7205 20.4107C63.0983 20.5783 63.402 20.8796 63.5739 21.2574C63.7459 21.6353 63.7741 22.0635 63.6533 22.4609L63.6533 22.4609Z"
                fill="#9E616A"
            />
            <path
                d="M48.5666 19.4179C48.5666 19.4179 47.0552 15.9803 45.1464 16.6636C43.2375 17.3469 44.2231 20.6823 44.2231 20.6823L51.3947 31.5589C51.3947 31.5589 61.7387 24.8803 60.4809 24.8331C59.223 24.786 58.8468 22.4834 58.8468 22.4834L53.3798 25.2328L53.1948 24.254L52.8983 24.5524L52.1333 23.9463L48.5666 19.4179Z"
                fill="#3DACFF"
            />
        </g>
        <defs>
            <clipPath id="clip0_8293_17184">
                <rect width="116" height="88" fill="white" transform="translate(0.25)" />
            </clipPath>
        </defs>
    </svg>
);

export default InstaFBPersonal;
