import { Grid } from "@mui/material";
import CircleFbIcon from "../../assets/CircleFB";
import CircleIgIcon from "../../assets/CircleIG";
import CircleLinkedin from "../../assets/CircleLinkedin";
import CircleTiktokIcon from "../../assets/CircleTiktok";
import CircleTwitchIcon from "../../assets/CircleTwitch";
import CircleTwitterIcon from "../../assets/CircleTwitter";
import CircleYoutubeIcon from "../../assets/CircleYoutube";
import {
    FACEBOOK_PERSONAL_PLATFORM_ID,
    FACEBOOK_PLATFORM_ID,
    INSTAGRAM_PLATFORM_ID,
    LINKEDIN_PLATFORM_ID,
    TIKTOK_PLATFORM_ID,
    TWITTER_PLATFORM_ID,
    YOUTUBE_PLATFORM_ID,
    TWITCH_PLATFORM_ID,
} from "../../Utils/constants";

const static_size = "32px";

export default function GetPlatformIcon(props) {
    const { disabled, platform_id, size, theme } = props;

    return (
        <Grid item>
            {platform_id && (
                <Grid container>
                    {platform_id === TIKTOK_PLATFORM_ID ? (
                        <CircleTiktokIcon
                            color={disabled ? theme.palette.divider : null}
                            width={size ? size : static_size}
                            height={size ? size : static_size}
                        />
                    ) : platform_id === INSTAGRAM_PLATFORM_ID ? (
                        <CircleIgIcon
                            color={disabled ? theme.palette.divider : null}
                            width={size ? size : static_size}
                            height={size ? size : static_size}
                        />
                    ) : platform_id === TWITTER_PLATFORM_ID ? (
                        <CircleTwitterIcon
                            color={disabled ? theme.palette.divider : null}
                            width={size ? size : static_size}
                            height={size ? size : static_size}
                        />
                    ) : platform_id === YOUTUBE_PLATFORM_ID ? (
                        <CircleYoutubeIcon
                            color={disabled ? theme.palette.divider : null}
                            width={size ? size : static_size}
                            height={size ? size : static_size}
                        />
                    ) : platform_id === FACEBOOK_PLATFORM_ID ||
                      platform_id === FACEBOOK_PERSONAL_PLATFORM_ID ? (
                        <CircleFbIcon
                            color={disabled ? theme.palette.divider : null}
                            width={size ? size : static_size}
                            height={size ? size : static_size}
                        />
                    ) : platform_id === LINKEDIN_PLATFORM_ID ? (
                        <CircleLinkedin
                            color={disabled ? theme.palette.divider : null}
                            width={size ? size : static_size}
                            height={size ? size : static_size}
                        />
                    ) : platform_id === TWITCH_PLATFORM_ID ? (
                        <CircleTwitchIcon
                            color={disabled ? theme.palette.divider : null}
                            width={size ? size : static_size}
                            height={size ? size : static_size}
                        />
                    ) : null}
                </Grid>
            )}
        </Grid>
    );
}
