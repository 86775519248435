const CircleTwitterIcon = (props) => (
    <svg
        width={props?.width}
        height={props?.height}
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <rect width="24" height="24" rx="12" fill={props?.color ? "none" : "#212121"} />
        <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M17.4324 5H18.5676L13.7302 10.9238L19 19H15.1081L11.5727 13.5658L7.13514 19H6L11.0694 12.7921L6 5H9.86486L13.226 10.1511L17.4324 5ZM7.54054 5.93902H9.2973L17.4324 18.1748H15.6486L7.54054 5.93902Z"
            fill={props?.color ? props?.color : "white"}
        />
    </svg>
);

export default CircleTwitterIcon;
