import { gql } from "@apollo/client";
export const GET_USER_SOCIAL_CONNECTS = gql`
    query GetUserSocialConnects($userId: Int!) {
        getUserSocialConnects(user_id: $userId) {
            code
            status
            msg
            data
        }
    }
`;

export const GET_USER_SOCIAL_CONNECTS_MONO = gql`
    query UserSocialConnects($userId: Int, $redisOveride: Boolean, $skipAdminCheck: Boolean) {
        userSocialConnects(
            user_id: $userId
            redis_overide: $redisOveride
            skip_admin_check: $skipAdminCheck
        ) {
            facebook {
                user_id
                is_token_valid
                facebook_user_id
                facebook_display_name
                token_error_message
            }
            facebook_pages {
                user_id
                is_token_valid
                is_linkits_attached
                facebook_user_id
                page_id
                page_name
                page_image_url
                token_error_message
            }
            linkedin {
                user_id
                is_token_valid
                is_linkits_attached
                linkedin_account_id
                profile_pic_url
                linkedin_handle
                first_name
                last_name
                token_error_message
            }
            tiktok {
                user_id
                unique_key
                is_token_valid
                is_linkits_attached
                profile_pic_url
                tiktok_handle
                tiktok_user_id
                display_name
                description
                is_business
                token_error_message
                is_advertiser
            }
            instagram {
                user_id
                is_token_valid
                is_linkits_attached
                profile_pic_url
                is_business
                instagram_account_id
                username
                token_error_message
            }
            youtube {
                user_id
                youtube_channel_id
                youtube_channel_name
                channel_image_url
                expiry
                is_token_valid
                is_linkits_attached
                token_error_message
            }
            twitter {
                user_id
                access_token
                is_token_valid
                is_linkits_attached
                profile_pic_url
                twitter_account_id
                twitter_handle
                access_token_secret
                token_error_message
            }
            twitch {
                user_id
                twitch_account_id
                handle_name
                display_name
                access_token
                refresh_token
                profile_pic_url
                is_token_valid
                is_linkits_attached
                scopes
            }
        }
    }
`;

export const GET_USER_PROFILE = gql`
    query GetUserProfile {
        getUserProfile {
            code
            status
            msg
            data
        }
    }
`;

export const GET_USER_SETTINGS = gql`
    query GetUserSettings($userId: Int!) {
        getUserSettings(user_id: $userId) {
            data
        }
    }
`;

export const GET_MASTER_PROFILE_MEDIAKITS = gql`
    query GetProfileMediakit($mediakitHandle: String!) {
        getProfileMediakit(mediakit_handle: $mediakitHandle) {
            mediakit {
                id
                profile_id
                platform_order {
                    id
                    visible
                    img_url
                    platform_id
                    name
                    is_token_valid
                }
            }
        }
    }
`;

export const GET_USER_PLATFORM_ORDERS = gql`
    query GetUserPlatformOrder($userId: Int!) {
        getUserPlatformOrder(user_id: $userId) {
            id
            visible
            img_url
            platform_id
            name
        }
    }
`;

export const CREATE_DEFAULT_INDIVIDUAL_PROFILE = gql`
    mutation CreateDefaultIndividualReport($userId: Int!) {
        createDefaultIndividualReport(user_id: $userId) {
            id
        }
    }
`;

export const UPSERT_DEFAULT_MEDIAKIT = gql`
    mutation UpsertDefaultMediakit {
        upsertDefaultMediakit {
            code
            status
            msg
            data
        }
    }
`;
