import { createSlice } from "@reduxjs/toolkit";
import onboardingActions from "./onboardingActions";
import { toast } from "react-toastify";
import { TOASTIFY_THEME } from "../../Utils/constants";

export type AppTheme = {
    name: string;
    id: number;
    type: string;
};

export type OnboardingState = {
    onboardingLoading: boolean;
    onboardingError: string;
    role_id: any | [];
    user_id: any | null;
    userProfile: any;
    connectedSocials: {
        accounts: any;
        connectedSocialsLoading: boolean;
        connectedSocialsError: string;
    };
};

const initialState: OnboardingState = {
    onboardingLoading: false,
    onboardingError: "",
    role_id: [],
    user_id: null,
    userProfile: {},
    connectedSocials: {
        accounts: {},
        connectedSocialsLoading: false,
        connectedSocialsError: "",
    },
};

export const onboardingSlice = createSlice({
    name: "onboarding",
    initialState: initialState,
    reducers: {},
    extraReducers: {
        //get connected socials handler
        [onboardingActions.getConnectedSocials.fulfilled.toString()]: (state, action) => {
            state.connectedSocials.accounts = action.payload;
            state.connectedSocials.connectedSocialsLoading = false;
            state.connectedSocials.connectedSocialsError = "";
        },
        [onboardingActions.getConnectedSocials.pending.toString()]: (state) => {
            state.connectedSocials.connectedSocialsLoading = true;
            state.connectedSocials.connectedSocialsError = "";
        },
        [onboardingActions.getConnectedSocials.rejected.toString()]: (state, action) => {
            state.connectedSocials.connectedSocialsLoading = false;
            state.connectedSocials.connectedSocialsError = action.error;
            toast.error(`Error: ${action.error.message}`, TOASTIFY_THEME);
        },

        //role_id handler
        [onboardingActions.setRoleId.fulfilled.toString()]: (state, action) => {
            state.role_id = action.payload;
            state.onboardingLoading = false;
            state.onboardingError = "";
        },
        [onboardingActions.setRoleId.pending.toString()]: (state) => {
            state.onboardingLoading = true;
        },
        [onboardingActions.setRoleId.rejected.toString()]: (state, action) => {
            state.onboardingLoading = false;
            state.onboardingError = action.error;
            toast.error(`Error: ${action.error.message}`, TOASTIFY_THEME);
        },

        //user_id handler
        [onboardingActions.setUserId.fulfilled.toString()]: (state, action) => {
            state.user_id = action.payload;
            state.onboardingLoading = false;
            state.onboardingError = "";
        },
        [onboardingActions.setUserId.pending.toString()]: (state) => {
            state.onboardingLoading = true;
        },
        [onboardingActions.setUserId.rejected.toString()]: (state, action) => {
            state.onboardingLoading = false;
            state.onboardingError = action.error;
            toast.error(`Error: ${action.error.message}`, TOASTIFY_THEME);
        },

        //get user profile handler
        [onboardingActions.getUserProfile.pending.toString()]: (state) => {
            state.onboardingLoading = true;
            state.onboardingError = "";
        },
        [onboardingActions.getUserProfile.fulfilled.toString()]: (state, action) => {
            state.userProfile = action.payload;
            state.onboardingLoading = false;
            state.onboardingError = "";
        },
        [onboardingActions.getUserProfile.rejected.toString()]: (state, action) => {
            state.onboardingLoading = false;
            state.onboardingError = action.error;
            // toast.error(`Error: ${action.error.message}`, TOASTIFY_THEME);
        },
    },
});

export default onboardingSlice.reducer;
