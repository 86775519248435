import { useLazyQuery } from "@apollo/client";
import { Userclient } from "../../../../../apolloClient/apolloClient";
import { GET_USER_PROFILE } from "../../../../../graphQL/Queries";
import { useEffect } from "react";
import LogRocket from "logrocket";

export default function useUserProfile() {
    const [
        getUserProfileQuery,
        { data: getUserProfileQueryData, loading: getUserProfileQueryLoading },
    ] = useLazyQuery(GET_USER_PROFILE, {
        client: Userclient,
    });

    const getUserProfileData = (token) => {
        if (token) {
            getUserProfileQuery();
        }
    };

    useEffect(() => {
        if (
            getUserProfileQueryData &&
            !getUserProfileQueryLoading &&
            getUserProfileQueryData?.getUserProfile?.data
        ) {
            const userProfile = getUserProfileQueryData?.getUserProfile?.data;
            LogRocket.identify(`${userProfile?.id}`, {
                name: `${userProfile?.first_name} ${userProfile?.last_name}`,
                email:
                    userProfile?.user_emails && userProfile?.user_emails?.length > 0
                        ? userProfile?.user_emails?.find(
                              (user) => user?.user_id === userProfile?.id && user?.email
                          )?.email
                        : userProfile?.user_emails?.find((user) => user?.email)?.email ||
                          "No email found",
            });
        }
    }, [getUserProfileQueryData, getUserProfileQueryLoading]);
    return { getUserProfileData };
}
