import { useMutation } from "@apollo/client";
import { Userclient } from "../../../../apolloClient/apolloClient";
import { ACCEPT_CREATOR_INVITE } from "../../../../graphQL/Mutations";
import { useEffect, useState } from "react";

export const useAcceptInvite = () => {
    const [acceptInviteResponse, setAcceptInviteResponse] = useState<any>();
    const [acceptInvite, { loading, error, data }] = useMutation(ACCEPT_CREATOR_INVITE, {
        client: Userclient,
    });

    useEffect(() => {
        if (data?.acceptCreatorInvite) {
            setAcceptInviteResponse(data?.acceptCreatorInvite);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [loading, error, data]);

    return { acceptInviteResponse, acceptInviteLoading: loading, error, acceptInvite };
};
