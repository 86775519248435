import React, { useEffect } from "react";
import MainPageV2 from "../../sections/connectSocials/pages/MainPageV2";
import NoAccessPage from "../../sections/connectSocials/pages/NoAccessPage/NoAccessPage";
import { useParams } from "react-router-dom";
import { getAccessToken } from "../../Utils/getToken";
import { useDispatch } from "react-redux";
import jwt_decode from "jwt-decode";
import onboardingActions from "../../store/onboardingSlice/onboardingActions";
import { Dark } from "../../core/theme/SecureTheme";

interface SocialConnectComponentProps {
    invitationKey?: string;
    teamId?: string;
    profileId?: string;
    domainId?: string;
    creatorInviteKey?: string;
    agree?: boolean;
}

const SocialConnectComponent: React.FC<SocialConnectComponentProps> = ({
    invitationKey,
    teamId,
    profileId,
    creatorInviteKey,
    agree,
}) => {
    let {
        invitationKey: invitationKeyParam,
        teamId: teamIdParam,
        profileId: profileIdParam,
        creatorInviteKey: creatorInviteKeyParam,
        domainId: domainIdParam,

    } = useParams();
    const { domainId } = useParams()
    const token = getAccessToken();
    const dispatch = useDispatch();

    useEffect(() => {
        if (token) {
            let decoded: any = jwt_decode(token);
            if (decoded?.user_id) {
                dispatch(onboardingActions.setUserId(parseInt(decoded?.user_id)));
            }
        } // eslint-disable-next-line
    }, [token]);

    const config = {
        workspace_logo: null,
        workspace_name: null,
        title: null,
        description: null,
        skip_intro: true,
    };
    return (
        <>
            {token ? (
                <MainPageV2
                    config={config}
                    theme={Dark}
                    invitationKey={invitationKey ?? invitationKeyParam}
                    teamId={teamId ?? teamIdParam}
                    profileId={profileId ?? profileIdParam}
                    domainId={domainId ?? domainIdParam ?? 2}
                    creatorInviteKey={creatorInviteKey ?? creatorInviteKeyParam}
                    agree={agree}
                />
            ) : (
                <NoAccessPage />
            )}
        </>
    );
};

export default SocialConnectComponent;
