/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/no-unused-vars */
import {
    Avatar,
    Backdrop,
    Badge,
    Button,
    CircularProgress,
    Grid,
    Tooltip,
    Typography,
    useMediaQuery,
} from "@mui/material";
import {
    FACEBOOK_PLATFORM_ID,
    INSTAGRAM_PLATFORM_ID,
    LINKEDIN_PLATFORM_ID,
    TIKTOK_PLATFORM_ID,
    TWITTER_PLATFORM_ID,
    YOUTUBE_PLATFORM_ID,
    TWITCH_PLATFORM_ID,
    FilteredPlatforms,
    DisabledConsentPlatforms,
    TOASTIFY_THEME,
    REACT_APP_CONNECTS_REST_API_URL,
    REACT_APP_HANDLE_REDIRECT_URL,
} from "../../../../Utils/constants";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { OnboardingState } from "../../../../store/onboardingSlice/onboardingSlice";
import GetPlatformIcon from "../../../../sharedComponent/GetPlatformIcon/GetPlatformIcon";
import RemoveSocialAccountDialog from "../../../../sections/connectSocials/components/RemoveSocialAccountDialog";
import { getAccessToken } from "../../../../Utils/getToken";
import { useLocation, useParams, useSearchParams } from "react-router-dom";
import VNLogo from "../../../../assets/VNLogo";
import MultipleStopIcon from "@mui/icons-material/MultipleStop";
import ViralNationLoader from "../../../../sharedComponent/ViralNationLoader";
import useRemoveSocialConnect from "./hooks/useRemoveSocialConnects";
import { useStyles } from "./styles";
import {
    SocialConnectFeatures,
    SocialPlatforms,
    DisabledConsentPlatformsTootipTitle,
    TooltipTitle,
} from "./constants";
import InstagramSelectionDrawer from "./components/InstagramSelectionDrawer";
import TiktokSelectionDrawer from "./components/TiktokSelectionDrawer";
import useSocialAccountsOrderUpdate from "./hooks/useSocialAccountOrderUpdate";
import useSsoAuth from "./hooks/useSsoAuthKey";
import useUserProfile from "./hooks/useUserProfile";
import useConnectedSocials from "./hooks/useConnectedSocials";
import useProfileConsent from "./hooks/useProfileConsent";
import { toast } from "react-toastify";
import ConnectedSocials from "./components/ConnectedSocials";

interface MyWindow extends Window {
    child?: Window;
    onSuccess?: (data: string) => void;
    onSuccessInstagram?: (data: { url: string; platformName: string }) => void;
}

declare var window: MyWindow;

const SlideTabs = [0, 1, 2];

const MainPageV2 = (props: any) => {
    let { config, theme, invitationKey, teamId, profileId, domainId, creatorInviteKey, agree } =
        props;
    const location = useLocation();
    const currentUrl = window?.location?.href;
    const queryParams = new URLSearchParams(location?.search);
    const encodedURL = queryParams?.get("encodedURL");
    const token = getAccessToken();
    const isMobileScreen = useMediaQuery(theme.breakpoints.down("sm")); // Adjust breakpoint as needed
    const classes = useStyles(props);
    const pathname = window.location.pathname;
    const callBackURL = REACT_APP_HANDLE_REDIRECT_URL as string;
    const [slidePage, setSlidePage] = useState<Number>(0);
    const [showInstagramDrawer, setShowInstagramDrawer] = useState<boolean>(false);
    const [showTiktokDrawer, setShowTiktokDrawer] = useState<boolean>(false);
    const [selectedPlatform, setSelectedPlatform] = useState<String>("");
    const [socialAccounts, setSocialAccounts] = useState<any>([]);
    const [selectedSocialAccounts, setSelectedSocialAccounts] = useState<any>([]);
    const [isTalent, setIsTalent] = useState<boolean>(false);
    const [socialAccountsMediakits, setSocialAccountsMediakits] = useState<
        | Array<{
              id: number;
              platform_id: number;
              img_url: string;
              name: string;
              visible: boolean;
          }>
        | []
    >([]);
    // let { invitationKey, teamId, profileId, domainId, creatorInviteKey } = useParams();

    if (config?.teamId && config?.profileId) {
        teamId = config?.teamId;
        profileId = config?.profileId;
    }
    const { onboardingLoading, user_id } = useSelector(
        (state: { onboarding: OnboardingState }) => state.onboarding
    );

    useEffect(() => {
        if (Boolean(new URLSearchParams(window?.location?.search)?.get("handleID") as string))
            setSocialAccountsMediakits(socialAccounts);
    }, [socialAccounts]);

    const [loading, setLoading] = useState<boolean>(true);
    const [showRemoveSocialDialog, setShowRemoveSocialDialog] = useState<boolean>(false);
    const [SelectedAccount, setSelectedAccount] = useState<Record<string, any> | null>(null);

    const { handleRemoveSocialConnects, removeSocialConnectLoading } = useRemoveSocialConnect();
    const { getUserProfileData } = useUserProfile();
    const { handleRedirect } = useSsoAuth(domainId);

    const { handleOnDragEnd } = useSocialAccountsOrderUpdate(
        socialAccounts,
        socialAccountsMediakits,
        setSocialAccountsMediakits,
        user_id,
        setSocialAccounts
    );
    const { getConnectedSocialsLoading, ChangeSelectedSocialAccounts, getBadgeContent } =
        useConnectedSocials(
            user_id,
            setSocialAccounts,
            setSelectedSocialAccounts,
            setSocialAccountsMediakits,
            socialAccountsMediakits,
            selectedSocialAccounts,
            socialAccounts,
            { invitationKey, teamId, profileId }
        );
    const [searchParams] = useSearchParams();
    const didCreatorConsent = searchParams.get("consent") === "true";
    const {
        handleProfileConsent,
        createDefaultIndividualReportLoading,
        upsertDefaultMediakitLoading,
        acceptInviteLoading,
        updateProfileConsentLoading,
    } = useProfileConsent(user_id, handleRedirect, config, selectedSocialAccounts, setSlidePage, {
        invitationKey,
        teamId,
        profileId,
        domainId,
        creatorInviteKey,
        didCreatorConsent: agree ?? didCreatorConsent,
    });

    useEffect(() => {
        const queryParams = new URLSearchParams(window.location.search);
        const isTalent = domainId === "3" ?? Boolean(queryParams.get("domainID") === "3");
        setIsTalent(isTalent);
    }, []);
    useEffect(() => {
        if (token) {
            getUserProfileData(token);
        }
    }, [token]);

    useEffect(() => {
        if (config?.skip_intro) {
            setSlidePage(1);
            setTimeout(() => {
                setLoading(false);
            }, 500);
        } else {
            setLoading(false);
        }
    }, [config]);

    useEffect(() => {
        if (pathname.includes("mediakits"))
            window?.parent?.postMessage(socialAccountsMediakits, "*");
    }, [socialAccountsMediakits, pathname]);

    const getPlatformConnected = async (platform) => {
        try {
            const restApiUrl = REACT_APP_CONNECTS_REST_API_URL;
            // Below step is required as on connecting of the faceboook the window location is having #_=_ and that is causing the other api urls to fail
            const windowLocation = window.location.href?.split("#_=_")?.[0];
            const redirectURL = encodedURL || windowLocation || callBackURL;
            const apiUrl = `${restApiUrl}/${platform}/?uri=${redirectURL}&token=${token}`;

            if (encodedURL) {
                window.open(apiUrl, "_blank");
            } else {
                window.location.href = apiUrl;
            }
        } catch (err) {
            console.log("error", err);
        }
    };
    useEffect(() => {
        if (currentUrl) {
            const urlParts = currentUrl?.split("?");
            let errorMessage = "";
            let successMessage = "";
            if (urlParts?.length > 1) {
                const queryString = urlParts[1];
                const queryParams = queryString?.split("&");
                for (const param of queryParams) {
                    const [key, value] = param?.split("=");
                    if (key === "error") {
                        // checking if there is an "error" query parameter
                        errorMessage = decodeURIComponent(value);
                        try {
                            // Attempt to parse the JSON
                            const errorObj = JSON?.parse(errorMessage?.split("#_")?.[0]);
                            if (errorObj && errorObj?.message) {
                                const platform = errorObj?.platform || "Platform";
                                const message = errorObj?.message;
                                const capitalizedPlatform =
                                    platform?.charAt(0)?.toUpperCase() + platform?.slice(1);
                                const error = capitalizedPlatform + "\n" + message;
                                toast.error(error, TOASTIFY_THEME);
                                window?.history?.pushState(
                                    {},
                                    document?.title,
                                    window?.location?.pathname
                                );
                            }
                        } catch (error) {
                            toast.error(
                                "Something went wrong. Please try again later!",
                                TOASTIFY_THEME
                            );
                        }
                        break;
                    } else if (key === "success") {
                        successMessage = decodeURIComponent(value);
                        try {
                            if (successMessage) {
                                successMessage = successMessage?.includes("#_")
                                    ? successMessage?.split("#_")?.[0]
                                    : successMessage;
                                successMessage = successMessage?.replace(/_\w+/g, function (match) {
                                    return match?.replace("_", " ");
                                });
                                toast.success(successMessage, TOASTIFY_THEME);
                                window?.history?.pushState(
                                    {},
                                    document?.title,
                                    window?.location?.pathname
                                );
                            }
                        } catch (error) {
                            toast.error(
                                "Something went wrong. Please try again later!",
                                TOASTIFY_THEME
                            );
                        }
                        break;
                    }
                }
            } else {
                window?.history?.pushState({}, document?.title, window?.location?.pathname);
            }
        }
    }, [currentUrl]);

    const handleSocialConnection = (id) => {
        if (id === INSTAGRAM_PLATFORM_ID) {
            setSelectedPlatform("instagram");
            setShowInstagramDrawer(true);
        } else if (id === FACEBOOK_PLATFORM_ID) {
            setSelectedPlatform("facebook");
            getPlatformConnected("facebook");
        } else if (id === TWITTER_PLATFORM_ID) {
            getPlatformConnected("twitter");
        } else if (id === YOUTUBE_PLATFORM_ID) {
            getPlatformConnected("youtube");
        } else if (id === TIKTOK_PLATFORM_ID) {
            setSelectedPlatform("tiktok");
            setShowTiktokDrawer(true);
        } else if (id === LINKEDIN_PLATFORM_ID) {
            getPlatformConnected("linkedin");
        } else if (id === TWITCH_PLATFORM_ID) {
            getPlatformConnected("twitch");
        }
    };

    return loading ? (
        <Grid
            container
            width={"100vw"}
            height={"100vh"}
            sx={{ background: config?.background_color ?? theme.palette.background.default }}
            justifyContent={"center"}
            alignItems={"center"}
        >
            <CircularProgress disableShrink />
        </Grid>
    ) : (
        <Grid
            container
            p={2}
            pt={0}
            sx={{
                background: config?.background_color ?? theme.palette.background.default,
                overflow: "auto",
            }}
            width={"100vw"}
            height={"100vh"}
        >
            <Grid item xs={12}>
                <Grid container>
                    {!config?.skip_intro && (
                        <Grid item mt={1}>
                            <Grid container spacing={1}>
                                {SlideTabs.map((item) => (
                                    <Grid item>
                                        {item === slidePage ? (
                                            <div className={classes.selectedSlideTab}></div>
                                        ) : (
                                            <div className={classes.unSelectedSlideTab}></div>
                                        )}
                                    </Grid>
                                ))}
                            </Grid>
                        </Grid>
                    )}
                    {slidePage === 0 ? (
                        <Grid container>
                            <Grid item xs={12} mt={3}>
                                <Grid
                                    container
                                    justifyContent="center"
                                    alignItems="center"
                                    spacing={2}
                                >
                                    <Grid item>
                                        <Grid container>
                                            <VNLogo height="60px" width="60px" />
                                        </Grid>
                                    </Grid>
                                    <Grid item>
                                        <MultipleStopIcon
                                            fontSize="large"
                                            sx={{
                                                color: theme.palette.text.secondary,
                                            }}
                                        />
                                    </Grid>
                                    <Grid item>
                                        {config?.workspace_logo ? (
                                            <Avatar
                                                src={config?.workspace_logo}
                                                alt="logo"
                                                sx={{
                                                    height: "60px",
                                                    width: "60px",
                                                    background: "#292929",
                                                    borderRadius: "20px",
                                                }}
                                            />
                                        ) : (
                                            <div
                                                style={{
                                                    height: "60px",
                                                    width: "60px",
                                                    background: "#292929",
                                                    borderRadius: "20px",
                                                }}
                                            ></div>
                                        )}
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item xs={12} my={3} px={3}>
                                <Typography
                                    fontSize="24px"
                                    fontWeight={600}
                                    lineHeight="32px"
                                    letterSpacing="0.15px"
                                    color={theme.palette.text.primary}
                                    textAlign="center"
                                >
                                    {config?.workspace_name
                                        ? config?.workspace_name + " uses"
                                        : "Use"}{" "}
                                    VN Secure to access and verify your social platform data
                                </Typography>
                            </Grid>
                            {SocialConnectFeatures(theme, config)?.map((item, index) => (
                                <Grid item xs={12} mt={2} key={index}>
                                    <Grid container spacing={2}>
                                        <Grid item>
                                            <Grid container>{item?.icon}</Grid>
                                        </Grid>
                                        <Grid item xs>
                                            <Grid container>
                                                <Grid item xs={12}>
                                                    <Typography
                                                        fontSize="16px"
                                                        fontWeight={700}
                                                        lineHeight="24px"
                                                        letterSpacing="0.15px"
                                                        color={theme.palette.text.primary}
                                                    >
                                                        {item?.title}
                                                    </Typography>
                                                </Grid>
                                                <Grid item xs={12}>
                                                    <Typography
                                                        fontSize="16px"
                                                        color={theme.palette.text.disabled}
                                                    >
                                                        {item?.description}
                                                    </Typography>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            ))}
                            <Grid item xs={12} mt={5}>
                                <Typography
                                    fontSize="14px"
                                    lineHeight="20px"
                                    letterSpacing="0.25px"
                                    color={theme.palette.text.primary}
                                    textAlign="center"
                                >
                                    By clicking on “Continue” you agree to
                                </Typography>
                                <Typography
                                    fontSize="14px"
                                    lineHeight="20px"
                                    letterSpacing="0.25px"
                                    color={config?.button_color ?? theme.palette.primary.main}
                                    textAlign="center"
                                >
                                    VN Secure’s End User Privacy Policy
                                </Typography>
                            </Grid>
                            <Grid item xs={12} mt={2}>
                                <Button
                                    variant="contained"
                                    sx={{
                                        backgroundColor:
                                            config?.button_color ?? theme.palette.primary.main,
                                        "&:hover": {
                                            backgroundColor:
                                                config?.button_color ?? theme.palette.primary.main,
                                            opacity: 0.7,
                                        },
                                    }}
                                    onClick={() => setSlidePage(1)}
                                    fullWidth
                                >
                                    Continue
                                </Button>
                            </Grid>
                        </Grid>
                    ) : slidePage === 1 ? (
                        <Grid item xs={12}>
                            <Grid container>
                                <Grid item xs={12} mt={2}>
                                    <Grid container alignItems="center" spacing={1}>
                                        <Grid item xs>
                                            <Typography
                                                fontSize="24px"
                                                fontWeight={700}
                                                color={theme.palette.text.primary}
                                            >
                                                {config?.title || isTalent
                                                    ? "Display Socials"
                                                    : "Connect Socials"}
                                            </Typography>
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Grid item xs={12} mt={2}>
                                    <Typography
                                        fontSize="16px"
                                        color={theme.palette.text.secondary}
                                    >
                                        {config?.description ||
                                            `Please ${
                                                pathname.includes("mediakits")
                                                    ? "switch"
                                                    : "connect"
                                            } the social media accounts you wish to ${
                                                isTalent ? "display" : "grant access to"
                                            }.`}
                                        {/* }. Drag and drop the icons in order of priority to be scanned.`} */}
                                    </Typography>
                                </Grid>
                                <Grid item xs={12} my={3}>
                                    <Grid container spacing={2} justifyContent="center">
                                        {FilteredPlatforms(SocialPlatforms)
                                            .filter((SocialPlatformItem) =>
                                                pathname.includes("mediakits")
                                                    ? SocialPlatformItem?.platform_id !== 6 &&
                                                      SocialPlatformItem?.platform_id !== 12
                                                    : SocialPlatformItem
                                            )
                                            .map((social, index) => (
                                                <Grid
                                                    item
                                                    key={index}
                                                    display={
                                                        (isTalent &&
                                                            socialAccounts?.filter(
                                                                (item) =>
                                                                    item.platform_id ===
                                                                    social?.platform_id
                                                            )?.length > 0) ||
                                                        !isTalent
                                                            ? "block"
                                                            : "none"
                                                    }
                                                >
                                                    <Tooltip
                                                        title={
                                                            DisabledConsentPlatforms?.includes(
                                                                social?.platform_id
                                                            )
                                                                ? DisabledConsentPlatformsTootipTitle(
                                                                      social?.name,
                                                                      theme
                                                                  )
                                                                : TooltipTitle(theme)
                                                        }
                                                    >
                                                        <Badge
                                                            anchorOrigin={{
                                                                vertical: "top",
                                                                horizontal: "left",
                                                            }}
                                                            overlap="circular"
                                                            color="primary"
                                                            badgeContent={
                                                                getBadgeContent(
                                                                    social?.platform_id
                                                                ) > 1
                                                                    ? getBadgeContent(
                                                                          social?.platform_id
                                                                      )
                                                                    : null
                                                            }
                                                        >
                                                            <Badge
                                                                anchorOrigin={{
                                                                    vertical: "top",
                                                                    horizontal: "right",
                                                                }}
                                                                overlap="circular"
                                                                color="primary"
                                                                badgeContent={
                                                                    DisabledConsentPlatforms?.includes(
                                                                        social?.platform_id
                                                                    )
                                                                        ? "Beta"
                                                                        : null
                                                                }
                                                            >
                                                                <Grid
                                                                    container
                                                                    className={
                                                                        getBadgeContent(
                                                                            social?.platform_id
                                                                        ) > 0
                                                                            ? classes.ConnectedCard
                                                                            : classes.DisConnectedCard
                                                                    }
                                                                    onClick={() => {
                                                                        if (
                                                                            !pathname.includes(
                                                                                "mediakits"
                                                                            )
                                                                        ) {
                                                                            handleSocialConnection(
                                                                                social?.platform_id
                                                                            );
                                                                        }
                                                                    }}
                                                                    sx={{
                                                                        cursor: pathname.includes(
                                                                            "mediakits"
                                                                        )
                                                                            ? "default"
                                                                            : "pointer",
                                                                    }}
                                                                >
                                                                    <GetPlatformIcon
                                                                        theme={theme}
                                                                        disabled={
                                                                            getBadgeContent(
                                                                                social?.platform_id
                                                                            ) === 0
                                                                        }
                                                                        platform_id={
                                                                            social?.platform_id
                                                                        }
                                                                        size="40px"
                                                                    />
                                                                </Grid>
                                                            </Badge>
                                                        </Badge>
                                                    </Tooltip>
                                                </Grid>
                                            ))}
                                    </Grid>
                                </Grid>

                                <Grid item xs={12} mt={1}>
                                    <ConnectedSocials
                                        handleOnDragEnd={handleOnDragEnd}
                                        socialAccounts={socialAccounts}
                                        config={config}
                                        theme={theme}
                                        setSelectedAccount={setSelectedAccount}
                                        ChangeSelectedSocialAccounts={ChangeSelectedSocialAccounts}
                                        socialAccountsMediakits={socialAccountsMediakits}
                                        setShowRemoveSocialDialog={setShowRemoveSocialDialog}
                                    />
                                </Grid>
                                {invitationKey && teamId && profileId ? (
                                    <Grid item xs={12} mt={2}>
                                        <Grid container justifyContent="end">
                                            <Tooltip
                                                title={
                                                    selectedSocialAccounts?.length &&
                                                    selectedSocialAccounts?.findIndex(
                                                        (F) => !F?.is_token_valid
                                                    ) > -1
                                                        ? "Please reconnect the expired (keys in red) social media handles or remove it prior to Consent."
                                                        : ""
                                                }
                                                placement="left"
                                            >
                                                <Grid item>
                                                    <Button
                                                        onClick={() => {
                                                            handleProfileConsent();
                                                        }}
                                                        size="small"
                                                        variant="contained"
                                                        disabled={
                                                            selectedSocialAccounts?.length === 0 ||
                                                            selectedSocialAccounts?.findIndex(
                                                                (F) => !F?.is_token_valid
                                                            ) > -1 ||
                                                            updateProfileConsentLoading ||
                                                            createDefaultIndividualReportLoading ||
                                                            acceptInviteLoading ||
                                                            upsertDefaultMediakitLoading
                                                        }
                                                        sx={{
                                                            backgroundColor:
                                                                config?.button_color ??
                                                                theme.palette.primary.main,
                                                            "&:hover": {
                                                                backgroundColor:
                                                                    config?.button_color ??
                                                                    theme.palette.primary.main,
                                                                opacity: 0.7,
                                                            },
                                                        }}
                                                    >
                                                        {(updateProfileConsentLoading ||
                                                            createDefaultIndividualReportLoading ||
                                                            acceptInviteLoading ||
                                                            upsertDefaultMediakitLoading) && (
                                                            <CircularProgress size="sm" />
                                                        )}
                                                        CONTINUE
                                                    </Button>
                                                </Grid>
                                            </Tooltip>
                                        </Grid>
                                    </Grid>
                                ) : (
                                    !config?.skip_intro &&
                                    socialAccounts.length > 0 && (
                                        <Grid item xs={12} mt={2}>
                                            <Grid container justifyContent="end">
                                                <Button
                                                    variant="contained"
                                                    onClick={() => setSlidePage(2)}
                                                    sx={{
                                                        backgroundColor:
                                                            config?.button_color ??
                                                            theme.palette.primary.main,
                                                        "&:hover": {
                                                            backgroundColor:
                                                                config?.button_color ??
                                                                theme.palette.primary.main,
                                                            opacity: 0.7,
                                                        },
                                                    }}
                                                >
                                                    Continue
                                                </Button>
                                            </Grid>
                                        </Grid>
                                    )
                                )}
                            </Grid>
                        </Grid>
                    ) : slidePage === 2 ? (
                        <Grid item xs={12}>
                            <Grid container>
                                <Grid item xs={12} pt="30vh">
                                    <Typography
                                        fontSize="24px"
                                        fontWeight={700}
                                        color={theme.palette.text.primary}
                                        textAlign="center"
                                    >
                                        You're all done!
                                    </Typography>
                                </Grid>
                                <Grid item xs={12} mt={2}>
                                    <Typography
                                        fontSize="16px"
                                        color={theme.palette.text.ter}
                                        textAlign="center"
                                    >
                                        {config?.finish_text ||
                                            "Thanks for connecting your social media account to VN Secure"}
                                    </Typography>
                                </Grid>
                            </Grid>
                        </Grid>
                    ) : null}

                    <InstagramSelectionDrawer
                        open={showInstagramDrawer}
                        onClose={() => setShowInstagramDrawer(false)}
                        config={config}
                        theme={theme}
                        isMobileScreen={isMobileScreen}
                        selectedPlatform={selectedPlatform}
                        classes={classes}
                        getPlatformConnected={getPlatformConnected}
                    />
                    <TiktokSelectionDrawer
                        open={showTiktokDrawer}
                        onClose={() => setShowTiktokDrawer(false)}
                        config={config}
                        theme={theme}
                        isMobileScreen={isMobileScreen}
                        selectedPlatform={selectedPlatform}
                        classes={classes}
                        getPlatformConnected={getPlatformConnected}
                    />
                    {showRemoveSocialDialog && (
                        <RemoveSocialAccountDialog
                            theme={theme}
                            config={config}
                            open={showRemoveSocialDialog}
                            close={() => {
                                setShowRemoveSocialDialog(false);
                                setSelectedAccount(null);
                            }}
                            social={SelectedAccount}
                            ctaFn={() => {
                                handleRemoveSocialConnects(SelectedAccount);
                                setShowRemoveSocialDialog(false);
                                setSelectedAccount(null);
                            }}
                        />
                    )}

                    <React.Fragment>
                        <Backdrop
                            sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
                            open={
                                updateProfileConsentLoading ||
                                getConnectedSocialsLoading ||
                                onboardingLoading ||
                                loading ||
                                removeSocialConnectLoading ||
                                createDefaultIndividualReportLoading ||
                                upsertDefaultMediakitLoading
                            }
                        >
                            <ViralNationLoader />
                        </Backdrop>
                    </React.Fragment>
                </Grid>
            </Grid>
        </Grid>
    );
};
export default MainPageV2;
