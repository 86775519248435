import { CircularProgress, Grid, Skeleton, useTheme } from "@mui/material";
import VNLogo from "../../assets/VNLogo";

const ViralNationLoader = () => {
    const theme = useTheme();

    return (
        <Grid
            container
            justifyContent="center"
            alignItems="center"
            flexDirection="column"
            sx={{ position: "relative" }}
        >
            <VNLogo style={{ height: "24px", width: "24px", color: theme.palette.text.primary }} />
            <CircularProgress
                size={52}
                sx={{
                    marginTop: "-40px",
                }}
                color="primary"
            />
            <Skeleton
                variant="circular"
                width={44}
                height={44}
                sx={{
                    marginTop: "-48px",
                }}
            />
        </Grid>
    );
};

export default ViralNationLoader;
