const CircleIgIcon = (props) => (
    <svg
        width={props.width}
        height={props.height}
        viewBox="0 0 51 50"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            d="M0.5 24C0.5 10.7452 11.2452 0 24.5 0H26.5C39.7548 0 50.5 10.7452 50.5 24V26C50.5 39.2548 39.7548 50 26.5 50H24.5C11.2452 50 0.5 39.2548 0.5 26V24Z"
            fill={props?.color ? "none" : "white"}
            fillOpacity="0.05"
        />
        <path
            d="M0.5 24C0.5 10.7452 11.2452 0 24.5 0H26.5C39.7548 0 50.5 10.7452 50.5 24V26C50.5 39.2548 39.7548 50 26.5 50H24.5C11.2452 50 0.5 39.2548 0.5 26V24Z"
            fill={props?.color ? "none" : "url(#paint0_radial_22506_224433)"}
        />
        <path
            d="M0.5 24C0.5 10.7452 11.2452 0 24.5 0H26.5C39.7548 0 50.5 10.7452 50.5 24V26C50.5 39.2548 39.7548 50 26.5 50H24.5C11.2452 50 0.5 39.2548 0.5 26V24Z"
            fill={props?.color ? "none" : "url(#paint1_radial_22506_224433)"}
        />
        <path
            d="M25.5074 18.0602C21.6653 18.0602 18.5662 21.1592 18.5662 25.0013C18.5662 28.8434 21.6653 31.9425 25.5074 31.9425C29.3495 31.9425 32.4485 28.8434 32.4485 25.0013C32.4485 21.1592 29.3495 18.0602 25.5074 18.0602ZM25.5074 29.514C23.0245 29.514 20.9947 27.4902 20.9947 25.0013C20.9947 22.5124 23.0185 20.4887 25.5074 20.4887C27.9963 20.4887 30.02 22.5124 30.02 25.0013C30.02 27.4902 27.9902 29.514 25.5074 29.514ZM34.3515 17.7762C34.3515 18.6764 33.6266 19.3952 32.7325 19.3952C31.8324 19.3952 31.1135 18.6703 31.1135 17.7762C31.1135 16.8822 31.8384 16.1572 32.7325 16.1572C33.6266 16.1572 34.3515 16.8822 34.3515 17.7762ZM38.9487 19.4194C38.846 17.2507 38.3507 15.3296 36.7619 13.7469C35.1791 12.1641 33.258 11.6687 31.0893 11.56C28.8541 11.4331 22.1546 11.4331 19.9194 11.56C17.7567 11.6627 15.8356 12.1581 14.2469 13.7408C12.6581 15.3236 12.1687 17.2446 12.06 19.4134C11.9331 21.6486 11.9331 28.3481 12.06 30.5833C12.1627 32.752 12.6581 34.6731 14.2469 36.2558C15.8356 37.8386 17.7507 38.3339 19.9194 38.4427C22.1546 38.5695 28.8541 38.5695 31.0893 38.4427C33.258 38.34 35.1791 37.8446 36.7619 36.2558C38.3446 34.6731 38.84 32.752 38.9487 30.5833C39.0756 28.3481 39.0756 21.6546 38.9487 19.4194ZM36.0611 32.9816C35.5899 34.1656 34.6777 35.0778 33.4876 35.5551C31.7055 36.2619 27.4768 36.0988 25.5074 36.0988C23.538 36.0988 19.3032 36.2558 17.5271 35.5551C16.3431 35.0839 15.4309 34.1717 14.9537 32.9816C14.2469 31.1995 14.41 26.9707 14.41 25.0013C14.41 23.032 14.2529 18.7972 14.9537 17.0211C15.4249 15.8371 16.3371 14.9249 17.5271 14.4476C19.3093 13.7408 23.538 13.9039 25.5074 13.9039C27.4768 13.9039 31.7115 13.7469 33.4876 14.4476C34.6717 14.9188 35.5839 15.831 36.0611 17.0211C36.7679 18.8032 36.6048 23.032 36.6048 25.0013C36.6048 26.9707 36.7679 31.2055 36.0611 32.9816Z"
            fill={props?.color ? props?.color : "white"}
        />
        <defs>
            <radialGradient
                id="paint0_radial_22506_224433"
                cx="0"
                cy="0"
                r="1"
                gradientUnits="userSpaceOnUse"
                gradientTransform="translate(13.7813 53.851) rotate(-90) scale(49.5537 46.0888)"
            >
                <stop stopColor="#FFDD55" />
                <stop offset="0.1" stopColor="#FFDD55" />
                <stop offset="0.5" stopColor="#FF543E" />
                <stop offset="1" stopColor="#C837AB" />
            </radialGradient>
            <radialGradient
                id="paint1_radial_22506_224433"
                cx="0"
                cy="0"
                r="1"
                gradientUnits="userSpaceOnUse"
                gradientTransform="translate(-7.87526 3.60192) rotate(78.6806) scale(22.1507 91.3062)"
            >
                <stop stopColor="#3771C8" />
                <stop offset="0.128" stopColor="#3771C8" />
                <stop offset="1" stopColor="#6600FF" stopOpacity="0" />
            </radialGradient>
        </defs>
    </svg>
);

export default CircleIgIcon;
