const InstaFBBusiness = (props) => (
    <svg
        width={props.width}
        height={props.height}
        viewBox="0 0 108 88"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <g clip-path="url(#clip0_8293_17251)">
            <path
                d="M85.6718 16.0294C85.1159 22.2395 83.5324 28.7877 81.0489 35.1059C77.9778 42.9191 73.7819 49.801 68.9146 55.0075L68.6445 54.7687C73.4882 49.5872 77.6651 42.7357 80.7234 34.9548C83.4873 27.9233 85.1313 20.606 85.4797 13.7971L85.8249 13.8557C85.7883 14.5748 85.7372 15.2994 85.6718 16.0294Z"
                fill="#313131"
            />
            <path
                d="M87.8839 52.0966C81.0475 63.1253 72.7535 71.0053 64.5296 74.2849L64.3633 73.9756C72.5302 70.7187 80.7756 62.879 87.5808 51.9008L87.8839 52.0966Z"
                fill="#313131"
            />
            <path
                d="M85.5057 23.7613C84.9499 29.9714 83.3664 36.5196 80.8829 42.8378C77.8118 50.6511 73.6159 57.533 68.7486 62.7395L68.4785 62.5006C73.3222 57.3192 77.4991 50.4677 80.5573 42.6867C83.3213 35.6552 84.9653 28.3379 85.3137 21.5291L85.6589 21.5876C85.6222 22.3068 85.5712 23.0313 85.5057 23.7613Z"
                fill="#313131"
            />
            <path
                d="M8.75897 39.7045H4.27349C4.17766 39.7047 4.08303 39.6829 3.99669 39.6407C3.91035 39.5986 3.83455 39.5371 3.77497 39.461C3.71612 39.3868 3.6746 39.3001 3.65352 39.2073C3.63245 39.1146 3.63237 39.0181 3.65329 38.9253C4.08049 37.0139 4.05365 35.0269 3.57499 33.1281C3.54471 33.0031 3.55203 32.8717 3.59601 32.751C3.63998 32.6302 3.7186 32.5256 3.82173 32.4505C6.12817 30.7684 7.94456 30.8172 9.2205 32.5958C9.27802 32.6769 9.31623 32.7703 9.33213 32.8689C9.67305 34.9472 9.69192 37.0667 9.38805 39.1508C9.36693 39.3046 9.29156 39.4455 9.1759 39.5473C9.06024 39.6491 8.91212 39.7049 8.75897 39.7045Z"
                fill="#E6E6E6"
            />
            <path
                d="M7.36668 35.1498H5.82367C5.73835 35.1498 5.65651 35.1154 5.59618 35.0542C5.53584 34.993 5.50195 34.9101 5.50195 34.8235C5.50195 34.737 5.53584 34.654 5.59618 34.5929C5.65651 34.5317 5.73835 34.4973 5.82367 34.4973H7.36668C7.45189 34.4975 7.53355 34.5319 7.59373 34.5931C7.65392 34.6543 7.68773 34.7371 7.68773 34.8235C7.68773 34.9099 7.65392 34.9928 7.59373 35.054C7.53355 35.1151 7.45189 35.1496 7.36668 35.1498Z"
                fill="white"
            />
            <path
                d="M7.36668 36.2938H5.82367C5.73835 36.2938 5.65651 36.2594 5.59618 36.1983C5.53584 36.1371 5.50195 36.0541 5.50195 35.9676C5.50195 35.8811 5.53584 35.7981 5.59618 35.7369C5.65651 35.6757 5.73835 35.6414 5.82367 35.6414H7.36668C7.45189 35.6415 7.53355 35.676 7.59373 35.7371C7.65392 35.7983 7.68773 35.8812 7.68773 35.9676C7.68773 36.054 7.65392 36.1369 7.59373 36.198C7.53355 36.2592 7.45189 36.2936 7.36668 36.2938Z"
                fill="white"
            />
            <path
                d="M7.36668 37.438H5.82367C5.73835 37.438 5.65651 37.4036 5.59618 37.3424C5.53584 37.2812 5.50195 37.1983 5.50195 37.1118C5.50195 37.0252 5.53584 36.9423 5.59618 36.8811C5.65651 36.8199 5.73835 36.7855 5.82367 36.7855H7.36668C7.45189 36.7857 7.53355 36.8202 7.59373 36.8813C7.65392 36.9425 7.68773 37.0253 7.68773 37.1118C7.68773 37.1982 7.65392 37.281 7.59373 37.3422C7.53355 37.4033 7.45189 37.4378 7.36668 37.438Z"
                fill="white"
            />
            <path
                d="M90.2765 60.0125H85.7911C85.6952 60.0127 85.6006 59.9909 85.5143 59.9487C85.4279 59.9066 85.3521 59.8451 85.2926 59.769C85.2337 59.6948 85.1922 59.6081 85.1711 59.5153C85.15 59.4225 85.1499 59.3261 85.1709 59.2333C85.5981 57.3219 85.5712 55.3349 85.0926 53.4361C85.0623 53.3111 85.0696 53.1797 85.1136 53.059C85.1576 52.9382 85.2362 52.8335 85.3393 52.7585C87.6458 51.0764 89.4621 51.1252 90.7381 52.9038C90.7956 52.9848 90.8338 53.0783 90.8497 53.1769C91.1906 55.2551 91.2095 57.3746 90.9056 59.4588C90.8845 59.6126 90.8091 59.7535 90.6935 59.8553C90.5778 59.9571 90.4297 60.0129 90.2765 60.0125Z"
                fill="#E6E6E6"
            />
            <path
                d="M88.8855 55.4576H87.3425C87.2573 55.4575 87.1757 55.423 87.1155 55.3618C87.0553 55.3007 87.0215 55.2178 87.0215 55.1314C87.0215 55.045 87.0553 54.9621 87.1155 54.901C87.1757 54.8398 87.2573 54.8054 87.3425 54.8052H88.8855C88.9709 54.8052 89.0527 54.8396 89.113 54.9007C89.1734 54.9619 89.2073 55.0449 89.2073 55.1314C89.2073 55.2179 89.1734 55.3009 89.113 55.3621C89.0527 55.4233 88.9709 55.4576 88.8855 55.4576Z"
                fill="white"
            />
            <path
                d="M88.8855 56.6018H87.3425C87.2573 56.6016 87.1757 56.5672 87.1155 56.506C87.0553 56.4448 87.0215 56.362 87.0215 56.2756C87.0215 56.1892 87.0553 56.1063 87.1155 56.0451C87.1757 55.984 87.2573 55.9495 87.3425 55.9493H88.8855C88.9709 55.9493 89.0527 55.9837 89.113 56.0449C89.1734 56.1061 89.2073 56.1891 89.2073 56.2756C89.2073 56.3621 89.1734 56.4451 89.113 56.5063C89.0527 56.5674 88.9709 56.6018 88.8855 56.6018Z"
                fill="white"
            />
            <path
                d="M88.8855 57.7458H87.3425C87.2573 57.7457 87.1757 57.7112 87.1155 57.65C87.0553 57.5889 87.0215 57.506 87.0215 57.4196C87.0215 57.3332 87.0553 57.2503 87.1155 57.1892C87.1757 57.128 87.2573 57.0936 87.3425 57.0934H88.8855C88.9709 57.0934 89.0527 57.1278 89.113 57.1889C89.1734 57.2501 89.2073 57.3331 89.2073 57.4196C89.2073 57.5061 89.1734 57.5891 89.113 57.6503C89.0527 57.7115 88.9709 57.7458 88.8855 57.7458Z"
                fill="white"
            />
            <path
                d="M107.25 87.8299C107.25 87.8522 107.246 87.8744 107.237 87.8951C107.229 87.9157 107.217 87.9345 107.201 87.9503C107.185 87.9662 107.167 87.9787 107.146 87.9872C107.126 87.9957 107.104 88.0001 107.082 88H0.417834C0.373321 88 0.330634 87.9821 0.299159 87.9502C0.267684 87.9183 0.25 87.875 0.25 87.8299C0.25 87.7847 0.267684 87.7414 0.299159 87.7095C0.330634 87.6776 0.373321 87.6597 0.417834 87.6597H107.082C107.104 87.6596 107.126 87.664 107.146 87.6725C107.167 87.681 107.185 87.6935 107.201 87.7094C107.217 87.7252 107.229 87.744 107.237 87.7646C107.246 87.7853 107.25 87.8075 107.25 87.8299Z"
                fill="#CCCCCC"
            />
            <path
                d="M28.3863 77.4098L28.1065 73.4642C26.274 72.5353 24.2455 72.0741 22.1973 72.1205C25.0789 74.3791 24.8481 78.8933 26.8437 81.9827C27.4734 82.9414 28.3078 83.7441 29.2851 84.3314C30.2623 84.9186 31.3574 85.2754 32.4891 85.3752L34.8873 86.7742C35.1616 84.9356 35.0396 83.0586 34.5296 81.2724C34.0197 79.4862 33.1339 77.8334 31.9334 76.4277C31.2522 75.646 30.4766 74.9543 29.6249 74.369C29.1007 75.8951 28.3863 77.4098 28.3863 77.4098Z"
                fill="#313131"
            />
            <path
                d="M79.1125 77.9819L79.3923 74.0364C81.2248 73.1075 83.2533 72.6462 85.3015 72.6926C82.4199 74.9512 82.6507 79.4654 80.6551 82.5548C80.0254 83.5135 79.191 84.3162 78.2137 84.9035C77.2365 85.4908 76.1414 85.8475 75.0097 85.9473L72.6115 87.3463C72.3372 85.5078 72.4592 83.6307 72.9691 81.8445C73.4791 80.0584 74.3649 78.4055 75.5654 76.9999C76.2466 76.2181 77.0222 75.5264 77.8739 74.9412C78.3981 76.4673 79.1125 77.9819 79.1125 77.9819Z"
                fill="#313131"
            />
            <path
                d="M52.4805 33.457C53.9604 33.457 55.1602 32.2404 55.1602 30.7397C55.1602 29.239 53.9604 28.0225 52.4805 28.0225C51.0005 28.0225 49.8008 29.239 49.8008 30.7397C49.8008 32.2404 51.0005 33.457 52.4805 33.457Z"
                fill="#3DACFF"
            />
            <path
                d="M53.8562 29.6073C53.8158 29.5683 53.7623 29.5465 53.7066 29.5465C53.6509 29.5465 53.5973 29.5683 53.557 29.6073C52.9884 30.0671 52.4777 30.5961 52.0363 31.1822C51.928 30.9697 51.8197 30.7571 51.7113 30.5445C51.5863 30.2991 51.221 30.5159 51.346 30.7611C51.5045 31.072 51.6629 31.3829 51.8214 31.6938C51.8405 31.7256 51.8673 31.7518 51.8993 31.77C51.9313 31.7882 51.9674 31.7978 52.0041 31.7978C52.0407 31.7978 52.0768 31.7882 52.1088 31.77C52.1408 31.7518 52.1676 31.7256 52.1867 31.6938C52.657 31.0222 53.2191 30.4218 53.8562 29.9106C53.8955 29.8702 53.9176 29.8157 53.9176 29.7589C53.9176 29.7022 53.8955 29.6477 53.8562 29.6073Z"
                fill="white"
            />
            <path
                d="M40.4901 38.4449L36.4492 32.0099L41.1285 29.4688L42.3566 34.3784L51.01 39.1199C51.3516 38.9953 51.7216 38.9741 52.0749 39.059C52.4282 39.1439 52.7496 39.3312 52.9997 39.598C53.2498 39.8649 53.418 40.1998 53.4837 40.5619C53.5494 40.924 53.5097 41.2976 53.3696 41.6373C53.2295 41.977 52.995 42.2679 52.6946 42.4748C52.3942 42.6816 52.0409 42.7953 51.6779 42.8021C51.3149 42.8088 50.9578 42.7083 50.6501 42.5128C50.3424 42.3173 50.0975 42.0353 49.9451 41.7011L40.4901 38.4449Z"
                fill="#A0616A"
            />
            <path
                d="M33.4967 24.2917C32.2271 26.182 36.7391 33.0531 36.7391 33.0531C36.959 32.5727 39.0602 37.9679 39.4121 37.6492C40.4124 36.7434 41.9781 34.2203 42.8485 34.1078C43.3462 34.0435 41.6941 30.4641 41.6941 30.4641C41.6941 30.4641 40.9391 28.3504 39.9145 25.6504C39.6388 24.8816 39.1758 24.1961 38.5685 23.6576C37.9612 23.1191 37.2295 22.7451 36.4413 22.5705C36.4413 22.5705 34.7664 22.4014 33.4967 24.2917Z"
                fill="#3F3D56"
            />
            <path
                d="M33.559 85.8783L35.4781 85.8781L36.391 78.3721L33.5586 78.3722L33.559 85.8783Z"
                fill="#A0616A"
            />
            <path
                d="M33.0703 85.243L33.6105 85.243L35.7194 84.3733L36.8497 85.2428H36.8499C37.4887 85.2429 38.1012 85.5002 38.5529 85.9582C39.0046 86.4162 39.2584 87.0374 39.2584 87.6851V87.7645L33.0704 87.7647L33.0703 85.243Z"
                fill="#2F2E41"
            />
            <path
                d="M42.4749 85.8783L44.394 85.8781L45.307 78.3721L42.4746 78.3722L42.4749 85.8783Z"
                fill="#A0616A"
            />
            <path
                d="M41.9863 85.243L42.5265 85.243L44.6354 84.3733L45.7658 85.2428H45.7659C46.4047 85.2429 47.0173 85.5002 47.469 85.9582C47.9206 86.4162 48.1744 87.0374 48.1745 87.6851V87.7645L41.9864 87.7647L41.9863 85.243Z"
                fill="#2F2E41"
            />
            <path
                d="M43.3048 44.8422L30.8221 45.1586C28.4514 46.4643 28.4154 49.2904 29.1922 52.6457C29.1922 52.6457 28.9299 54.64 29.4544 55.1718C29.9789 55.7036 30.2411 55.7036 29.9789 56.6343C29.7166 57.565 31.1021 59.085 30.9338 59.2557C30.7655 59.4263 30.3722 60.357 30.3722 60.357L31.6833 67.9353C31.6833 67.9353 32.6012 81.0977 32.8634 81.3636C33.1256 81.6295 33.3878 81.3636 33.1256 82.0284C32.8634 82.6932 32.6012 82.4273 32.8634 82.6932C33.0957 82.9471 33.3145 83.2134 33.519 83.4909H36.689C36.689 83.4909 36.928 82.1613 36.928 82.0284C36.928 81.8954 37.1902 81.0977 37.1902 80.9648C37.1902 80.8318 36.9584 80.5968 36.9584 80.5968C36.8783 80.3265 36.8242 80.049 36.7968 79.7682C36.7968 79.5023 36.0102 69.2649 36.0102 69.2649L37.5835 55.9695L41.3859 68.999C41.3859 68.999 41.3859 80.4329 41.6481 80.6989C41.9103 80.9648 41.9103 80.8318 41.7792 81.3636C41.6481 81.8954 41.1236 81.7625 41.517 82.1614C41.9103 82.5602 42.0414 81.8954 41.9103 82.5602L41.7792 83.225L45.7127 83.282C45.7127 83.282 46.2371 82.1614 45.9749 81.7625C45.7127 81.3636 45.7284 81.4725 46.0483 80.8198C46.3682 80.167 46.4994 80.0341 46.3683 79.9011C46.2371 79.7682 46.2371 79.061 46.2371 79.061L45.5816 66.3399C45.5816 66.3399 45.4504 52.9116 45.4504 52.5128C45.4406 52.3075 45.4778 52.1028 45.5594 51.9146V51.3732L45.0571 49.4548L43.3048 44.8422Z"
                fill="#2F2E41"
            />
            <path
                d="M35.8062 18.5224C37.9482 18.5224 39.6846 16.7616 39.6846 14.5896C39.6846 12.4175 37.9482 10.6567 35.8062 10.6567C33.6642 10.6567 31.9277 12.4175 31.9277 14.5896C31.9277 16.7616 33.6642 18.5224 35.8062 18.5224Z"
                fill="#A0616A"
            />
            <path
                d="M37.7471 21.1217C38.5826 21.6254 39.0825 22.5509 39.3694 23.4927C39.9006 25.2362 40.2204 27.0389 40.3216 28.8609L40.6246 34.3157L44.378 45.9304C41.1251 48.7214 28.8979 46.5647 28.8979 46.5647C28.8979 46.5647 28.5226 46.4378 28.8979 46.0572C29.2733 45.6766 29.6387 46.0138 29.2633 45.6332C28.888 45.2526 29.1467 45.6766 29.2718 45.296C29.397 44.9154 29.2718 45.1692 29.1467 45.0423C29.0216 44.9154 30.1153 43.7736 30.1153 43.7736L29.1144 37.1067L27.8633 23.659C29.3646 21.756 32.384 20.6342 32.384 20.6342L33.1642 19.2102L37.065 19.5266L37.7471 21.1217Z"
                fill="#3F3D56"
            />
            <path
                d="M39.0036 12.5001C39.305 12.7281 39.5283 13.0462 39.6421 13.4097C39.6858 12.8157 39.6886 12.2194 39.6504 11.6251C39.6047 11.2074 39.3147 11.1135 38.9789 11.0046C38.6757 10.9063 38.3328 10.7952 38.2405 10.3897C37.0214 9.18708 34.8991 9.24772 33.4466 9.66723C32.6474 9.89807 32.1875 10.1947 32.1464 10.3684L32.1378 10.4042L32.1054 10.4206C31.4893 10.733 31.3694 11.7193 31.3773 12.4917C31.3923 13.947 31.8878 15.5998 32.167 15.7341C32.1818 15.7412 32.1855 15.7384 32.1919 15.733C32.6123 15.3924 33.1251 15.7589 33.3504 15.9582C33.4834 15.0537 33.9114 14.2205 34.5661 13.5916C35.2208 12.9627 36.0644 12.5744 36.9623 12.4887C37.2555 12.46 37.5464 12.4105 37.8328 12.3407C37.9781 12.3026 38.1275 12.2827 38.2775 12.2815C38.5359 12.2775 38.7892 12.3538 39.0036 12.5001Z"
                fill="#2F2E41"
            />
            <path
                d="M63.0817 47.9759L70.5425 40.2935L72.5081 34.1492L69.3449 32.8518C67.778 34.4546 66.1649 39.3611 66.1649 39.3611L61.0727 46.2913C61.0321 46.3066 60.9915 46.3228 60.9516 46.3419C60.7172 46.4542 60.5152 46.6259 60.3653 46.8404C60.2154 47.0549 60.1226 47.305 60.0959 47.5665C60.0692 47.828 60.1094 48.092 60.2128 48.3331C60.3161 48.5743 60.479 48.7843 60.6858 48.9429C60.8926 49.1015 61.1362 49.2033 61.3931 49.2386C61.65 49.2738 61.9116 49.2412 62.1525 49.144C62.3934 49.0468 62.6054 48.8882 62.7682 48.6836C62.931 48.479 63.039 48.2352 63.0817 47.9759Z"
                fill="#FFB8B8"
            />
            <path
                d="M68.4824 33.5547L72.4827 34.7281L72.4976 34.7252C73.968 34.4357 75.1311 31.9578 75.8479 29.9301C76.0837 29.2613 76.0603 28.5267 75.7823 27.8747C75.5044 27.2228 74.9928 26.7021 74.3508 26.4181C73.8758 26.2118 73.3534 26.1443 72.8426 26.2233C72.3318 26.3022 71.853 26.5245 71.4602 26.865L69.4658 28.6065L68.4824 33.5547Z"
                fill="#3DACFF"
            />
            <path
                d="M65.0595 85.6501L62.9081 85.6499L61.8848 77.2357L65.0598 77.2359L65.0595 85.6501Z"
                fill="#FFB8B8"
            />
            <path
                d="M65.6087 84.9378L65.0032 84.9378L62.6391 83.9629L61.372 84.9376H61.3718C60.6558 84.9377 59.9691 85.2261 59.4627 85.7396C58.9564 86.253 58.6719 86.9493 58.6719 87.6754V87.7644L65.6086 87.7647L65.6087 84.9378Z"
                fill="#2F2E41"
            />
            <path
                d="M74.6044 85.6501L72.453 85.6499L71.4297 77.2357L74.6048 77.2359L74.6044 85.6501Z"
                fill="#FFB8B8"
            />
            <path
                d="M75.1537 84.9378L74.5481 84.9378L72.184 83.9629L70.9169 84.9376H70.9168C70.2007 84.9377 69.514 85.2261 69.0077 85.7396C68.5013 86.253 68.2168 86.9493 68.2168 87.6754V87.7644L75.1535 87.7647L75.1537 84.9378Z"
                fill="#2F2E41"
            />
            <path
                d="M77.8844 49.4068C77.8844 49.4068 78.1356 52.4635 77.5076 55.3929C76.8795 58.3222 76.8796 66.5723 76.8796 66.5723L75.4979 82.6486L71.353 82.2665L70.2226 55.3929L66.2033 64.4357L66.5802 65.7093L66.0782 66.0277C66.0782 66.0277 66.4545 68.5113 66.2033 68.5113C65.9521 68.5113 65.3241 68.8934 65.3241 68.8934L65.8265 82.0118L61.5561 81.8844L60.0488 63.4168L61.6817 52.8456L61.8073 50.8078L62.3725 50.8314L62.6603 49.9799L63.1889 48.5153L77.8844 49.4068Z"
                fill="#2F2E41"
            />
            <path
                d="M78.1023 50.0489L78.1247 50.017C79.3203 48.3198 77.5222 44.2413 77.251 43.6501L78.3932 43.2742L78.136 41.0571L78.0141 40.0687L78.8872 39.1834L78.8896 39.1613L79.392 34.7036L80.1225 31.7428C80.3978 30.6236 80.3743 29.4502 80.0546 28.3431C79.7348 27.2361 79.1303 26.2353 78.3031 25.4434L76.6146 23.829L74.5625 20.7501H71.2533L70.1186 22.9234C69.0185 23.1812 68.0425 23.8222 67.3606 24.7346C66.6787 25.6471 66.334 26.7733 66.3867 27.9169L66.6439 33.6167L65.6372 40.7135L65.6366 41.9978L64.5036 43.2746L64.751 44.5289L63.8888 44.9036L63.5081 46.7051C63.3505 46.9159 62.3105 48.322 62.3105 48.7064C62.3105 48.7641 62.3526 48.8199 62.4427 48.8817C63.1514 49.3684 67.0687 50.3605 68.8024 49.657C70.6491 48.9082 77.9902 50.0315 78.0642 50.0429L78.1023 50.0489Z"
                fill="#3DACFF"
            />
            <path
                d="M72.4803 19.7562C74.9007 19.7562 76.8629 17.7665 76.8629 15.3121C76.8629 12.8577 74.9007 10.868 72.4803 10.868C70.0598 10.868 68.0977 12.8577 68.0977 15.3121C68.0977 17.7665 70.0598 19.7562 72.4803 19.7562Z"
                fill="#FFB8B8"
            />
            <path
                d="M72.0606 19.8282C72.7393 19.8211 73.4162 19.7553 74.0839 19.6315C74.7449 19.4771 76.2033 18.3335 76.6008 17.658C76.8961 17.156 77.0733 16.3797 77.17 15.8168C77.3006 15.0719 77.2754 14.3074 77.0962 13.573C76.9169 12.8386 76.5875 12.1505 76.1293 11.5534C75.9526 11.3032 75.7126 11.1061 75.4346 10.9829C75.41 10.9739 75.3847 10.966 75.3592 10.9593C74.9562 10.863 74.5908 10.6468 74.3099 10.3383C74.2553 10.2759 74.1964 10.2175 74.1336 10.1636C73.8185 9.90777 73.4481 9.73117 73.0527 9.64826C72.4141 9.49273 71.5004 9.68763 70.3373 10.2281C69.753 10.4996 69.1044 10.4238 68.6045 10.3275C68.5765 10.3228 68.5478 10.3256 68.5211 10.3356C68.4945 10.3456 68.4709 10.3626 68.4527 10.3847C68.2464 10.5916 67.9704 10.7116 67.6804 10.7206C67.5029 10.7332 67.3228 10.9875 67.1058 11.3187C67.0565 11.3939 66.9991 11.4817 66.9574 11.5352L66.949 11.4346L66.8514 11.5496C66.6979 11.7303 66.592 11.9475 66.5435 12.1809C66.4951 12.4143 66.5057 12.6563 66.5743 12.8844C66.643 13.1126 66.7674 13.3194 66.9361 13.4856C67.1048 13.6518 67.3122 13.772 67.5391 13.8351C67.719 13.8786 67.9029 13.9034 68.0878 13.9091C68.1999 13.9164 68.3159 13.9239 68.4273 13.9405C68.785 14.0051 69.1202 14.1626 69.3999 14.3977C69.6797 14.6328 69.8946 14.9375 70.0236 15.282C70.054 15.3616 70.1021 15.433 70.1641 15.4908C70.2261 15.5486 70.3004 15.5911 70.3812 15.6151C70.462 15.6392 70.5472 15.6441 70.6301 15.6294C70.7131 15.6148 70.7916 15.581 70.8597 15.5307C70.9728 15.4365 71.1068 15.3714 71.2502 15.3411C71.3935 15.3107 71.542 15.3159 71.6829 15.3563C71.8054 15.4213 71.9022 15.527 71.957 15.6557C72.0233 15.799 72.1302 15.919 72.264 16.0004C72.7203 16.2241 72.7779 17.2662 72.6383 18.1398C72.5037 18.9819 72.211 19.5962 71.9264 19.6336C71.7074 19.6624 71.6827 19.6789 71.6678 19.7187L71.6544 19.7544L71.68 19.7867C71.8024 19.8281 71.9322 19.8423 72.0606 19.8282Z"
                fill="#2F2E41"
            />
            <path
                d="M68.4243 34.0299L66.3177 31.3081C64.1719 31.9073 60.3644 35.3486 60.3644 35.3486L52.5451 38.7894C52.2842 38.745 52.0162 38.771 51.7683 38.8649C51.5204 38.9588 51.3013 39.1173 51.133 39.3244C50.9647 39.5315 50.8531 39.7799 50.8096 40.0445C50.7661 40.3092 50.792 40.5809 50.885 40.8322C50.9779 41.0834 51.1344 41.3054 51.3388 41.4759C51.5433 41.6463 51.7883 41.7591 52.0494 41.8029C52.3105 41.8467 52.5784 41.8201 52.8261 41.7256C53.0737 41.631 53.2925 41.472 53.4603 41.2645L63.7034 38.3668L68.4243 34.0299Z"
                fill="#FFB8B8"
            />
            <path
                d="M67.9186 34.6522L71.2426 30.8948L71.4272 28.2325C71.4615 27.7097 71.351 27.1876 71.108 26.7251C70.8651 26.2627 70.4995 25.8784 70.0524 25.6156C69.4441 25.2635 68.7266 25.1588 68.0451 25.3226C67.3635 25.4865 66.7687 25.9066 66.381 26.4982C65.2065 28.2931 63.943 30.7199 64.5931 32.0881L64.5997 32.102L67.9186 34.6522Z"
                fill="#3DACFF"
            />
            <path
                d="M37.2834 48.7748L28.6444 44.2882C28.0299 43.9691 27.8263 43.1227 28.1906 42.4014L33.8235 31.2486C34.1878 30.5273 34.9841 30.2001 35.5986 30.5192L44.2377 35.0058C44.8522 35.3249 45.0558 36.1714 44.6914 36.8927L39.0586 48.0454C38.6943 48.7667 37.8979 49.0939 37.2834 48.7748Z"
                fill="#E6E6E6"
            />
            <path
                d="M35.8076 46.7123L29.9381 43.6641C29.3626 43.3652 29.1719 42.5725 29.5131 41.8969L34.1928 32.6314C34.534 31.9559 35.2798 31.6495 35.8553 31.9484L42.9445 35.63C43.52 35.9289 43.7106 36.7216 43.3694 37.3971L40.8272 42.4306C39.3074 45.4397 38.3712 48.0436 35.8076 46.7123Z"
                fill="white"
            />
            <path
                d="M38.7083 41.6524L33.5095 38.9525C33.4459 38.9065 33.4008 38.839 33.3823 38.7621C33.3638 38.6852 33.3732 38.6041 33.4088 38.5337C33.4444 38.4632 33.5038 38.4081 33.5761 38.3784C33.6484 38.3487 33.7289 38.3463 33.8028 38.3718L39.0016 41.0717C39.0652 41.1176 39.1103 41.1852 39.1288 41.2621C39.1473 41.339 39.1379 41.42 39.1023 41.4905C39.0667 41.5609 39.0074 41.616 38.935 41.6458C38.8627 41.6755 38.7822 41.6778 38.7083 41.6524Z"
                fill="#3DACFF"
            />
            <path
                d="M34.1178 40.716L32.9314 40.0998C32.8678 40.0539 32.8227 39.9863 32.8042 39.9094C32.7857 39.8325 32.7951 39.7515 32.8307 39.681C32.8663 39.6106 32.9256 39.5554 32.998 39.5257C33.0703 39.496 33.1508 39.4937 33.2247 39.5191L34.4111 40.1353C34.4747 40.1812 34.5198 40.2488 34.5383 40.3257C34.5568 40.4026 34.5474 40.4836 34.5118 40.5541C34.4762 40.6245 34.4169 40.6796 34.3445 40.7094C34.2722 40.7391 34.1917 40.7414 34.1178 40.716Z"
                fill="#3DACFF"
            />
            <path
                d="M35.639 42.9546L32.3513 41.2472C32.2877 41.2012 32.2426 41.1337 32.2241 41.0568C32.2056 40.9799 32.215 40.8988 32.2506 40.8284C32.2862 40.7579 32.3456 40.7028 32.4179 40.6731C32.4902 40.6433 32.5707 40.641 32.6446 40.6665L35.9323 42.3739C35.9959 42.4198 36.041 42.4874 36.0595 42.5643C36.078 42.6412 36.0686 42.7222 36.033 42.7927C35.9975 42.8631 35.9381 42.9182 35.8657 42.948C35.7934 42.9777 35.7129 42.98 35.639 42.9546V42.9546Z"
                fill="#3DACFF"
            />
            <path
                d="M40.7669 37.5791L35.5681 34.8792C35.5045 34.8333 35.4594 34.7657 35.4409 34.6888C35.4224 34.6119 35.4318 34.5309 35.4674 34.4604C35.503 34.39 35.5624 34.3349 35.6347 34.3051C35.707 34.2754 35.7875 34.2731 35.8614 34.2985L41.0602 36.9984C41.1238 37.0443 41.1689 37.1119 41.1874 37.1888C41.2059 37.2657 41.1965 37.3468 41.1609 37.4172C41.1253 37.4877 41.066 37.5428 40.9936 37.5725C40.9213 37.6022 40.8408 37.6046 40.7669 37.5791Z"
                fill="#3DACFF"
            />
            <path
                d="M36.1744 36.6426L34.988 36.0265C34.9245 35.9805 34.8793 35.913 34.8608 35.8361C34.8423 35.7592 34.8517 35.6781 34.8873 35.6076C34.9229 35.5372 34.9823 35.4821 35.0546 35.4524C35.127 35.4226 35.2074 35.4203 35.2813 35.4457L36.4678 36.0619C36.5313 36.1078 36.5765 36.1754 36.595 36.2523C36.6135 36.3292 36.604 36.4103 36.5685 36.4807C36.5329 36.5512 36.4735 36.6063 36.4012 36.636C36.3288 36.6657 36.2484 36.6681 36.1744 36.6426Z"
                fill="#3DACFF"
            />
            <path
                d="M37.6957 38.8812L34.4079 37.1738C34.3444 37.1279 34.2992 37.0603 34.2807 36.9834C34.2623 36.9065 34.2717 36.8254 34.3072 36.755C34.3428 36.6845 34.4022 36.6294 34.4745 36.5997C34.5469 36.57 34.6273 36.5676 34.7013 36.5931L37.989 38.3005C38.0525 38.3464 38.0977 38.414 38.1162 38.4909C38.1347 38.5678 38.1253 38.6489 38.0897 38.7193C38.0541 38.7898 37.9947 38.8449 37.9224 38.8746C37.85 38.9043 37.7696 38.9067 37.6957 38.8812L37.6957 38.8812Z"
                fill="#3DACFF"
            />
            <path
                d="M30.1236 39.0652L28.1719 31.6975L33.3873 30.6964L33.1068 35.7535L39.9595 42.9084C40.3223 42.8934 40.6815 42.9857 40.9935 43.1741C41.3054 43.3625 41.5565 43.6389 41.7162 43.9696C41.8759 44.3002 41.9373 44.6709 41.8929 45.0363C41.8484 45.4017 41.7001 45.7461 41.466 46.0275C41.2319 46.3089 40.922 46.5151 40.5743 46.6211C40.2266 46.727 39.856 46.7281 39.5077 46.6242C39.1593 46.5202 38.8484 46.3157 38.6127 46.0357C38.377 45.7556 38.2267 45.4121 38.1803 45.0469L30.1236 39.0652Z"
                fill="#A0616A"
            />
            <path
                d="M27.6397 23.4369C25.8694 24.8541 28.1416 32.7811 28.1416 32.7811C28.4934 32.3896 28.9022 38.1755 29.3322 37.9785C30.5543 37.4186 32.7943 35.4877 33.6578 35.6451C34.1516 35.7351 33.6342 31.818 33.6342 31.818C33.6342 31.818 33.5391 29.5721 33.3602 26.6848C33.3246 25.8675 33.0856 25.0727 32.6656 24.3743C32.2455 23.6759 31.6581 23.0967 30.9578 22.6904C30.9578 22.6904 29.41 22.0198 27.6397 23.4369Z"
                fill="#3F3D56"
            />
            <path
                d="M57.0962 55.4183L52.3819 53.161C52.2811 53.113 52.1923 53.0424 52.1222 52.9547C52.0521 52.8669 52.0025 52.7642 51.9771 52.6542C51.9516 52.5466 51.9504 52.4346 51.9736 52.3264C51.9969 52.2183 52.044 52.117 52.1114 52.0299C53.477 50.2602 54.4147 48.1906 54.8493 45.9871C54.8822 45.8184 54.9717 45.6664 55.1027 45.5571C55.2336 45.4479 55.3979 45.388 55.5675 45.3878C57.9719 45.3961 59.7688 46.2863 60.9083 48.0335C60.9684 48.1243 61.0082 48.2274 61.0249 48.3355C61.0417 48.4436 61.035 48.5541 61.0054 48.6592C60.3456 50.9652 59.3416 53.1551 58.0284 55.153C57.9309 55.304 57.7827 55.4141 57.6113 55.4629C57.44 55.5117 57.257 55.4959 57.0962 55.4183Z"
                fill="#E6E6E6"
            />
            <path
                d="M58.483 52.2522L54.8783 50.5262C54.7886 50.4833 54.7195 50.406 54.686 50.3113C54.6525 50.2167 54.6575 50.1124 54.6999 50.0215C54.7422 49.9305 54.8184 49.8604 54.9118 49.8264C55.0052 49.7925 55.108 49.7976 55.1977 49.8405L58.8023 51.5665C58.8467 51.5878 58.8865 51.6177 58.9195 51.6546C58.9525 51.6914 58.978 51.7345 58.9946 51.7814C59.0112 51.8283 59.0185 51.878 59.0161 51.9277C59.0137 51.9775 59.0017 52.0263 58.9807 52.0713C58.9598 52.1163 58.9303 52.1567 58.8939 52.1902C58.8575 52.2236 58.815 52.2495 58.7688 52.2663C58.7226 52.2831 58.6735 52.2905 58.6245 52.2881C58.5754 52.2857 58.5273 52.2735 58.4829 52.2522H58.483Z"
                fill="white"
            />
            <path
                d="M59.0415 51.0498L55.4369 49.3239C55.3472 49.2809 55.2781 49.2036 55.2446 49.1089C55.2111 49.0143 55.2161 48.91 55.2585 48.8191C55.3008 48.7281 55.377 48.658 55.4704 48.6241C55.5637 48.5901 55.6666 48.5952 55.7563 48.6381L59.3609 50.3641C59.4506 50.407 59.5197 50.4844 59.5532 50.579C59.5867 50.6737 59.5817 50.778 59.5393 50.8689C59.497 50.9598 59.4208 51.03 59.3274 51.0639C59.234 51.0979 59.1312 51.0928 59.0415 51.0499V51.0498Z"
                fill="white"
            />
            <path
                d="M54.2261 13.3024L53.8905 13.3397C52.3945 7.37826 49.4947 3.24825 45.5043 1.39606C40.4315 -0.958406 34.1588 0.622659 27.8417 5.84807C21.4925 11.1002 15.8718 19.3962 12.015 29.2083L11.6895 29.0572C15.5631 19.2022 21.2127 10.8661 27.5975 5.58485C34.0143 0.276869 40.415 -1.31579 45.6205 1.10032C49.7253 3.00558 52.701 7.22502 54.2261 13.3024Z"
                fill="#313131"
            />
            <path
                d="M17.2066 21.0664C16.3292 22.9408 15.4945 24.8828 14.7259 26.8382C10.3029 38.0908 8.06158 49.5827 8.24474 60.0721L7.89906 60.0347C7.71571 49.5063 9.96353 37.9748 14.4003 26.6871C15.1712 24.7262 16.0082 22.7788 16.8882 20.899L17.2066 21.0664Z"
                fill="#313131"
            />
            <path
                d="M17.0406 28.7982C16.1631 30.6727 15.3285 32.6146 14.5599 34.57C10.1369 45.8226 7.89556 57.3145 8.07872 67.804L7.73304 67.7666C7.54969 57.2381 9.79751 45.7066 14.2343 34.4189C15.0051 32.458 15.8421 30.5106 16.7221 28.6309L17.0406 28.7982Z"
                fill="#313131"
            />
            <path
                d="M7.75014 31.6102C8.13227 31.0278 7.90609 30.1941 7.24496 29.748C6.58382 29.302 5.7381 29.4126 5.35597 29.995C4.97384 30.5774 5.20001 31.4111 5.86115 31.8572C6.52228 32.3032 7.36801 32.1926 7.75014 31.6102Z"
                fill="#CCCCCC"
            />
            <path
                d="M89.1193 52.643C89.5014 52.0606 89.2752 51.2269 88.6141 50.7809C87.953 50.3349 87.1072 50.4454 86.7251 51.0278C86.343 51.6103 86.5692 52.444 87.2303 52.89C87.8914 53.336 88.7372 53.2254 89.1193 52.643Z"
                fill="#CCCCCC"
            />
        </g>
        <defs>
            <clipPath id="clip0_8293_17251">
                <rect width="107" height="88" fill="white" transform="translate(0.25)" />
            </clipPath>
        </defs>
    </svg>
);

export default InstaFBBusiness;
