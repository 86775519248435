const CircleLinkedin = (props) => (
    <svg
        width={props?.width}
        height={props?.height}
        viewBox="0 0 41 40"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <circle cx="20.5" cy="20" r="20" fill={props?.color ? "none" : "#0A66C2"} />
        <path
            d="M28.1763 10.1309C28.758 10.1309 29.3158 10.3619 29.7271 10.7732C30.1384 11.1845 30.3695 11.7424 30.3695 12.324V27.6763C30.3695 28.258 30.1384 28.8158 29.7271 29.2271C29.3158 29.6384 28.758 29.8695 28.1763 29.8695H12.824C12.2424 29.8695 11.6845 29.6384 11.2732 29.2271C10.8619 28.8158 10.6309 28.258 10.6309 27.6763V12.324C10.6309 11.7424 10.8619 11.1845 11.2732 10.7732C11.6845 10.3619 12.2424 10.1309 12.824 10.1309H28.1763ZM27.628 27.128V21.3161C27.628 20.368 27.2514 19.4587 26.5809 18.7883C25.9105 18.1178 25.0012 17.7412 24.0531 17.7412C23.121 17.7412 22.0354 18.3114 21.509 19.1668V17.9495H18.4495V27.128H21.509V21.7218C21.509 20.8774 22.1889 20.1866 23.0333 20.1866C23.4405 20.1866 23.8309 20.3483 24.1189 20.6362C24.4068 20.9242 24.5685 21.3146 24.5685 21.7218V27.128H27.628ZM14.8856 16.2279C15.3742 16.2279 15.8428 16.0338 16.1883 15.6883C16.5338 15.3428 16.7279 14.8742 16.7279 14.3856C16.7279 13.3658 15.9055 12.5324 14.8856 12.5324C14.3941 12.5324 13.9227 12.7276 13.5752 13.0752C13.2276 13.4227 13.0324 13.8941 13.0324 14.3856C13.0324 15.4055 13.8658 16.2279 14.8856 16.2279ZM16.4099 27.128V17.9495H13.3723V27.128H16.4099Z"
            fill={props?.color ? props?.color : "white"}
            fillOpacity="0.95"
        />
    </svg>
);

export default CircleLinkedin;
