import * as React from "react";
import IconButton from "@mui/material/IconButton";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { useTheme } from "@mui/material";

var options = ["None"];

const ITEM_HEIGHT = 48;

// add this to use = <ThreeDotMenu item={} MenuOptions={['option1', 'option2']} selection={(value: any, selectedItem: any)=>{}} />

export default function ThreeDotMenu(props: any) {
    const theme = useTheme();
    options = props.MenuOptions;
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);
    const handleClick = (event: React.MouseEvent<HTMLElement>) => {
        event.stopPropagation();
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };
    const selection = (val: any) => {
        props.selection(val, props.item);
        setAnchorEl(null);
    };

    return (
        <div>
            {options && options?.length > 0 && (
                <IconButton
                    aria-label="more"
                    id="long-button"
                    aria-controls="long-menu"
                    aria-expanded={open ? "true" : undefined}
                    aria-haspopup="true"
                    onClick={handleClick}
                >
                    <MoreVertIcon
                        sx={{ color: props.color ? props.color : theme.palette.text.secondary }}
                    />
                </IconButton>
            )}
            <Menu
                id="long-menu"
                MenuListProps={{
                    "aria-labelledby": "long-button",
                }}
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                PaperProps={{
                    style: {
                        maxHeight: ITEM_HEIGHT * 4.5,
                        width: "20ch",
                    },
                }}
            >
                {options.map((option) => (
                    <MenuItem key={option} onClick={() => selection(option)}>
                        {option}
                    </MenuItem>
                ))}
            </Menu>
        </div>
    );
}
