import { createAsyncThunk } from "@reduxjs/toolkit";
import onboardingServices from "./onboardingServices";

const setRoleId = createAsyncThunk("roleId", async (roleId: any) => {
    try {
        const response = await onboardingServices.setRoleIdAPI(roleId);
        return response;
    } catch (error: any) {
        throw error;
    }
});

const setUserId = createAsyncThunk("userId", async (id: any) => {
    try {
        const response = await onboardingServices.setUserIdAPI(id);
        return response;
    } catch (error: any) {
        throw error;
    }
});

const getUserProfile = createAsyncThunk("getUserProfile", async (args: any) => {
    try {
        const response = await onboardingServices.getUserProfileAPI(args);
        return response;
    } catch (error: any) {
        throw error;
    }
});

const getConnectedSocials = createAsyncThunk("getConnectedSocials", async (args: any) => {
    try {
        const response = await onboardingServices.getConnectedSocialsAPI(args);
        return response;
    } catch (error: any) {
        throw error;
    }
});

const onboardingActions = {
    getConnectedSocials,
    getUserProfile,
    setRoleId,
    setUserId,
};
export default onboardingActions;
