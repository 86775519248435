const setRoleIdAPI = async (roleId: any) => {
    return roleId;
};

const setUserIdAPI = async (id: any) => {
    return id;
};

const getUserProfileAPI = async ({ mutations }) => {
    try {
        let { data } = await mutations({
            fetchPolicy: "network-only",
        });
        return data?.getUserProfile?.data;
    } catch (error: any) {
        throw error;
    }
};

const getConnectedSocialsAPI = async ({ mutations, userId }) => {
    try {
        let { data } = await mutations({
            variables: { userId },
            fetchPolicy: "network-only",
        });
        return data?.getUserSocialConnects?.data;
    } catch (error: any) {
        throw error;
    }
};

const onboardingServices = {
    getUserProfileAPI,
    setRoleIdAPI,
    setUserIdAPI,
    getConnectedSocialsAPI,
};
export default onboardingServices;
