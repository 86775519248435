import React from "react";
import { Chip, Drawer, Grid, IconButton, Link, Typography } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { TiktokSelectionTypes, capitalizeFirstLetter } from "../../constants";
import GetPlatformIcon from "sharedComponent/GetPlatformIcon/GetPlatformIcon";

const TiktokSelectionDrawer = (props) => {
    const {
        open,
        onClose,
        config,
        theme,
        isMobileScreen,
        selectedPlatform,
        classes,
        getPlatformConnected,
    } = props;

    return (
        <Drawer sx={{ zIndex: 1300 }} anchor="right" open={open} onClose={onClose}>
            <Grid
                container
                sx={{
                    background: config?.background_color ?? theme.palette.background.default,
                }}
                height="100%"
            >
                <Grid item>
                    <Grid container p={2}>
                        <Grid item xs={12} mt={-1}>
                            <Grid container alignItems="center" spacing={1}>
                                <Grid item xs>
                                    <Typography
                                        fontSize={isMobileScreen ? "16px" : "20px"}
                                        fontWeight={700}
                                        color={theme.palette.text.primary}
                                    >
                                        Select {capitalizeFirstLetter(selectedPlatform)} profile
                                        type
                                    </Typography>
                                </Grid>
                                <Grid item>
                                    <IconButton
                                        onClick={() => {
                                            onClose();
                                            console.log("apiUrl", "here");
                                        }}
                                    >
                                        <CloseIcon sx={{ color: theme.palette.text.ter }} />
                                    </IconButton>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item xs={12}>
                            <Typography
                                fontSize={isMobileScreen ? "12px" : "14px"}
                                color={theme.palette.text.disabled}
                            >
                                Your profile type determines your access to{" "}
                                <Link>{capitalizeFirstLetter(selectedPlatform)} </Link>
                                features in VN Platform. Connecting a business account on{" "}
                                <Link>{capitalizeFirstLetter(selectedPlatform)} </Link> provides
                                access to additional features, such as in-depth analytics,
                                advertising options, and the ability to publish content, allowing
                                for a more effective and streamlined presence on the platform and
                                cross platform functionality.
                            </Typography>
                        </Grid>
                        {/* UI for selection of accout types in  */}
                        <Grid item xs={12} my={3}>
                            <Grid container spacing={2}>
                                {/* mapping out accout types for tiktok */}
                                {TiktokSelectionTypes.map((item, i) => (
                                    <Grid item xs={6} key={i}>
                                        <Grid
                                            container
                                            justifyContent="space-evenly"
                                            alignItems="center"
                                            flexDirection="column"
                                            className={
                                                // selectedType === i
                                                //     ? classes.IGFBConnectedCard
                                                //     :
                                                classes.IGFBDisConnectedCard
                                            }
                                            onClick={() => {
                                                getPlatformConnected(
                                                    TiktokSelectionTypes[i]?.social_connect
                                                );
                                                onClose();
                                            }}
                                        >
                                            <Grid item>
                                                <Grid container>
                                                    {item?.icon_platform_ids &&
                                                        item?.icon_platform_ids.map(
                                                            (platform_id, index) => (
                                                                <Grid
                                                                    item
                                                                    key={index}
                                                                    ml={index > 0 ? "-17px" : "0px"}
                                                                >
                                                                    <GetPlatformIcon
                                                                        platform_id={platform_id}
                                                                        size="40px"
                                                                    />
                                                                </Grid>
                                                            )
                                                        )}
                                                </Grid>
                                            </Grid>
                                            <Grid item>
                                                <Typography
                                                    fontSize="16px"
                                                    color={theme.palette.text.secondary}
                                                    textAlign="center"
                                                >
                                                    {capitalizeFirstLetter(selectedPlatform) +
                                                        " " +
                                                        item?.name}
                                                </Typography>
                                            </Grid>

                                            <Grid item height="20px">
                                                {item?.recommended && (
                                                    <Chip
                                                        sx={{
                                                            color: theme.palette.primary.main,
                                                            background: theme.palette.primary.light,
                                                        }}
                                                        label="Recommended"
                                                    />
                                                )}
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                ))}
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </Drawer>
    );
};

export default TiktokSelectionDrawer;
