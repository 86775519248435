import { Grid, Typography, useTheme } from "@mui/material";

const NoAccessPage = () => {
    const theme = useTheme();

    return (
        <Grid container justifyContent="center" flexDirection="column" alignItems="center">
            <Grid item pt={5}>
                <Typography
                    sx={{
                        color: theme.palette.text.secondary,
                        fontSize: "32px",
                    }}
                >
                    Access required!
                </Typography>
            </Grid>
        </Grid>
    );
};

export default NoAccessPage;
