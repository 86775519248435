/* eslint-disable react-hooks/exhaustive-deps */
import { useMutation } from "@apollo/client";
import { ConnectsClient, SecureClient } from "../../../../../apolloClient/apolloClient";
import {
    CREATE_DEFAULT_INDIVIDUAL_PROFILE,
    UPSERT_DEFAULT_MEDIAKIT,
} from "../../../../../graphQL/Queries";
import { useEffect } from "react";
import { toast } from "react-toastify";
import { TOASTIFY_THEME, PLATFORM_DESCRIPTION_MAP } from "../../../../../Utils/constants";
import { useAcceptInvite } from "../../MainPage/useAcceptInvite";
import { UPDATE_PROFILE_CONSENT } from "../../../../../graphQL/Mutations";

export default function useProfileConsent(
    user_id,
    handleRedirect,
    config,
    selectedSocialAccounts,
    setSlidePage,
    params
) {
    const { acceptInvite, acceptInviteLoading, acceptInviteResponse } = useAcceptInvite();

    let { invitationKey, teamId, profileId, creatorInviteKey, domainId, didCreatorConsent } =
        params;

    const [
        updateProfileConsent,
        {
            data: updateProfileConsentData,
            loading: updateProfileConsentLoading,
            error: updateProfileConsentError,
        },
    ] = useMutation(UPDATE_PROFILE_CONSENT, {
        client: SecureClient,
        fetchPolicy: "network-only",
    });

    const [upsertDefaultMediakitQuery, { loading: upsertDefaultMediakitLoading }] = useMutation(
        UPSERT_DEFAULT_MEDIAKIT,
        {
            client: ConnectsClient,
        }
    );

    const [
        createDefaultIndividualReportQuery,
        { data: createDefaultIndividualReportData, loading: createDefaultIndividualReportLoading },
    ] = useMutation(CREATE_DEFAULT_INDIVIDUAL_PROFILE, {
        client: SecureClient,
    });

    useEffect(() => {
        if (createDefaultIndividualReportData?.createDefaultIndividualReport?.id) {
            // toast.success("Report Started!", TOASTIFY_THEME);
        } // eslint-disable-next-line
    }, [createDefaultIndividualReportData]);

    useEffect(() => {
        if (acceptInviteResponse?.acceptCreatorInvite && !acceptInviteLoading) {
            toast.success("Invite accepted!", TOASTIFY_THEME);
        } // eslint-disable-next-line
    }, [acceptInviteResponse, acceptInviteLoading]);

    const acceptInviteFunc = async () => {
        try {
            // Report generation will only run if user agreed to policy (second step 2 of creator invite flow)
            if (didCreatorConsent) {
                await createDefaultIndividualReportQuery({
                    variables: {
                        userId: user_id,
                    },
                });
            }

            await acceptInvite({
                variables: {
                    invitationKey: creatorInviteKey,
                },
            });

            await upsertDefaultMediakitQuery({
                variables: {
                    userId: user_id,
                },
            });
            handleRedirect(user_id);
        } catch (error) {
            // toast.error("Error Creating Report!", TOASTIFY_THEME);
            handleRedirect(user_id);
        }
    };

    useEffect(() => {
        if (
            updateProfileConsentData &&
            !updateProfileConsentLoading &&
            !updateProfileConsentError
        ) {
            if (!config?.skip_intro) {
                setSlidePage(2);
            } else {
                if (domainId === "2") {
                    acceptInviteFunc();
                } else {
                    handleRedirect(user_id);
                }
            }
        } else if (updateProfileConsentError && !updateProfileConsentLoading) {
            // toast.error("Invalid Key!", TOASTIFY_THEME);
            // Redirect user even if they encounter "Invalid Key" error
            handleRedirect(user_id);
        }
        // eslint-disable-next-line
    }, [updateProfileConsentData, updateProfileConsentLoading, updateProfileConsentError]);

    const handleProfileConsent = () => {
        const mappedhandles = selectedSocialAccounts?.map((item) => ({
            handle_id: item?.id,
            handle_name: item?.social_handle,
            platform: PLATFORM_DESCRIPTION_MAP?.[item?.platform_id],
        }));

        updateProfileConsent({
            variables: {
                input: {
                    handles: mappedhandles,
                    profile_id: Number(profileId),
                    team_id: Number(teamId),
                    invitation_key: invitationKey,
                },
            },
        });
    };

    return {
        handleProfileConsent,
        createDefaultIndividualReportLoading,
        upsertDefaultMediakitLoading,
        acceptInviteLoading,
        updateProfileConsentLoading,
    };
}
