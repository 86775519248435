import Cookies from "js-cookie";
import { ENV } from "./constants";
import jwt_decode from "jwt-decode";

// get Authorization token
function getAccessToken(): string {
    const token: string = getToken();
    if (decodable(token)) {
        return token;
    } else {
        return "";
    }
}

function decodable(token: string) {
    try {
        const decoded: any = jwt_decode(token);
        if (decoded) {
            return true;
        } else {
            return false;
        }
    } catch {
        return false;
    }
}

function getToken(): string {
    // NOTE: Don't change the order!
    if (sessionStorage.getItem("special_token")) {
        return sessionStorage.getItem("special_token") as string;
    } else if (ENV === "prod" && Cookies.get("access_token")) {
        localStorage.removeItem("access_token");
        return Cookies.get("access_token") as string;
    } else if (ENV !== "prod" && (Cookies.get("access_token_key") as string)) {
        localStorage.removeItem("access_token");
        return Cookies.get("access_token_key") as string;
    } else {
        return localStorage.getItem("access_token") as string;
    }
}

export { getAccessToken };
