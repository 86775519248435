import { CircularProgress } from "@material-ui/core";
import { Grid, Typography } from "@mui/material";
import { Dark } from "../../../../core/theme/SecureTheme";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import onboardingActions from "../../../../store/onboardingSlice/onboardingActions";
import { OnboardingState } from "../../../../store/onboardingSlice/onboardingSlice";
import { validateEmbedLinkAPI } from "../../../../Utils/validateKey";
// import MainPage from "../MainPage/MainPage";
import jwt_decode from "jwt-decode";
import SecureLogo from "../../../../assets/LogoSecure";
// import { ENV } from "../../../../Utils/constants";
import SocialConnectComponent from "../../../../sharedComponent/SocialConnectComponent";

const ConfigPage = (props: any) => {
    const { isPreview } = props;
    const { invitationKey, embedKey }: any = useParams();
    const dispatch = useDispatch();
    const [Config, setConfig] = useState<any>(null);
    const [InvalidLink, setInvalidLink] = useState<boolean>(false);

    const { user_id } = useSelector((state: { onboarding: OnboardingState }) => state.onboarding);

    async function GET_CONFIG_INFO(embedKey, key) {
        try {
            const { data } = await validateEmbedLinkAPI(embedKey, key);
            if (data) {
                if (data?.token && data?.team_id && data?.profile_id) {
                    sessionStorage.setItem("special_token", data?.token);
                }
                setTimeout(() => {
                    const config = {
                        workspace_logo: data?.workspace_logo,
                        workspace_name: data?.workspace_name,
                        title: data?.additional_details?.title,
                        description: data?.additional_details?.description,
                        finish_text: data?.additional_details?.finish_text,
                        skip_intro: data?.additional_details?.skip_intro,
                        button_color: data?.additional_details?.button_color,
                        background_color: data?.additional_details?.background_color,
                        paper_color: data?.additional_details?.paper_color,
                        isLightTheme: data?.additional_details?.theme === "light",
                        teamId: data?.team_id,
                        profileId: data?.profile_id,
                    };
                    setConfig(config);
                    if (data?.token) {
                        let decoded: any = jwt_decode(data?.token);
                        if (decoded?.user_id) {
                            dispatch(onboardingActions.setUserId(parseInt(decoded?.user_id)));
                        }
                    }
                }, 500);
            } else {
                setInvalidLink(true);
            }
        } catch (error: any) {
            setInvalidLink(true);
            throw error;
        }
    }

    useEffect(() => {
        if (embedKey && invitationKey) {
            GET_CONFIG_INFO(embedKey, invitationKey);
        } else if (embedKey && isPreview) {
            GET_CONFIG_INFO(embedKey, null);
        } // eslint-disable-next-line
    }, [embedKey, invitationKey]);

    return Config && user_id ? (
        // ENV === "dev" ? (
        <SocialConnectComponent />
    ) : (
        // ) : (
        //     <MainPage config={Config} theme={Config?.isLightTheme ? Light : Dark} />
        // )
        <Grid
            container
            width={"100vw"}
            height={"100vh"}
            sx={{ overflow: "auto" }}
            justifyContent={"center"}
            alignItems={"center"}
        >
            {InvalidLink ? (
                <Grid item>
                    <Grid
                        container
                        justifyContent="center"
                        flexDirection="column"
                        alignItems="center"
                    >
                        <Grid item mb={3}>
                            <SecureLogo color={Dark.palette.text.primary} size={36} />
                        </Grid>
                        <Grid item>
                            <Typography
                                sx={{
                                    color: Dark.palette.text.secondary,
                                    fontSize: "26px",
                                }}
                            >
                                Invalid Access Key
                            </Typography>
                        </Grid>
                    </Grid>
                </Grid>
            ) : (
                <CircularProgress disableShrink />
            )}
        </Grid>
    );
};

export default ConfigPage;
