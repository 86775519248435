import { createTheme, svgIconClasses } from "@mui/material";
declare module "@mui/material/styles/createPalette" {
    interface TypeText {
        primary: string;
        secondary: string;
        disabled: string;
        ter: string;
        pri: string;
    }
}

// Dark Theme
export const Dark = createTheme({
    palette: {
        mode: "dark",
        text: {
            primary: "#fff",
            secondary: "rgba(255, 255, 255, 0.7)",
            disabled: "rgba(255, 255, 255, 0.5)",
            ter: "rgba(255, 255, 255, 0.3)",
            pri: "rgba(255, 255, 255, 0.05)",
        },
        primary: {
            main: "#3DACFF",
            light: "rgba(38, 156, 241, 0.1)",
        },
        error: {
            main: "#E43B3B",
            light: "rgba(242, 39, 39, 0.1)",
        },
        success: {
            main: "#32C78A",
            light: "rgba(50, 199, 138, 0.1)",
        },
        warning: {
            main: "#FFA726",
            light: "rgba(255, 167, 38, 0.1)",
        },
        action: {
            active: "#fff",
            hover: "rgba(255, 255, 255, 0.08)",
            selected: "rgba(255, 255, 255, 0.16)",
            disabled: "rgba(255, 255, 255, 0.3)",
            disabledBackground: "rgba(255, 255, 255, 0.12)",
        },
        background: {
            default: "#121212",
            paper: "#121212",
        },
        card: {
            main: "#181818",
            light: "#212121",
            dark: "#161616",
        },
        divider: "rgba(255, 255, 255, 0.12)",
    },
    breakpoints: {
        values: {
            xs: 0,
            sm: 600,
            md: 900,
            lg: 1200,
            xl: 1536,
        },
    },
    components: {
        MuiLink: {
            defaultProps: {
                underline: "none",
            },
            styleOverrides: {
                root: {
                    cursor: "pointer",
                },
            },
        },
        MuiInput: {
            defaultProps: {
                disableUnderline: true,
            },
            styleOverrides: {
                root: {
                    color: `{Dark.palette.text.primary}`,
                    paddingLeft: "10px",
                    padding: "5px 10px 5px 10px",
                    border: "1px solid rgba(255, 255, 255, 0.12) !important",
                    "&.Mui-focused": {
                        border: "1px solid #3075cb !important",
                        borderRadius: "5px",
                    },
                    borderRadius: "4px",
                },
                input: {
                    fontsize: "16px",
                },
            },
        },
        MuiList: {
            styleOverrides: {
                root: {
                    backgroundColor: "#181818 !important",
                    color: `{Dark.palette.text.secondary}`,
                },
            },
        },
        MuiTextField: {
            defaultProps: {
                fullWidth: true,
            },
        },
        MuiCheckbox: {
            styleOverrides: {
                root: {
                    color: `{Dark.palette.text.secondary}`,
                },
            },
        },
        MuiTab: {
            styleOverrides: {
                root: {
                    color: `{Dark.palette.text.disabled}`,
                    textTransform: "capitalize",
                    fontSize: "16px",
                    lineHeight: "20px",
                    letterSpacing: "0.25px",
                    paddingX: "10px",
                    minWidth: "50px",
                    "&.Mui-selected": {
                        fontWeight: "700 !important",
                    },
                },
            },
        },
        MuiSelect: {
            styleOverrides: {
                select: {
                    color: `rgba(255, 255, 255, 0.7) !important`,
                    "&.Mui-disabled": {
                        "-webkit-text-fill-color": `{Dark.palette.text.disabled}`,
                    },
                    display: "flex",
                },
            },
        },
        MuiTooltip: {
            styleOverrides: {
                tooltip: {
                    fontSize: "12px",
                    color: `rgba(255, 255, 255, 0.7)`,
                    backgroundColor: `#2f2f2f`,
                    borderRadius: "8px",
                    padding: "6px 8px",
                    boxShadow: "1px 2px 10px 0px #00000012",
                },
            },
        },
        MuiInputLabel: {
            defaultProps: {
                shrink: true,
            },
            styleOverrides: {
                root: {
                    color: `{Dark.palette.text.primary}`,
                    width: "100%",
                },
            },
        },
        MuiTablePagination: {
            styleOverrides: {
                root: {
                    color: `{Dark.palette.text.secondary}`,
                    p: {
                        marginBottom: 0,
                    },
                },
            },
        },
        MuiTypography: {
            styleOverrides: {
                root: {
                    lineHeight: "20px",
                    letterSpacing: "0.25px",
                },
            },
        },
        MuiAccordion: {
            styleOverrides: {
                root: {
                    boxShadow: "none",
                    backgroundImage: "none",
                },
            },
        },
        MuiPaper: {
            styleOverrides: {
                root: {
                    // boxShadow: "1px 2px 10px 0px #00000012",
                    backgroundImage: "none",
                },
            },
        },
        MuiButton: {
            variants: [
                {
                    props: { color: "primary", variant: "contained" },
                    style: {
                        backgroundColor: `{Dark.palette.primary.main}`,
                        color: "#fff !important",
                        letterSpacing: "1.5px",
                        fontWeight: 700,
                        padding: "8px 16px",
                        textTransform: "capitalize",
                        "&.Mui-hover ": {
                            backgroundColor: `{Dark.palette.primary.light}`,
                        },
                        "&.Mui-clicked ": {
                            opacity: 0.7,
                            color: `{Dark.palette.text.primary}`,
                        },
                        "&.Mui-disabled ": {
                            opacity: 0.6,
                        },
                    },
                },
                {
                    props: { variant: "outlined" },
                    style: {
                        border: `{1px solid {Dark.palette.divider}}`,
                        fontWeight: 700,
                        padding: "8px 16px",
                        textTransform: "capitalize",
                        "&.Mui-hover ": {
                            backgroundColor: `{Dark.palette.primary.main}`,
                        },
                        "&.Mui-clicked ": {
                            opacity: 0.7,
                            color: `{Dark.palette.text.primary}`,
                        },
                    },
                },
                {
                    props: { color: "primary", variant: "text" },
                    style: {
                        color: `{Dark.palette.primary.main}`,
                        fontWeight: 700,
                        textTransform: "none",
                        padding: "8px 16px",
                        "&.Mui-hover ": {
                            textDecoration: `underline {Dark.palette.primary.light}`,
                        },
                        "&.Mui-clicked ": {
                            opacity: 0.4,
                            color: `{Dark.palette.text.primary}`,
                        },
                    },
                },
            ],
        },
        MuiListItemButton: {
            styleOverrides: {
                root: {
                    "&.Side-Menu": {
                        borderRadius: "2rem",
                        display: "flex",
                        padding: "0.5rem 1rem",
                        width: "100%",
                        alignItems: "center",
                        justifyContent: "space-between",
                    },
                    "&.Side-Menu:hover": { backgroundColor: "rgba(255, 255, 255, 0.08)" },
                    "&.Side-Menu.expanded": {
                        color: "white",
                        backgroundColor: "rgba(255, 255, 255, 0.08)",
                    },
                    "&.Side-Menu.Sub-Menu": { borderRadius: "2px" },
                    "&.Side-Menu.Sub-Menu.expanded": {
                        color: "white",
                        backgroundColor: "transparent",
                    },
                    "&.Side-Menu.Sub-Menu.expanded:hover": {
                        backgroundColor: "rgba(255, 255, 255, 0.08)",
                    },
                },
            },
        },
        MuiListItemText: {
            styleOverrides: {
                root: {
                    "&.Side-Menu.active": { color: "white" },
                    "&.SideMenu:hover": { backgroundColor: "rgba(255, 255, 255, 0.08)" },
                    "&.Side-Menu": { color: "#bababa" },
                    "&.Side-Menu.Sub-Menu.active": { color: "white" },
                },
            },
        },
        MuiListItemIcon: {
            styleOverrides: {
                root: {
                    "&.Side-Menu.active>svg": { color: "white", fill: "white" },
                    paddingLeft: "2px",
                    "&.Side-Menu>svg": { color: "#bababa", fill: "#bababa" },
                },
            },
        },
        MuiSvgIcon: {
            variants: [
                {
                    props: { color: "primary" },
                    style: { color: "#bababa", "&>svg": { color: "#bababa", fill: "#bababa" } },
                },
                {
                    props: { color: "secondary" },
                    style: { color: "#3DACFF", "&>svg": { color: "#3DACFF", fill: "#3DACFF" } },
                },
                {
                    props: { color: "error" },
                    style: { color: "#E43B3B", "&>svg": { color: "#E43B3B", fill: "#E43B3B" } },
                },
                {
                    props: { color: "success" },
                    style: { color: "#3FFCAE", "&>svg": { color: "#3FFCAE", fill: "#3FFCAE" } },
                },
                {
                    props: { color: "warning" },
                    style: { color: "#FFA842", "&>svg": { color: "#FFA842", fill: "#FFA842" } },
                },
                {
                    props: { color: "info" },
                    style: { color: "#73c0fa", "&>svg": { color: "#73c0fa", fill: "#73c0fa" } },
                },
            ],
            styleOverrides: {
                root: {
                    "&.Side-Menu.indicator.active": { color: "#3DACFF", width: "0.5rem" },
                    "&.Side-Menu.indicator": { width: "0.5rem" },
                },
            },
        },
        MuiGrid: {
            styleOverrides: {
                root: {
                    "&.Plan-Billing-Tab": { border: "1px solid #5a5a5a" },
                    "&.Workspaces-Box": {
                        border: "1px solid #4d4d4d",
                        backgroundColor: "#212121",
                        cursor: "pointer",
                        padding: "14px",
                        display: "flex",
                        gap: "8px",
                        justifyContent: "flex-start",
                        borderRadius: "4px",
                        flexGrow: 1,
                        alignItems: "center",
                    },
                    "&.Accounts-Box": {
                        border: "1px solid #4d4d4d",
                        backgroundColor: "#212121",
                        width: "100%",
                        height: "100%",
                        borderRadius: "2rem",
                        display: "flex",
                        flexDirection: "column",
                        position: "relative",
                    },
                    "&.Workspace-Item": {
                        color: "rgba(255,255,255,0.7)",
                        "&:hover": { color: "rgba(255,255,255)" },
                    },
                    "&.Divider": {
                        backgroundColor: "rgba(255, 255, 255, 0.3)",
                    },
                },
            },
        },
        MuiAvatar: {
            styleOverrides: {
                root: {
                    backgroundColor: "#bababa",
                    [`& .${svgIconClasses.root}`]: { color: "black" },
                },
            },
        },
        MuiAppBar: {
            defaultProps: { position: "fixed" },
            styleOverrides: {
                root: {
                    backgroundColor: "#181818",
                    height: "70px",
                    boxShadow: "1px 4px 10px 0px #00000012",
                    zIndex: 1300,
                    backgroundImage: "none",
                },
            },
        },
    },
});

// Light Theme
export const Light = createTheme({
    palette: {
        mode: "light",
        text: {
            primary: "rgba(0, 0, 0, 1)",
            secondary: "rgba(0, 0, 0, 0.87)",
            disabled: "rgba(0, 0, 0, 0.7)",
            ter: "rgba(0, 0, 0, 0.5)",
            pri: "rgba(0, 0, 0, 0.3)",
        },
        primary: {
            main: "#1192f3",
            light: "rgba(38, 156, 241, 0.2)",
        },
        error: {
            main: "#ec0e0e",
            light: "rgba(242, 39, 39, 0.2)",
        },
        success: {
            main: "#10a165",
            light: "rgba(50, 199, 138, 0.2)",
        },
        warning: {
            main: "#f3950b",
            light: "rgba(255, 167, 38, 0.2)",
        },
        action: {
            active: "rgba(0, 0, 0, 0.54)",
            hover: "rgba(0, 0, 0, 0.08)",
            selected: "rgba(0, 0, 0, 0.08)",
            disabled: "rgba(0, 0, 0, 0.26)",
            disabledBackground: "rgba(0, 0, 0, 0.12)",
        },
        background: {
            default: "#f2f2f3",
            paper: "#f2f2f3",
        },
        card: {
            main: "#fff",
            light: "#fff",
            dark: "#e1e5e7",
        },
        divider: "rgba(0, 0, 0, 0.12)",
    },
    breakpoints: {
        values: {
            xs: 0,
            sm: 600,
            md: 900,
            lg: 1200,
            xl: 1536,
        },
    },
    components: {
        MuiLink: {
            defaultProps: {
                underline: "none",
            },
            styleOverrides: {
                root: {
                    cursor: "pointer",
                },
            },
        },
        MuiInput: {
            defaultProps: {
                disableUnderline: true,
            },
            styleOverrides: {
                root: {
                    color: `{Light.palette.text.primary}`,
                    paddingLeft: "10px",
                    padding: "5px 10px 5px 10px",
                    border: "1px solid rgba(0, 0, 0, 0.12) !important",
                    "&.Mui-focused": {
                        border: "1px solid #3075cb !important",
                        borderRadius: "5px",
                    },
                    borderRadius: "4px",
                },
                input: {
                    fontsize: "16px",
                },
            },
        },
        MuiList: {
            styleOverrides: {
                root: {
                    backgroundColor: "#fff !important",
                    color: `{Light.palette.text.secondary}`,
                },
            },
        },
        MuiTextField: {
            defaultProps: {
                fullWidth: true,
            },
        },
        MuiCheckbox: {
            styleOverrides: {
                root: {
                    color: `{Light.palette.text.secondary}`,
                },
            },
        },
        MuiTab: {
            styleOverrides: {
                root: {
                    color: `{Light.palette.text.disabled}`,
                    textTransform: "capitalize",
                    fontSize: "16px",
                    lineHeight: "20px",
                    letterSpacing: "0.25px",
                    paddingX: "10px",
                    minWidth: "50px",
                    "&.Mui-selected": {
                        fontWeight: "700 !important",
                    },
                },
            },
        },
        MuiSelect: {
            styleOverrides: {
                select: {
                    color: `rgba(0, 0, 0, 0.6) !important`,
                    "&.Mui-disabled": {
                        "-webkit-text-fill-color": `{Light.palette.text.disabled}`,
                    },
                    display: "flex",
                },
            },
        },
        MuiTooltip: {
            styleOverrides: {
                tooltip: {
                    fontSize: "12px",
                    color: `rgba(0, 0, 0, 0.6)`,
                    backgroundColor: `#e8e8e8`,
                    borderRadius: "8px",
                    padding: "6px 8px",
                    boxShadow: "1px 2px 10px 0px #00000012",
                },
            },
        },
        MuiInputLabel: {
            defaultProps: {
                shrink: true,
            },
            styleOverrides: {
                root: {
                    color: `{Light.palette.text.primary}`,
                    width: "100%",
                },
            },
        },
        MuiTablePagination: {
            styleOverrides: {
                root: {
                    color: `{Light.palette.text.secondary}`,
                    p: {
                        marginBottom: 0,
                    },
                },
            },
        },
        MuiTypography: {
            styleOverrides: {
                root: {
                    lineHeight: "20px",
                    letterSpacing: "0.25px",
                },
            },
        },
        MuiAccordion: {
            styleOverrides: {
                root: {
                    boxShadow: "none",
                    backgroundImage: "none",
                },
            },
        },
        MuiPaper: {
            styleOverrides: {
                root: {
                    // boxShadow: "1px 2px 10px 0px #00000012",
                    backgroundImage: "none",
                },
            },
        },
        MuiButton: {
            variants: [
                {
                    props: { color: "primary", variant: "contained" },
                    style: {
                        backgroundColor: `{Light.palette.primary.main}`,
                        color: "rgba(0, 0, 0, 0.6) !important",
                        letterSpacing: "1.5px",
                        fontWeight: 700,
                        padding: "8px 16px",
                        textTransform: "capitalize",
                        "&.Mui-hover ": {
                            backgroundColor: `{Light.palette.primary.light}`,
                        },
                        "&.Mui-clicked ": {
                            opacity: 0.7,
                            color: `{Light.palette.text.primary}`,
                        },
                        "&.Mui-disabled ": {
                            opacity: 0.6,
                        },
                    },
                },
                {
                    props: { variant: "outlined" },
                    style: {
                        border: `{1px solid {Light.palette.divider}}`,
                        fontWeight: 700,
                        padding: "8px 16px",
                        textTransform: "capitalize",
                        "&.Mui-hover ": {
                            backgroundColor: `{Light.palette.primary.main}`,
                        },
                        "&.Mui-clicked ": {
                            opacity: 0.7,
                            color: `{Light.palette.text.primary}`,
                        },
                    },
                },
                {
                    props: { color: "primary", variant: "text" },
                    style: {
                        color: `{Light.palette.primary.main}`,
                        fontWeight: 700,
                        textTransform: "none",
                        padding: "8px 16px",
                        "&.Mui-hover ": {
                            textDecoration: `underline {Light.palette.primary.light}`,
                        },
                        "&.Mui-clicked ": {
                            opacity: 0.4,
                            color: `{Light.palette.text.primary}`,
                        },
                    },
                },
            ],
        },
        MuiSvgIcon: {
            variants: [
                {
                    props: { color: "primary" },
                    style: { color: "#000", "&>svg": { color: "#000", fill: "#000" } },
                },
                {
                    props: { color: "secondary" },
                    style: { color: "#3DACFF", "&>svg": { color: "#3DACFF", fill: "#3DACFF" } },
                },
                {
                    props: { color: "error" },
                    style: { color: "#E43B3B", "&>svg": { color: "#E43B3B", fill: "#E43B3B" } },
                },
                {
                    props: { color: "success" },
                    style: { color: "#3FFCAE", "&>svg": { color: "#3FFCAE", fill: "#3FFCAE" } },
                },
                {
                    props: { color: "warning" },
                    style: { color: "#FFA842", "&>svg": { color: "#FFA842", fill: "#FFA842" } },
                },
                {
                    props: { color: "info" },
                    style: { color: "#73c0fa", "&>svg": { color: "#73c0fa", fill: "#73c0fa" } },
                },
            ],
            styleOverrides: {
                root: {
                    "&.On-Dark": { color: "rgba(255,255,255,0.95)" },
                    "&.Side-Menu.indicator.active": { color: "#3DACFF", width: "0.5rem" },
                    "&.Side-Menu.indicator": { width: "0.5rem" },
                },
            },
        },
        MuiGrid: {
            styleOverrides: {
                root: {
                    "&.Plan-Billing-Tab": { border: "1px solid #000" },
                    "&.On-Dark": { color: "rgba(255,255,255,0.95)" },
                    "&.Workspaces-Box": {
                        border: "1px solid #0000001F",
                        backgroundColor: "#f1f5f7",
                        cursor: "pointer",
                        padding: "15px",
                        display: "flex",
                        gap: "14px",
                        justifyContent: "flex-start",
                        borderRadius: "4px",
                        flexGrow: 1,
                        alignItems: "center",
                    },
                    "&.Main-Container": { backgroundColor: "#f2f2f3" },
                    "&.Accounts-Box": {
                        border: "1px solid #0000001F",
                        backgroundColor: "#f1f5f7",
                        width: "100%",
                        height: "100%",
                        borderRadius: "2rem",
                        display: "flex",
                        flexDirection: "column",
                        position: "relative",
                    },
                    "&.Workspace-Item": {
                        color: "rgba(0,0,0,0.7)",
                        "&:hover": { color: "rgba(0,0,0)" },
                    },
                    "&.Divider": {
                        backgroundColor: "#000",
                    },
                },
            },
        },
        MuiListItemButton: {
            styleOverrides: {
                root: {
                    "&.Side-Menu": {
                        borderRadius: "2rem",
                        display: "flex",
                        padding: "0.5rem 1rem",
                        width: "100%",
                        alignItems: "center",
                        justifyContent: "space-between",
                    },
                    "&.Side-Menu:hover": { backgroundColor: "rgba(0,0,0,0.1)" },
                    "&.Side-Menu.expanded": {
                        color: "#001d35",
                        backgroundColor: "rgba(0,0,0,0.1)",
                    },
                    "&.Side-Menu.Sub-Menu": { borderRadius: "2px" },
                    "&.Side-Menu.Sub-Menu.expanded": {
                        color: "#001d35",
                        backgroundColor: "transparent",
                    },
                    "&.Side-Menu.Sub-Menu.expanded:hover": { backgroundColor: "rgba(0,0,0,0.1)" },
                },
            },
        },
        MuiListItemText: {
            styleOverrides: {
                root: {
                    "&.Side-Menu.active": { color: "#041e49" },
                    "&.SideMenu:hover": { backgroundColor: "rgba(0,0,0,0.1)" },
                    "&.Side-Menu": { color: "#5f6368" },
                    "&.Side-Menu.Sub-Menu.active": { color: "#041e49" },
                },
            },
        },
        MuiListItemIcon: {
            styleOverrides: {
                root: {
                    "&.Side-Menu.active>svg": { color: "#000", fill: "#000" },
                    paddingLeft: "2px",
                    "&.Side-Menu>svg": { color: "#5f6368", fill: "#5f6368" },
                },
            },
        },
        MuiAppBar: {
            defaultProps: { position: "fixed" },
            styleOverrides: {
                root: {
                    backgroundColor: "white",
                    height: "70px",
                    zIndex: 1300,
                    backgroundImage: "none",
                    boxShadow: "1px 4px 10px 0px #00000012",
                },
            },
        },
    },
});
