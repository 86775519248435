import { configureStore, getDefaultMiddleware } from "@reduxjs/toolkit";
import { ENV } from "../Utils/constants";
import reducer from "./reduer";

export function configureAppStore() {
    return configureStore({
        reducer: reducer,
        devTools: ENV !== "prod",
        middleware: [...getDefaultMiddleware()],
    });
}

export type RootState = ReturnType<typeof reducer>;
