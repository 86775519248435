/* eslint-disable react-hooks/exhaustive-deps */
import { useLazyQuery } from "@apollo/client";
import { BackendClient, ProfileClient } from "../../../../../apolloClient/apolloClient";
import {
    GET_MASTER_PROFILE_MEDIAKITS,
    GET_USER_PLATFORM_ORDERS,
    GET_USER_SOCIAL_CONNECTS_MONO,
} from "../../../../../graphQL/Queries";
import { useEffect } from "react";
import { toast } from "react-toastify";
import {
    FACEBOOK_PLATFORM_ID,
    FACEBOOK_PERSONAL_PLATFORM_ID,
    INSTAGRAM_PLATFORM_ID,
    LINKEDIN_PLATFORM_ID,
    TIKTOK_PLATFORM_ID,
    TWITTER_PLATFORM_ID,
    YOUTUBE_PLATFORM_ID,
    TWITCH_PLATFORM_ID,
    TOASTIFY_THEME,
} from "../../../../../Utils/constants";

export default function useConnectedSocials(
    user_id,
    setSocialAccounts,
    setSelectedSocialAccounts,
    setSocialAccountsMediakits,
    socialAccountsMediakits,
    selectedSocialAccounts,
    socialAccounts,
    params
) {
    const pathname = window.location.pathname;
    let { invitationKey, teamId, profileId } = params;
    const [
        getConnectedSocialsQuery,
        {
            data: getConnectedSocialsData,
            loading: getConnectedSocialsLoading,
            error: getConnectedSocialsError,
        },
    ] = useLazyQuery(GET_USER_SOCIAL_CONNECTS_MONO, {
        client: BackendClient,
    });
    const [getProfileSocialMetrics] = useLazyQuery(GET_MASTER_PROFILE_MEDIAKITS, {
        client: ProfileClient,
    });
    const [getProfilePlatformOrder, { data: getProfilePlatformOrderData }] = useLazyQuery(
        GET_USER_PLATFORM_ORDERS,
        {
            client: ProfileClient,
            variables: { userId: user_id },
        }
    );

    useEffect(() => {
        if (
            !!new URLSearchParams(window.location.search).get("handleID") &&
            new URLSearchParams(window.location.search).get("domainID") === "3"
        )
            (async (handleId: string) => {
                try {
                    const { data, error, loading } = await getProfileSocialMetrics({
                        variables: { mediakitHandle: handleId },
                    });
                    if (data && !loading && !error)
                        setSocialAccounts(
                            data.getProfileMediakit?.mediakit?.platform_order.map((item) => ({
                                id: item.id,
                                platform_id: item.platform_id,
                                profile_pic: item.img_url,
                                social_handle: item.name,
                                visible: item.visible,
                                is_token_valid: item.is_token_valid,
                            })) ?? []
                        );
                } catch (err: any) {
                    toast.error(`Something went wrong: ${err}`, TOASTIFY_THEME);
                }
            })(new URLSearchParams(window.location.search).get("handleID") as string);
    }, [getProfileSocialMetrics]);

    useEffect(() => {
        if (user_id) {
            getProfilePlatformOrder();
            getConnectedSocialsQuery({
                variables: {
                    userId: user_id,
                },
            });
        } // eslint-disable-next-line
    }, [user_id]);

    useEffect(() => {
        if (
            getConnectedSocialsData?.userSocialConnects &&
            !getConnectedSocialsLoading &&
            !getConnectedSocialsError
        ) {
            const connectedSocials = getConnectedSocialsData?.userSocialConnects;
            if (
                connectedSocials &&
                Object.keys(connectedSocials)?.length > 0 &&
                !Boolean(new URLSearchParams(window.location.search).get("handleID") as string)
            ) {
                let facbookAccounts,
                    facbookPages,
                    youtubeAccounts,
                    instaAccounts,
                    twitterAccounts,
                    tiktokAccounts,
                    linkedInAccounts,
                    twitchAccounts = [];
                if (connectedSocials?.["facebook"]) {
                    facbookAccounts = connectedSocials?.["facebook"]?.map((account) => {
                        return {
                            profile_pic: account.page_image_url,
                            social_handle: account.facebook_display_name,
                            platform_id: FACEBOOK_PERSONAL_PLATFORM_ID,
                            id: account.facebook_user_id,
                            is_token_valid: account.is_token_valid,
                        };
                    });
                }
                if (connectedSocials?.["facebook_pages"]) {
                    facbookPages = connectedSocials?.["facebook_pages"].map((page) => {
                        return {
                            profile_pic: page.page_image_url,
                            social_handle: page.page_name,
                            platform_id: FACEBOOK_PLATFORM_ID,
                            id: page.page_id,
                            is_token_valid: page.is_token_valid,
                        };
                    });
                }
                if (connectedSocials?.["instagram"]) {
                    instaAccounts = connectedSocials?.["instagram"].map((account) => {
                        return {
                            profile_pic: account.profile_pic_url,
                            social_handle: account.username,
                            platform_id: INSTAGRAM_PLATFORM_ID,
                            is_business: account.is_business,
                            id: account.instagram_account_id,
                            is_token_valid: account.is_token_valid,
                        };
                    });
                }
                if (connectedSocials?.["youtube"]) {
                    youtubeAccounts = connectedSocials?.["youtube"].map((account) => {
                        return {
                            profile_pic: account.channel_image_url,
                            social_handle: account.youtube_channel_name,
                            platform_id: YOUTUBE_PLATFORM_ID,
                            id: account.youtube_channel_id,
                            is_token_valid: account.is_token_valid,
                        };
                    });
                }
                if (connectedSocials?.["twitter"]) {
                    twitterAccounts = connectedSocials?.["twitter"].map((account) => {
                        return {
                            profile_pic: account.profile_pic_url,
                            social_handle: account.twitter_handle,
                            platform_id: TWITTER_PLATFORM_ID,
                            id: account.twitter_account_id,
                            is_token_valid: account.is_token_valid,
                        };
                    });
                }
                if (connectedSocials?.["tiktok"]) {
                    tiktokAccounts = connectedSocials?.["tiktok"].map((account) => {
                        return {
                            profile_pic: account.profile_pic_url,
                            social_handle: account.display_name,
                            platform_id: TIKTOK_PLATFORM_ID,
                            is_business: account.is_business,
                            is_advertiser: account.is_advertiser,
                            unique_key: account.unique_key,
                            id: account.tiktok_user_id,
                            is_token_valid: account.is_token_valid,
                        };
                    });
                }
                if (connectedSocials?.["linkedin"]) {
                    linkedInAccounts = connectedSocials?.["linkedin"]?.map((account) => {
                        return {
                            profile_pic: account.profile_pic_url,
                            social_handle: account?.linkedin_handle,
                            platform_id: LINKEDIN_PLATFORM_ID,
                            id: account.linkedin_account_id,
                            is_token_valid: account?.is_token_valid,
                        };
                    });
                }
                if (connectedSocials?.["twitch"]) {
                    twitchAccounts = connectedSocials?.["twitch"].map((account) => {
                        return {
                            profile_pic: account.profile_pic_url,
                            social_handle: account.display_name,
                            platform_id: TWITCH_PLATFORM_ID,
                            id: account.twitch_account_id,
                            // need to add is_token_valid key for twitch on the BE
                            is_token_valid: account?.is_token_valid,
                        };
                    });
                }

                let tempArr = [
                    ...(facbookAccounts ?? []),
                    ...(facbookPages ?? []),
                    ...(youtubeAccounts ?? []),
                    ...(instaAccounts ?? []),
                    ...(twitterAccounts ?? []),
                    ...(tiktokAccounts ?? []),
                    ...(pathname.includes("mediakits") ? [] : linkedInAccounts ?? []),
                    ...(pathname.includes("mediakits") ? [] : twitchAccounts ?? []),
                ];
                if (
                    getProfilePlatformOrderData?.getUserPlatformOrder?.length > 0 &&
                    tempArr?.length > 0
                ) {
                    tempArr = tempArr?.sort((a, b) => {
                        const indexA = getProfilePlatformOrderData?.getUserPlatformOrder?.findIndex(
                            (item) => item.id === a.id
                        );
                        const indexB = getProfilePlatformOrderData?.getUserPlatformOrder?.findIndex(
                            (item) => item.id === b.id
                        );
                        if (indexA < indexB) return -1;
                        if (indexA > indexB) return 1;
                        return 0;
                    });
                }

                if (pathname?.includes("mediakits"))
                    setSocialAccountsMediakits(
                        tempArr?.flatMap((item) => ({
                            id: item.id,
                            platform_id: item.platform_id,
                            img_url: item.profile_pic,
                            name: item.social_handle,
                            visible: true,
                        }))
                    );

                setSocialAccounts(tempArr);

                if ((invitationKey && teamId && profileId) || pathname?.includes("mediakits")) {
                    setSelectedSocialAccounts(tempArr);
                } else {
                    setSelectedSocialAccounts([]);
                }
                if (!pathname?.includes("mediakits")) window?.parent?.postMessage("updated!", "*");
            }
        }
    }, [
        getConnectedSocialsData,
        getProfilePlatformOrderData?.getUserPlatformOrder,
        getConnectedSocialsLoading,
        getConnectedSocialsError,
        invitationKey,
        teamId,
        profileId,
        pathname,
    ]);

    const ChangeSelectedSocialAccounts = (selectedItem, isChecked) => {
        if (pathname.includes("mediakits")) {
            setSocialAccountsMediakits(
                socialAccountsMediakits.map((item) => {
                    if (item.id === selectedItem.id) {
                        return { ...item, visible: !item.visible };
                    }
                    return item;
                })
            );
        } else {
            const findIndex = selectedSocialAccounts?.findIndex(
                (item) => item?.id === selectedItem?.id
            );
            if (isChecked && findIndex === -1) {
                const temp = [...selectedSocialAccounts];
                temp.push(selectedItem);
                setSelectedSocialAccounts([...temp]);
            } else if (findIndex > -1) {
                const temp = [...selectedSocialAccounts];
                temp.splice(findIndex, 1);
                setSelectedSocialAccounts([...temp]);
            }
        }
    };
    const getBadgeContent = (platformId) => {
        let totalBadge = 0;
        if (platformId === 2 || platformId === 11) {
            const facebookPage =
                socialAccounts?.filter?.((i) => i?.platform_id === 2)?.length > 0
                    ? socialAccounts?.filter?.((i) => i?.platform_id === 2)?.length
                    : 0;
            const facebookPersonal =
                socialAccounts?.filter?.((i) => i?.platform_id === 11)?.length > 0
                    ? socialAccounts?.filter?.((i) => i?.platform_id === 11)?.length
                    : 0;
            totalBadge = facebookPage + facebookPersonal;
            return totalBadge;
        } else {
            totalBadge =
                socialAccounts?.filter((item) => item?.platform_id === platformId)?.length > 0
                    ? socialAccounts?.filter((item) => item?.platform_id === platformId)?.length
                    : 0;
            return totalBadge;
        }
    };
    return {
        getConnectedSocialsQuery,
        getConnectedSocialsLoading,
        ChangeSelectedSocialAccounts,
        getBadgeContent,
    };
}
