import {
    FACEBOOK_PLATFORM_ID,
    INSTAGRAM_PLATFORM_ID,
    LINKEDIN_PLATFORM_ID,
    TIKTOK_PLATFORM_ID,
    TWITTER_PLATFORM_ID,
    YOUTUBE_PLATFORM_ID,
} from "./constants";

function getSocialUrl(platform, handle) {
    if (platform === INSTAGRAM_PLATFORM_ID) {
        window.open("https://www.instagram.com/" + handle, "_blank");
    } else if (platform === YOUTUBE_PLATFORM_ID) {
        window.open("https://www.youtube.com/channel/" + handle, "_blank");
    } else if (platform === TWITTER_PLATFORM_ID) {
        window.open("https://twitter.com/" + handle, "_blank");
    } else if (platform === TIKTOK_PLATFORM_ID) {
        window.open("https://tiktok.com/@" + handle, "_blank");
    } else if (platform === FACEBOOK_PLATFORM_ID) {
        window.open("https://www.facebook.com/" + handle, "_blank");
    } else if (platform === LINKEDIN_PLATFORM_ID) {
        window.open("https://linkedin.com/in/" + handle, "_blank");
    }
}

export { getSocialUrl };
