const CircleYoutubeIcon = (props) => (
    <svg
        width={props.width}
        height={props.height}
        viewBox="0 0 51 50"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            d="M0.5 24C0.5 10.7452 11.2452 0 24.5 0H26.5C39.7548 0 50.5 10.7452 50.5 24V26C50.5 39.2548 39.7548 50 26.5 50H24.5C11.2452 50 0.5 39.2548 0.5 26V24Z"
            fill={props?.color ? "none" : "white"}
        />
        <path
            d="M40.1319 17.6215C39.7807 16.299 38.7459 15.2575 37.4319 14.904C35.0503 14.2617 25.5003 14.2617 25.5003 14.2617C25.5003 14.2617 15.9503 14.2617 13.5686 14.904C12.2547 15.2575 11.2199 16.299 10.8686 17.6215C10.2305 20.0186 10.2305 25.02 10.2305 25.02C10.2305 25.02 10.2305 30.0213 10.8686 32.4184C11.2199 33.7409 12.2547 34.7391 13.5686 35.0926C15.9503 35.7349 25.5003 35.7349 25.5003 35.7349C25.5003 35.7349 35.0502 35.7349 37.4319 35.0926C38.7459 34.7391 39.7807 33.7409 40.1319 32.4184C40.7701 30.0213 40.7701 25.02 40.7701 25.02C40.7701 25.02 40.7701 20.0186 40.1319 17.6215V17.6215ZM22.3769 29.5608V20.4791L30.3588 25.0201L22.3769 29.5608V29.5608Z"
            fill={props?.color ? props?.color : "#FF0000"}
        />
    </svg>
);

export default CircleYoutubeIcon;
